import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlInteraction } from "../helpers";

import { DoubleClickZoom } from "ol/interaction";

export default function DoubleClickZoomInteraction(props) {
  const context = useContext(MapContext);

  const options = {
    duration: undefined,
    delta: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, DoubleClickZoom, props, options, events),
    []
  );

  return null;
}
