import React from 'react'
import PropTypes from 'prop-types';

import TextControl from 'Controls/TextControl';

class WholeNumberControl extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  tryParseInt(str, defaultValue) {
    let ret = defaultValue;
    if (str !== null && str !== undefined) {
      if (str.length > 0 && !isNaN(str) && str.match(/^\d+$/) !== null) {
        ret = parseInt(str, 10);
      }
    }
    return ret;
  }

  handleChange(newValue, source) {
    const { value } = this.props;

    let valid = false;
    let num = null;
    if (newValue === null || newValue === undefined || newValue.length === 0) {
      valid = true;
    } else if (newValue.length > 0) {
      num = this.tryParseInt(newValue, null);
      if (num !== null) {
        valid = true;
      }

    }

    if (this.props.onChange) {
      if (valid) {
        this.props.onChange(num, source);
      } else {
        this.props.onChange(value, source);
      }
    }
  }

  render() {
    const { formMode, controlMode, field, value, label, helperText, validation, ...other } = this.props;

    return (
      <TextControl
        formMode = {formMode}
        controlMode = {controlMode}
        field = {field}
        value = {value}
        label = {label}
        helperText = {helperText}
        validation = {validation}
        {...other}
        onChange = {this.handleChange}
      />
    )
  }

}

WholeNumberControl.defaultProps = {
  formMode: "form",
  controlMode: "edit"
}

WholeNumberControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  helperText: PropTypes.string,
  validation: PropTypes.object,
  onChange: PropTypes.func
}

export default WholeNumberControl;