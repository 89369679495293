import React from "react";
import UserContext from "Components/UserContext/UserContext";
import { Role } from "Lib/role";

import { userService } from "Services/userService";

class UserProvider extends React.Component {
  constructor(props) {
    super(props);

    this.setUserSetting = this.setUserSetting.bind(this);
    this.setUserData = this.setUserData.bind(this);
    this.setLayerVisible = this.setLayerVisible.bind(this);
    this.setMultipleLayerVisible = this.setMultipleLayerVisible.bind(this);

    const userSettings  = window.localStorage.userSettings;

    this.state = {
      username: null,
      user: null,
      email: null,
      userSettings: userSettings !== undefined ? Object.assign({}, JSON.parse(userSettings)) : {},
      userRole: null,
      userRoleTToken: null,
      roleIds: [],
      setUserData: this.setUserData,
      hasAnyRole: this.hasAnyRole,
      hasPermission: this.hasPermission,
      setUserSetting: this.setUserSetting,
      setLayerVisible: this.setLayerVisible,
      setMultipleLayerVisible: this.setMultipleLayerVisible
    };
  }

  checkRoleName(code) {
    const role = Role.getRoleByCode(code);
    switch (role.id) {
      case "ADM_SYS":
      case "ADM_APP":
      case "ADM_REQ":
      case "RESEARCH":
      case "CLERK":
      case "USER":
      case "PUBLIC":
        return role.display;
      default:
        return null;
    }
  }

  setUserData(data, callback) {
    const { username, first_name, last_name, email, user_role, user_role_ttoken, user_permissions, user_settings } = data;

    this.setState({
      username: username,
      firstName: first_name,
      lastName: last_name,
      fullName: first_name ? first_name.concat(" ", last_name) : last_name,
      email: email,
      userRole: user_role,
      userRoleTToken: user_role_ttoken,
      userPermissions: user_permissions,
      user: data,
      userSettings: user_settings || {}
    }, () => {
      if (callback) {
        callback();
      }
    });
  }

  setUserSetting(settings) {
    let currentSettings = {};

    this.setState(
      (prevState) => {
        currentSettings = prevState.userSettings || {};
        Object.keys(settings).forEach((key) => {
          currentSettings[key] = settings[key];
        });
        return {
          userSettings: currentSettings
        };
      },
      () => {
        this.setUserSettings(Object.assign({}, currentSettings));
      }
    );
  }

  setUserSettings(currentSettings) {
    window.localStorage.userSettings = JSON.stringify(currentSettings);
  }

  setLayerVisible(id, isVisible) {
    console.log(id, isVisible);
    const userSettings = this.state.userSettings || {};
    const map_layer_visibility = userSettings.map_layer_visibility || {};

    const new_map_layer_visibility = Object.assign({}, map_layer_visibility);
    const oldValue = new_map_layer_visibility[id];
    // if (oldValue !== isVisible) {
      new_map_layer_visibility[id] = isVisible;

      this.setUserSetting({
        map_layer_visibility: new_map_layer_visibility
      });
    // }
  }

  setMultipleLayerVisible(obj) {
    console.log(obj);

    const userSettings = this.state.userSettings || {};
    const map_layer_visibility = userSettings.map_layer_visibility || {};

    const new_map_layer_visibility = Object.assign({}, map_layer_visibility);
    Object.keys(obj).forEach((key) => {
      new_map_layer_visibility[key] = obj[key];
    });

    this.setUserSetting({
      map_layer_visibility: new_map_layer_visibility
    });
  }

  hasPermission(needPermission) {
    if (needPermission === null || needPermission === undefined) {
      return true;
    } else if (!Array.isArray(needPermission)) {
      needPermission = [needPermission];
    } else if (needPermission.length === 0) {
      return true;
    }

    const permissionCodes = needPermission.map((x) => x.code);
    return this.userPermissions.find(x => permissionCodes.indexOf(x) >= 0) !== undefined;
  }

  hasAnyRole(needAnyRole) {
    if (needAnyRole === null || needAnyRole === undefined) {
      return true;
    } else if (!Array.isArray(needAnyRole)) {
      needAnyRole = [needAnyRole];
    } else if (needAnyRole.length === 0) {
      return true;
    }
    
    const roleCodes = needAnyRole.map((x) => x.code);
    //return this.roleIds.find((x) => roleCodes.indexOf(x) >= 0) !== undefined;
    return roleCodes.indexOf(this.userRole) >= 0;
  }

  render() {
    return <UserContext.Provider value={this.state}>{this.props.children}</UserContext.Provider>;
  }
}

export default UserProvider;
