import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlInteraction } from "../helpers";

import { PinchRotate } from "ol/interaction";

export default function PinchRotateInteraction(props) {
  const context = useContext(MapContext);

  const options = {
    duration: undefined,
    threshold: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, PinchRotate, props, options, events),
    []
  );

  return null;
}
