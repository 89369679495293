import React from "react"

//Material-UI Core Components
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

function Loader(props) {
  const { open } = props;

  return (
    <Backdrop
      open={open}
      style={{
        zIndex: 99999,
        color: "#fff"
      }}
    >
      <CircularProgress color="primary"/>
    </Backdrop>
  );
}

export default Loader;
