import ExcelJS from 'exceljs';
import dataControllerSubModel from "Lib/dataControllerSubModel";
import moment from "moment";

import radio from "Models/submodels/radio";
import checkbox from "Models/submodels/checkbox";
import picker from "Models/submodels/picker";

import { formats } from "Lib/formats";

export {
  exportTableToExcel,
  exportGraphToExcel
};

function exportGraphToExcel({graphData, filename, t}) {
  let fileDownload = require("js-file-download");

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Data', { views: [{ xSplit: 1, ySplit: 1 }] });
  ;

  const idKey = "id";

  const columns = graphData.labels.map((label,i) => {
    return {
      header: label,
      key: "col-" + i,
      width: 20
    }
  })
  columns.unshift({
    header: "ID",
    key: idKey,
    width: 40
  })

  worksheet.columns = columns;

  const ts = moment();
  const filenameT = `${t(filename)}_${ts.format("YYYYMMDD_HHMM")}.xlsx`

  graphData.series.forEach(s => {
    const newRecord = {};
    newRecord[idKey] = t(s.meta);
    s.value.forEach((count, i) => {
      newRecord["col-" + i] = count;
    })
    worksheet.addRow(newRecord)
  })

  workbook.xlsx.writeBuffer().then(data => {
    const file = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }, filenameT);
    fileDownload(file, filenameT);
  });

}

function exportTableToExcel ({records, fields, filename, t}) {
  let fileDownload = require("js-file-download");

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Data', { views: [{ xSplit: 1, ySplit: 1 }] });

  const columns = fields.map(field => {
    return {
      header: t(field.ttoken),
      key: field.source,
      width: field.width
    }
  })

  const fieldsWithModel = fields.filter(f => f.subModel);
  const fieldSubModels = {};
  const fieldPromises = fieldsWithModel.map(f => {
    const modelType =
      f.type === "picker"
        ? picker
        : f.type === "radio"
        ? radio
        : f.type === "checkbox"
          ? checkbox
          : null;
    const newSubModel = new dataControllerSubModel(modelType, f);
    Object.assign(fieldSubModels, {[f.source]: newSubModel});
    return newSubModel.GetData();
  })

  worksheet.columns = columns;

  console.log(records)

  const ts = moment();
  const filenameT = `${t(filename)}_${ts.format("YYYYMMDD_HHMM")}.xlsx`

  if (fieldPromises.length > 0) {
    Promise.all(fieldPromises).then(data => {
      records.forEach(record => {
        const newRecord = {};
        fields.forEach(field => {
          const val = record[field.source]
          const sModel = fieldSubModels[field.source];
          const value = getData(field, sModel, val, t);
          Object.assign(newRecord, {[field.source]: value});
        })
        worksheet.addRow(newRecord)
      })

      workbook.xlsx.writeBuffer().then(data => {
        const file = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }, filenameT);
        fileDownload(file, filenameT);
      });
    })
  } else {
    records.forEach(record => {
      const newRecord = {};
      fields.forEach(field => {
        const val = record[field.source]
        const value = getData(field, null, val, t);
        Object.assign(newRecord, {[field.source]: value});
      })
      worksheet.addRow(newRecord)
    })

    workbook.xlsx.writeBuffer().then(data => {
      const file = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }, filenameT);
      fileDownload(file, filenameT);
    });
  }

}

function getData(field, sModel, val, t) {

  switch(field.type) {
    case "date":
      return val ? moment.utc(val).local().format(formats.date) : "";
    case "datetime":
      return val ? moment.utc(val).local().format(formats.datetime) : "";
    case "boolean":
    case "radio":
      if (sModel) {
        if (val === null || val === undefined) {
          return '';
        } else {
          return t(sModel.records.find(x => x.value === val).label);
        }
      } else if (field.items) {
        const ind = field.items.values.indexOf(val);
        const valueLabel = t(field.items.labels[ind]);
        return valueLabel;
      } else {
        return val;
      }
    case "checkbox":
      if ( Number.isInteger(val)) {
        if (sModel) {
          return t(sModel.records.find(x => x.value == val).label);
        }
        const codes = val.toString(2).split("").reverse();
        return codes.map((x,i) => t(field.items.labels[field.items.values.indexOf(Math.pow(2,i))])).join(', ');

      } else if (Array.isArray(val)) {
        if (sModel) {
          return val.map(x => t(sModel.records.find(f => f.value === x || f.value === x.value).label)).join(', ');
        } else {
          return val.map(x => x.value).join(', ');
        }

      } else  {
        return val;
      }
    case "picker":
      if (sModel) {
        if (val === null || val === undefined) {
          return '';
        } else {
          return t(sModel.records.find(x => x.value === val).label);
        }
      }
      else if (field.items) {
        const ind = field.items.values.indexOf(val);
        const valueLabel = t(field.items.labels[ind]);
        return valueLabel;
      } else {
        return val;
      }
  }
  return val;
}
