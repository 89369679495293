import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlControl } from "../helpers";

import { FullScreen } from "ol/control";

export default function FullScreenControl(props) {
  const context = useContext(MapContext);

  const defaultLabelHtmlElement = document.createElement('i')
  defaultLabelHtmlElement.setAttribute('class','fas fa-expand-alt');

  const defaultLabelActiveHtmlElement = document.createElement('i')
  defaultLabelActiveHtmlElement.setAttribute('class','fas fa-compress-alt');

  const options = {
    className: undefined,
    label: undefined,
    labelActive: defaultLabelActiveHtmlElement,
    label: defaultLabelHtmlElement,
    keys: undefined,
    target: undefined,
    source: undefined
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, FullScreen, props, options, events), [
    props.className,
    props.label,
    props.labelActive,
    props.tipLabel,
    props.keys,
    props.target,
    props.source
  ]);

  return null;
}
