import React, { Fragment, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";

import EditIcon from "@material-ui/icons/Edit";
import RoomIcon from "@material-ui/icons/Room";

import ModelTable from "UI/Table/ModelTable";
import { MapContext } from "Components/Map/MapContext";
import TableButton from "UI/Table/TableButton";

import dataController from "Lib/dataController";
import DialogContext from "UI/DialogContext/DialogContext";
import DataDialog from "Views/MapPage/DataDialog";

import { dataIcons } from "Lib/dataIcons";
import { Role } from "Lib/role";

export default function DataPane(props) {
  const { t } = useTranslation();

  const mapContext = useContext(MapContext);
  const dialogContext = useContext(DialogContext);

  const { model, tableName, viewName, onMarkRecords } = props;

  const [records, setRecords] = useState([]);
  const [ selectedRecords, setSelectedRecords] = useState([]);

  const dc = new dataController(model);

  const handleSelectRecord = (selection) => {
    // let records = null;
    // if (Array.isArray(selection)) {
    //   records = selection.map(x => records[parseInt(x)]);
    // } else {
    //   records = records.filter(x => x.id === parseInt(selection));
    // }

    setSelectedRecords(selection);

  };

  const refreshData = () => {
    dc.GetData().then((resp) => {
      if (resp.success) {
        if (Array.isArray(resp.data)) {
          setRecords(resp.data);
        } else {
          setRecords([]);
        }
      }
    });
  };

  useEffect(() => {
    refreshData();
  }, []);

  const handleAdd = (evt) => {
    const faIconClass = dataIcons(model.source);

    dialogContext.showDialog(DataDialog, {
      type: model.source,
      dc: dc,
      mode: "update",
      form: "insert",
      iconClass: faIconClass,
      onClose: handleDataDialogClose
    });
  };

  const handleEdit = (evt, sel) => {
    console.log(sel);

    const selId = parseInt(sel[0], 10);
    const record = records[selId];
    const recordId = record.id;
    //const dc = new dataController(model);
    const faIconClass = dataIcons(model.source);

    dialogContext.showDialog(DataDialog, {
      type: model.source,
      dc: dc,
      recordId: recordId,
      mode: "update",
      form: "edit",
      iconClass: faIconClass,
      onClose: handleDataDialogClose
    });
  };

  const handleDataDialogClose = (result) => {
    if (result && result.dataChanged) {
      refreshData();
    }

  };

  const handleShowOnMap = () => {
    if (onMarkRecords) {
      onMarkRecords(selectedRecords, mapContext.map);
    }
  };

  return (
    <Fragment>
      <ModelTable
        dc={dc}
        tableName={tableName}
        records={records}
        allowSelection="many"
        allowAdd={true}
        allowFilter={true}
        viewName={viewName}
        sidebarpane={true}
        onSelectRecord={handleSelectRecord}
        onAddRecord={handleAdd}
        pagination={false}
        headerButtonsType="icon"
      >
        <TableButton variant="outlined" allow="one" onClick={handleEdit} startIcon={<EditIcon />} needRole={[Role.ADMINISTRATOR, Role.DATA_EDITOR]}>
          {t("buttons.edit")}
        </TableButton>
        <TableButton variant="contained" allow="always" onClick={handleShowOnMap} startIcon={<RoomIcon />}>
          {t("buttons.mark_on_map")}
        </TableButton>
      </ModelTable>
    </Fragment>
  );
}
