import OlLayerGroup from "ol/layer/Group";
import {getArea, getLength} from 'ol/sphere';

export {
  flatLayersCollection,
  flatLayersArray,
  flattenLayers,
  formatLength,
  formatArea
};


function flatLayersCollection(olLayerCollection, steps) {
  const arr = olLayerCollection.getArray();
  return flattenLayers(arr, steps);
}

function flatLayersArray(layers, steps) {
  return flattenLayers(layers, steps);
}

function flattenLayers(arr, d = 1) {
  if (arr) {
    return d > 0
      ? arr.reduce(
        (acc, val) =>
          acc.concat(
            val instanceof OlLayerGroup && val.getLayers().getArray().length > 0
              ? [val].concat(flattenLayers(val.getLayers().getArray(), d - 1))
              : val
          ),
        []
      )
      : arr.slice();
  } else {
    return [];
  }
}

function formatLength(line) {
  var length = getLength(line)
  if (length > 100) {
    return Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
  } else {
    return Math.round(length * 100) / 100 + ' ' + 'm';
  }
};

function formatArea(polygon) {
  var area = getArea(polygon);
  // if (area > 100000) {
  //   return Math.round((area / 1000000) * 100) / 100 + ' ' + 'km²';
  // } else {
  // }
  return Math.round(area * 100) / 100 + ' ' + 'm²';
};


