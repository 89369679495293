import React from "react";

//Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const useClasses = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    marginRight: "16px",
    width: "60px",
    height: "60px",
    fontSize: "35px"
  },
  img: {
    color: theme.palette.primary.main,
    width: "35px",
    height: "35px",
    display: "inline"
  }
}));

function DialogAvatar(props) {
  const { icon, ariaLabel } = props;

  const { className } = icon.props;

  const classes = useClasses();

  return (
    <Avatar aria-label={ariaLabel} classes={classes}>
      <center>
        <icon.type className={classes.img + (className ? " " + className : "")} />
      </center>
    </Avatar>
  );
}

export default DialogAvatar;
