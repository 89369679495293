import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlControl } from "../helpers";

import { Rotate } from "ol/control";

export default function AttributionControl(props) {
  const context = useContext(MapContext);

  const options = {
    className: undefined,
    label: undefined,
    tipLabel: undefined,
    duration: undefined,
    autoHide: undefined,
    render: undefined,
    resetNorth: undefined,
    target: undefined
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, Rotate, props, options, events), [
    props.className,
    props.label,
    props.tipLabel,
    props.duration,
    props.autoHide,
    props.render,
    props.resetNorth,
    props.target
  ]);

  return null;
}
