import React from 'react'
import PropTypes from 'prop-types';
import Chartist from 'chartist'
import ChartistGraph from "react-chartist";
import { withTranslation } from 'react-i18next';

class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.isEdge = window.navigator.userAgent.toLowerCase().indexOf("edge") >= 0;
    this.isMozilla = window.navigator.userAgent.toLowerCase().indexOf("firefox") >= 0;
  }

  getAttributes(data) {
    const barVerticalCenter = data.y2 - 5;
    const barHorizontalCenter = this.isMozilla || this.isEdge ? data.x1 : (data.x1 + (data.element.width() * .5));

    return {
      x: barHorizontalCenter,
      y: barVerticalCenter,
      "text-anchor": "middle",
      style: "font-family: 'proxima-nova-alt', Helvetica, Arial, sans-serif; font-size: 12px; fill: black"
    };
  }

  chartListener() {
    const { color } = this.props;

    return {
      draw: function (data) {
        if (data.type === 'bar') {
          data.element.attr({ style: 'stroke: ' + color + '; stroke-width: ' + Math.floor(1 / data.series.length * 40) + '%'});
          
          const labelText = data.series[data.index]
          const label = new Chartist.Svg("text");
          label.text(labelText);
          
          const attributes = this.getAttributes(data);
          label.attr(attributes);

          // add the new custom text label to the bar
          data.group.append(label);

          // Get the total path length in order to use for dash array animation
          let pathLength = -1;
          if (!this.isEdge) {
            pathLength = data.element._node.getTotalLength();
          }


          // Set a dasharray that matches the path length as prerequisite to animate dashoffset
          if (!this.isEdge) {
            data.element.attr({
              'stroke-dasharray': pathLength + 'px ' + pathLength + 'px'
            });
          }

          // Create animation definition while also assigning an ID to the animation for later sync usage
          var animationDefinition = {
            'stroke-dashoffset': {
              id: 'anim' + data.index,
              dur: 200,
              begin: 200 * data.index,
              from: pathLength + 'px',
              to: '0px',
              easing: Chartist.Svg.Easing.easeOutQuint,
              // We need to use `fill: 'freeze'` otherwise our animation will fall back to initial (not visible)
              fill: 'freeze'
            }
          };

          // We need to set an initial value before the animation starts as we are not in guided mode which would do that for us
          data.element.attr({
            'stroke-dashoffset': -pathLength + 'px'
          });

          // We can't use guided mode as the animations need to rely on setting begin manually
          // See http://gionkunz.github.io/chartist-js/api-documentation.html#chartistsvg-function-animate
          data.element.animate(animationDefinition, false);

        } else if (data.type === 'label') {
          data.element._node.setAttribute('style', 'font-size: 2em; fill:' + color);
        }
      }.bind(this)
    }
  }

  render() {
    const { value, data, t } = this.props;

    const options = {
      height: 250,
      axisX: {
        labelInterpolationFnc: (label, i, labelList) => t(label)
      }
    };

    return (
      <ChartistGraph
        type="Bar"
        data={data}
        options={options}
        listener={this.chartListener()}
      />
    );
  }

}

BarChart.propTypes = {
  value: PropTypes.number
}

export default withTranslation()(BarChart);