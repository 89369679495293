import Control from 'ol/control/Control';


export default class Status extends Control {

  constructor(opt_options) {

    super(opt_options);

    var options = Object.assign({}, null, opt_options);

    var element = document.createElement('div');
    element.id = "status-control";
    element.className = "status";
    element.appendChild(options.element);
  }

}

if (window.ol && window.ol.control) {
  window.ol.control.Status = Status;
}
