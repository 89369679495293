import React, { Fragment, useEffect } from 'react';
import GridContainer from "UI/Grid/GridContainer";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { transform as OlTransform } from "ol/proj";
import makeStyles
  from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root:{
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  }
}));

function BookmarkPane(props) {

  const classes = useStyles();

  const { currentViews, currentView } = props;

  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  useEffect(() => {
    console.log(currentView);
  }, [currentView])

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const transform = (center) => {
    const newCenter = OlTransform(center, "EPSG:3857", "EPSG:4326");
    const display = newCenter[0].toFixed(2) + " " + newCenter[1].toFixed(2)
    return display;
  }

  return (
     <Fragment>
       <GridContainer>
         <Box m={3} style={{width:"100%"}}>
           <Typography>
             <b>Views</b>
           </Typography>
           <List aria-label="view-list" className="view-list">
             {currentViews.map((view, index) => (
               <ListItem
                   key={index}
                   button
                   selected={index === currentView}
                   onClick={(event) => handleListItemClick(event, index)}
                   classes={classes}
               >
                 <Typography>
                   <b>Center:</b> {transform(view.center)}
                 </Typography>
                 <Typography>
                   <b>Zoom:</b> {view.zoom.toFixed(1)}
                 </Typography>
               </ListItem>
             ))}
           </List>
         </Box>
       </GridContainer>
     </Fragment>
  );
}

export default BookmarkPane;
