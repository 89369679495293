import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import BarControl from "../OpenLayersExt/Bar/BarControl";
import ButtonControl from "../OpenLayersExt/ButtonControl";
import { MapContext } from "../../MapContext";

function ViewHistoryControl(props) {

  const [ready, setReady] = useState(false);

  const mapContext = useContext(MapContext);

  const map = mapContext.current;

  const { previousView, nextView } = props;


  useEffect(() => {
    if (!ready) {
      if (map !== null) {
        setReady(true);
      }
    }
  }, [map]);


  return ready ? (
      <BarControl id="bar-panning" position="top">
        <ButtonControl
            className="view-cntrl-btn"
            title="Previous View"
            name="prev_view"
            html={'<i class="fas fa-caret-square-left"></i>'}
            handleClick={() => previousView()}
        />
        <ButtonControl
            className="view-cntrl-btn"
            title="Next View"
            name="next_view"
            html={'<i class="fas fa-caret-square-right"></i>'}
            handleClick={() => nextView()}
        />
      </BarControl>
  ) : null;
}

export default ViewHistoryControl;
