import React, { useContext, useEffect, useRef } from "react";
import Util from "../Util";
import { MapContext } from "../MapContext";

import OlOverlay from "ol/Overlay";

//Material-UI Core Components
import IconButton from "@material-ui/core/IconButton";

const PopupOverlay = function(props) {

  const context = useContext(MapContext);

  const overlayDiv = useRef(null);

  let overlay = undefined;

  const options = {
    id: undefined,
    element: undefined,
    offset: undefined,
    position: undefined,
    positioning: undefined,
    stopEvent: undefined,
    insertFirst: undefined,
    autoPan: true,
    autoPanAnimation: { duration: 100},
    autoPanMargin: undefined,
    className: undefined
  };

  const events = {
    'change': undefined,
    'change:element': undefined,
    'change:map': undefined,
    'change:offset': undefined,
    'change:position': undefined,
    'change:positioning': undefined,
    'error': undefined,
    'propertychange': undefined
  };

  useEffect(() => {
    if(document.fullscreenElement === null || document.fullscreenElement.id === "main-map") {
      let allOptions = Object.assign(options, props);
      allOptions.position = undefined; //we need to set it after adding to map, otherwise autoPan won't work
      let definedOptions = Util.getDefinedOptions(allOptions);

      //definedOptions.element = ReactDOM.findDOMNode(this).querySelector('div');
      definedOptions.element = overlayDiv.current;

      // console.log('options.element', options.element);
      overlay = new OlOverlay(definedOptions);

      if (context.map) {
        const mapOverlay = context.map.getOverlayById(props.id);
        if (mapOverlay) {
          context.map.removeOverlay(mapOverlay);
        }
        context.map.addOverlay(overlay);
        if (props.position) {
          overlay.setPosition(props.position);
        }

      } else {
        context.initOptions.overlays.push(overlay);
      }

      let olEvents = Util.getEvents(events, props);
      for(let eventName in olEvents) {
        overlay.on(eventName, olEvents[eventName]);
      }

      // console.log('popup overlay: ', context.map, props.position);
    } 
  }, [options, events])

  const handleClose = function(evt) {
    overlay.setPosition(undefined);
    if (props.onClose) {
      props.onClose();
    }
  };




  return(
    <div id={"overlay-" + props.id} ref={overlayDiv} className="ol-react-popup" style={{display: "block"}}>
      <IconButton
        id="popup-closer"
        className="ol-react-popup-closer"
        aria-label="close"
        size="small"
        color="primary"
        onClick={handleClose}
        style={{position: "absolute", top: "2px", right: "8px"}}
      />
      <div id="react-popup-content">
        {props.children}
      </div>
    </div>
  );

}

PopupOverlay.isTouchDevice = function() {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch(e) {
    return false;
  }
}

export default PopupOverlay;
