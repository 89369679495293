import React, { useContext } from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import ToolTip from "@material-ui/core/Tooltip";

//Custom Components
import UserContext from "Components/UserContext/UserContext";
import MapCardTooltipPopper from "UI/MapCard/MapCardTooltipPopper";
import { useTranslation } from "react-i18next";

const useTooltipStyles = makeStyles((theme) => ({
  tooltip: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    fontSize: 16
  }
}));

const useIconButtonStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1)
  }
}));

function MapCardActionButton(props) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);

  const { needRole, needPermission, tooltip, disabled, stickyRight, ...rest } = props;

  const tooltipClasses = useTooltipStyles();
  const iconButtonClasses = useIconButtonStyles();

  const divStyle = {};
  if (stickyRight) {
    divStyle["marginLeft"] = "auto";
  }

  return needRole && !userContext.hasAnyRole(needRole) || needPermission && !userContext.hasPermission(needPermission) ? null : (
    // <ToolTip arrow={true} classes={tooltipClasses} title={t("buttons."+tooltip)} PopperComponent={MapCardTooltipPopper}>
      <div style={divStyle}>
        <Button size="small" color="primary" variant="contained" {...rest} classes={iconButtonClasses} disabled={disabled ? disabled : false}>
          {props.children}
        </Button>
      </div>
    // </ToolTip>
      // Privremeno sam izkomentiro tooltip, ne vidim svrhu tooltip za dodatnu info kad na gumbic vec pise sta je akcija
      // a sto ti znas?
  );
}

MapCardActionButton.propTypes = {
  tooltip: PropTypes.string,
  needRole: PropTypes.array,
  needPermission: PropTypes.array
};

export default MapCardActionButton;
