import React from 'react'
import PropTypes from "prop-types";

//Material-UI Core Components
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

const iconStyles = theme => ({
  root: {
    color: theme.palette.common.black
  }
})

const customStyles =  theme => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: "#fff",
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main
    }
  }
});

class AppHeaderMenuItem extends React.Component {

  render() {
    const { classes, onClick, label, icon } = this.props;

    return (
      <MenuItem onClick={onClick}
       classes={classes}
      >
        {icon ?
          <ListItemIcon style={{minWidth: 32, color: "#000"}}>
            <Icon>{icon}</Icon>
          </ListItemIcon>
        : null}
        {label ?
          <Typography variant="button">{label}</Typography>
        : null }
      </MenuItem>
    );
  }
}

AppHeaderMenuItem.propTypes={
  label: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func
}

export default withStyles(customStyles)(AppHeaderMenuItem);