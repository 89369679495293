import React, { useContext } from "react";
import withPermissions from "Components/withPermissions";

import UserContext
  from "Components/UserContext/UserContext";
import SidebarContext
  from "./SidebarContext/SidebarContext";
import { useEffect } from "react";

function SidebarTabListItem(props) {
  const { id, icon, disabled, title, needRole, needPermission } = props;

  const sideBarContext = useContext(SidebarContext);
  const userContext = useContext(UserContext);

  function handlePaneChange(id) {
    userContext.setUserSetting({map_sidebar_pane: id});
    sideBarContext.setActivePane(id);
  }

  const isActive = sideBarContext.activePane === id;
  const isDisabled = disabled || (needRole && !userContext.hasAnyRole(needRole) || needPermission && !userContext.hasPermission(needPermission));

  let classNames = [];
  if (isDisabled) {
    classNames.push("disabled");
  }
  if (isActive) {
    classNames.push("active");
  }
  classNames = classNames.length > 0 ? classNames.join(" ") : "";

  return (
    <li className={classNames} title={title}>
      <a href={"#" + id} role="tab"
         onClick={(evt) => handlePaneChange(id)}>
        {needRole && !userContext.hasAnyRole(needRole) ? null : icon}
      </a>
    </li>
  );
}

export default SidebarTabListItem;
