import React, { useContext, useEffect, useRef, useState } from "react";
import Util from "../Util";
import { MapContext } from "../MapContext";

import OlOverlay from "ol/Overlay";
import OlGeomPolygon from "ol/geom/Polygon";
import OlGeomLineString from "ol/geom/LineString";
import OlGeomPoint from "ol/geom/Point";
import {unByKey} from 'ol/Observable';

import { formatArea, formatLength } from "Lib/olHelpers";
import { instanceOf } from "prop-types";

const MeasureTooltipOverlay = function (props) {
  const context = useContext(MapContext);

  const overlayDiv = useRef(null);

  const [listener, setListener] = useState();

  let overlay = undefined;

  const { feature } = props;

  const options = {
    id: undefined,
    element: undefined,
    offset: [0, -15],
    position: undefined,
    positioning: 'bottom-center',
    stopEvent: undefined,
    insertFirst: undefined,
    autoPan: true,
    autoPanAnimation: { duration: 100 },
    autoPanMargin: undefined,
    className: undefined
  };

  const events = {
    change: undefined,
    "change:element": undefined,
    "change:map": undefined,
    "change:offset": undefined,
    "change:position": undefined,
    "change:positioning": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => {
    let allOptions = Object.assign(options, props);
    allOptions.position = undefined; //we need to set it after adding to map, otherwise autoPan won't work
    let definedOptions = Util.getDefinedOptions(allOptions);

    definedOptions.element = overlayDiv.current;

    overlay = new OlOverlay(definedOptions);

    if (context.map) {
      const mapOverlay = context.map.getOverlayById(props.id);
      if (mapOverlay) {
        context.map.removeOverlay(mapOverlay);
      }
      context.map.addOverlay(overlay);
      if (props.position) {
        overlay.setPosition(props.position);
      }
    } else {
      context.initOptions.overlays.push(overlay);
    }

    let olEvents = Util.getEvents(events, props);
    for (let eventName in olEvents) {
      overlay.on(eventName, olEvents[eventName]);
    }
  }, [options, events]);

  useEffect(() => {

    if (feature) {
      const geomChangelistener = feature.getGeometry().on("change", function (evt) {
        var geom = evt.target;
        var output;
        var tooltipCoord;
        if (geom instanceof OlGeomPolygon) {
          output = formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof OlGeomLineString) {
          output = formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        }

        overlayDiv.current.innerHTML = output;
        overlayDiv.current.style.display = "block";

        if (context.map) {
          const mapOverlay = context.map.getOverlayById(props.id);
          if (mapOverlay) {
            mapOverlay.setPosition(tooltipCoord);
          }
        }
      });

      setListener(geomChangelistener);
    } else {
      unByKey(listener);
      overlay.setPosition(undefined);
    }
  }, [feature]);

    return (
    <div
      id={"overlay-" + props.id}
      ref={overlayDiv}
      className="ol-tooltip ol-tooltip-static"
      style={{ display: "block" }}
    >
    </div>
  );
};

MeasureTooltipOverlay.isTouchDevice = function () {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
};

export default MeasureTooltipOverlay;
