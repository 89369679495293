import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlControl, findControl } from "../helpers";

import Util from "../../Util";
import { BarControlContext } from "../OpenLayersExt/Bar/BarControlContext";

import { ZoomToExtent } from "ol/control";

export default function ZoomToExtentControl(props) {
  const context = useContext(MapContext);
  const parentBarContext = useContext(BarControlContext);

  const defaultHtmlElement = document.createElement('i')
  defaultHtmlElement.setAttribute('class','fas fa-expand');

  const options = {
    className: undefined,
    target: undefined,
    label: defaultHtmlElement,
    tipLabel: undefined,
    extent: undefined
  };

  const events = {
    change: undefined,
    propertychange: undefined
  };


  useEffect(() => {
    let allOptions = Object.assign(options, props);
    let definedOptions = Util.getDefinedOptions(allOptions);

    let control = new ZoomToExtent(definedOptions);

    if(props.id) {
      control.set("id", props.id)
    }

    if (parentBarContext && parentBarContext.exists) {
      parentBarContext.childControls.push(control);

    } else if (context.map) {
      const mapControl = findControl(context.map, props.id, ZoomToExtent);
      if (mapControl) {
        context.map.removeControl(mapControl);
      }
      context.map.addControl(control);
    } else {
      context.initOptions.controls.push(control);
    }

    let olEvents = Util.getEvents(events, props);
    for (let eventName in olEvents) {
      control.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (context.map) {
        const mapControl = findControl(context.map, props.id, ZoomToExtent);
        if (mapControl) {
          context.map.removeControl(mapControl);
        }
      }
    }
  }, [
    props.className,
    props.target,
    props.label,
    props.tipLabel,
    props.extent
  ])

  return null;
}
