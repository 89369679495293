import React, { useContext, useEffect, useRef } from "react";
import { MapContext } from "../../MapContext";
import {findControl} from "../helpers";
import CoordZoomStatus from "./coordzoomstatus";
import "./coordzoomstatus.css";

import Util from "../../Util";

export default function CoordZoomStatusRoot(props) {
  const mapContext = useContext(MapContext);
  const coordzoomstatusRef = useRef(props.ref);

  const options = {
    activationMode: undefined,
    collapseLabel: undefined,
    label: undefined,
    tipLabel: undefined,
    groupSelectStyle: undefined,
    reverse: undefined,
    target: undefined
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => {
    if (coordzoomstatusRef.current) {
      const options = {
        element: coordzoomstatusRef.current,
      };

      let allOptions = Object.assign(options, props);
      let definedOptions = Util.getDefinedOptions(allOptions);

      const events = {};

      let control = new CoordZoomStatus(definedOptions);

      if (props.id) {
        control.set("id", props.id);
      }

      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, props.id, coordzoomstatus);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
        mapContext.map.addControl(control);
      } else {
        mapContext.initOptions.controls.push(control);
      }

      let olEvents = Util.getEvents(events, props);
      for (let eventName in olEvents) {
        control.on(eventName, olEvents[eventName]);
      }
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, props.id, coordzoomstatus);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, []);

  return (

      <div id="coordzoomstatus-control"
           ref={coordzoomstatusRef}>
          {props.children}
      </div>

  );
}