import {getArea, getLength} from 'ol/sphere';

export {
  zoomToPanValue
};

function isNumeric(val) {
  return /^[0-9]+$/i.test(val);
}

function zoomToPanValue(zoom){
  switch(zoom.toFixed(0)){
    case "7":
      return 40000;
      break;
    case "8":
      return 35000;
      break;
    case "9":
      return 25000;
      break;
    case "10":
      return 20000;
      break;
    case "11":
      return 12000;
      break;
    case "12":
      return 7000;
      break;
    case "13":
      return 3000;
      break;
    case "14":
      return 1200;
      break;
    case "15":
      return 800;
      break;
    case "16":
      return 500;
      break;
    case "17":
      return 300;
      break;
    case "18":
      return 100;
      break;
    default:
      const val = isNumeric(zoom) ? parseInt(zoom, 10) : 0;
      return val >= 18 ? 100 : 40000
  }
};

function formatArea(polygon) {
  const area = getArea(polygon);
  let output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
  } else {
    output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
  }
  return output;
}
