import React, { useEffect, useContext, Fragment, useState } from "react";
import { MapContext } from "../MapContext";

export default function Layers(props) {

  const [layersRendered, setLayersRendered] = useState(props.renderLayerSwitcher);
  const context = useContext(MapContext);

  // useEffect(() => {
  //   if (context.map) {
  //     const mapLayers = context.map.getLayers();
  //     if (props.baseLayers) {
  //       mapLayers.insertAt(0, props.baseLayers);
  //     }
  //   }
  // }, [props.baseLayers])

  // useEffect(() => {
  //   if (context.map) {
  //     const mapLayers = context.map.getLayers();

  //     const mapLayersCount = mapLayers.getArray().length;

  //     if (props.layers) {
  //       props.layers.forEach((layer, i) => {
  //         //mapLayers.push(layer);
  //         mapLayers.insertAt(i + mapLayersCount, layer);
  //         if (!layersRendered && i === props.layers.getLength() - 1) {
  //           setLayersRendered(true);
  //           props.displayLayerSwitcher();
  //         }
  //       })
  //     }
  //   }
  // }, [props.layers])

  return (
    <Fragment>{props.children}</Fragment>
  )
}
