import React from "react";
import ThemeContext from "Components/ThemeContext/ThemeContext";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const THEME_LIGHT = "light";
const THEME_DARK = "dark";

const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#046a41",
      dark: "#0e5113",
      light: "#438f49",
      contrastText: "#FFF"
    },
    secondary: {
      main: "#b7c800",
      contrastText: "#000"
    },
    background: {
      default: "#eaeee2"
    }
  },
  overrides: {
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color"
        }
      }
    },
    MuiAccordionSummary:{
      content:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }
    }
  }
});

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: "#344955",
      dark: "#232f34",
      light: "#4A6572",
      contrastText: "#FFF"
    },
    secondary: {
      main: "#b7c800",
      contrastText: "#000"
    }
  },
  overrides: {
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color"
        }
      }
    },
    MuiAccordionSummary:{
      content:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }
    }
  }
});

class ThemeProvider extends React.Component {
  constructor(props) {
    super(props);

    this.setTheme = this.setTheme.bind(this);

    this.state = {
      theme: THEME_LIGHT,
      setTheme: this.setTheme
    };
  }

  componentWillUnmount() {
    // if (this.state.isThemeLight === true) {
    //   this.setState({ isThemeLight: true });
    // } else {
    //   this.setState({ isThemeLight: false });
    // }
  }

  setTheme(theme) {
    if (theme === THEME_LIGHT) {
      this.setState({ theme: THEME_LIGHT});
    } else if (theme === THEME_DARK) {
      this.setState({ theme: THEME_DARK});
    } else {
      this.setState({ theme: THEME_LIGHT});
    }
  }

  getMuiTheme() {
    const { theme } = this.state;
    if (theme === THEME_LIGHT) {
      return lightTheme;
    } else if (theme === THEME_DARK) {
      return darkTheme;
    } else {
      return lightTheme;
    }
  }

  render() {

    const muiTheme = this.getMuiTheme();

    return (
      <ThemeContext.Provider value={this.state}>
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline/>
          {this.props.children}
        </MuiThemeProvider>
      </ThemeContext.Provider>
    );
  }
}

export default ThemeProvider;
