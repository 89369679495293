import React from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const style = (theme) => ({
  toolbarHeading: {
    color: "#FFFFFF",
    marginRight: "20px"
  }
});

class DialogToolbarHeading extends React.Component {
  render() {
    const { children, classes } = this.props;

    return (
      <Typography
        align="left"
        variant="subtitle1"
        className={"nodrag " + classes.toolbarHeading}
        style={this.props.lowercase ? { textTransform: "none" } : null}
      >
        {children}
      </Typography>
    );
  }
}

DialogToolbarHeading.defaultProps = {
  lowercase: false
};

DialogToolbarHeading.propTypes = {
  lowercase: PropTypes.bool
};

export default withStyles(style)(DialogToolbarHeading);
