import React, { useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SidebarContext from "./SidebarContext/SidebarContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    margin: "-10px -20px 10px",
    height: "40px",
    padding: "0 20px",
    lineHeight: "40px",
    fontSize: "14.4pt",
    color: "#fff"
  }
}));

export default function SidebarHeading(props) {
  const classes = useStyles();
  const sideBarContext = useContext(SidebarContext);
  const { title } = props;

  const handleCollapse = (evt) => {
    sideBarContext.setCollapsed(true);
  };

  return (
    <h1 className={classes.root}>
      {title}
      <span className="sidebar-close" onClick={handleCollapse}>
        <i className="fas fa-caret-left"></i>
      </span>
    </h1>
  );
}
