import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

import UserConsumer from "Components/UserContext/UserConsumer";
import UserContext from "Components/UserContext/UserContext";

import { authService } from "Services/authService";
import { Role } from 'Lib/role';

class PrivateRoute extends Component {

  render() {
    const { component, rest } = this.props;

    const accessToken = localStorage.getItem("auth_token");

    if (accessToken) {
      let userContext = this.context;
      if (!userContext || !userContext.username) {
        authService.whoAmI().then(user => {
          if (user === undefined || user.username === undefined) {
            localStorage.removeItem("auth_token");
            window.location.reload(true);
          } else {
            userContext.setUserData(user)
          }
        });
      }
    }

    return (
      <UserConsumer>
        {userContext => (
          <Route
            {...rest}
            render={props =>
              accessToken ? (
                React.createElement(component, props)
              ) : (
                <Redirect
                  to={{ pathname: "/login", state: { from: props.location } }}
                />
              )
            }
          />
        )}
      </UserConsumer>
    );
  }
}

PrivateRoute.contextType = UserContext;

export default PrivateRoute;
