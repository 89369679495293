import { createContext } from 'react';

const SidebarContext = createContext({
  activePane: 'layers',
  collapsed: false,
  setActivePane: () => {},
  setCollapsed: () => {}
});
SidebarContext.displayName = 'SidebarContext'

export default SidebarContext
