import React, { useContext, useEffect} from "react";
import OlLayerTile from "ol/layer/Tile";

import OlSourceOSM from "ol/source/OSM";
import OlSourceStamen from "ol/source/Stamen";
import OlSourceTileWMS from 'ol/source/TileWMS';

import Util from '../Util';
import { MapContext } from "../MapContext";
import { GroupLayerContext } from "./GroupLayer/GroupLayerContext";

const idKey = "id";
const titleKey = "title";
const typeKey = "type";
const stamenKey = "stamen";

function findLayer(map, id) {
  return  map.getLayers().getArray().find(x => x instanceof OlLayerTile && x.get(idKey) === id);
}

export default function TileLayer(props) {

  const mapContext = useContext(MapContext);
  const parentLayerContext = useContext(GroupLayerContext);

  let layer = undefined;

  const wmsLayers = props.wms.params.LAYERS;

  const options = {
    className: undefined,
    opacity: undefined,
    visible: undefined,
    extent: undefined,
    zIndex: undefined,
    minResolution: undefined,
    maxResolution: undefined,
    preload: undefined,
    source: undefined,
    map: undefined,
    useInterimTilesOnError: undefined
  };

  const wmsOptions = {
    attributions: undefined,
    cacheSize: undefined,
    crossOrigin: undefined,
    params: undefined,
    gutter: undefined,
    hidpi: undefined,
    projection: undefined,
    reprojectionErrorThreshold: undefined,
    tileClass: undefined,
    tileGrid: undefined,
    serverType: undefined,
    tileLoadFunction: undefined,
    url: undefined,
    urls: undefined,
    wrapX: undefined,
    transition: 0
  }

  const events = {
    'change': undefined,
    'change:extent': undefined,
    'change:maxResolution': undefined,
    'change:maxZoom': undefined,
    'change:minResolution': undefined,
    'change:minZoom': undefined,
    'change:opacity': undefined,
    'change:preload': undefined,
    'change:source': undefined,
    'change:useInterimTilesOnError': undefined,
    'change:visible': undefined,
    'change:zIndex': undefined,
    'error': undefined,
    'postrender': undefined,
    'prerender': undefined,
    'propertychange': undefined
  };

  const getSource = () => {
    if (props.source) {
      return props.source;
    }
    else if (props.wms) {
      let allWMSOptions = Object.assign(wmsOptions, props.wms);
      let definedWMSOptions = Util.getDefinedOptions(allWMSOptions);

      return new OlSourceTileWMS(definedWMSOptions);
    }
    else if (props.stamen) {
      return new OlSourceStamen({
        layer: props.stamen
      })
    } else {
      return new OlSourceOSM();
    }
  }

  // console.log('render layer', layer, props);

  useEffect(() => {
    // console.log('tile layer effect called');
    let allOptions = Object.assign(options, props);
    let definedOptions = Util.getDefinedOptions(allOptions);

    definedOptions.source = definedOptions.source || getSource();

    layer = new OlLayerTile(definedOptions);
    if (props.id) {
      layer.set(idKey, props.id);
    }
    if (props.title) {
      layer.set(titleKey, props.title);
    }
    if (props.type) {
      layer.set(typeKey, props.type);
    }
    if(props.zIndex){
      layer.setZIndex(props.zIndex);
    }

    if (parentLayerContext && parentLayerContext.exists) {
      parentLayerContext.childLayers.push(layer);

    } else if (mapContext.map) {
      // const mapLayers = mapContext.map.getLayers();
      // mapLayers.getArray().find(x => x instanceof OlLayerTile && x.get(idKey) === props.id);
      const mapLayer = findLayer(mapContext.map, props.id);

      if (mapLayer) {
        // context.updateMap({type: "removeLayer", layer: mapLayer});
        // console.log('remove layer', mapLayer);
        mapContext.map.removeLayer(mapLayer);
      }
      // context.updateMap({type: "addLayer", layer: layer});
      // console.log('add layer', layer);
      mapContext.map.addLayer(layer);

    } else {
      mapContext.initOptions.layers.push(layer)
    }

    let olEvents = Util.getEvents(events, props);
    for(let eventName in olEvents) {
      layer.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (mapContext.map) {
        const mapLayer = findLayer(mapContext.map, props.id);
        if (mapLayer) {
          // console.log('unmounting TileLayer, removing mapLayer', props.id, mapLayer)
          mapContext.map.removeLayer(mapLayer);
        }
      }
    }

    // console.log('layer Tile mounted', layer);
  }, [wmsLayers, props.visible]);

  return(null);
}
