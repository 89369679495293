import React from 'react'
import PropTypes from 'prop-types';
import ChartistGraph from "react-chartist";
import { withTranslation } from 'react-i18next';

class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.isEdge = window.navigator.userAgent.toLowerCase().indexOf("edge") >= 0;
    this.isMozilla = window.navigator.userAgent.toLowerCase().indexOf("firefox") >= 0;
  }

  chartListener() {
    const { color } = this.props;

    return {
      draw: function (data) {
        if (data.type === 'line') {
          // Get the total path length in order to use for dash array animation
          // Set a dasharray that matches the path length as prerequisite to animate dashoffset
          let pathLength = -1;
          if (!this.isEdge) {
            pathLength = data.element._node.getTotalLength();
            data.element.attr({
              'stroke-dasharray': pathLength + 'px ' + pathLength + 'px'
            });
          }

          // Create animation definition while also assigning an ID to the animation for later sync usage
          var animationDefinition = {
            'stroke-dashoffset': {
              id: 'anim' + data.index,
              dur: 2000,
              from: pathLength + 'px',
              to: '0px',
              easing: Chartist.Svg.Easing.easeOutQuint,
              // We need to use `fill: 'freeze'` otherwise our animation will fall back to initial (not visible)
              fill: 'freeze'
            }
          };

          // If this was not the first slice, we need to time the animation so that it uses the end sync event of the previous animation
          if (data.index !== 0) {
            animationDefinition['stroke-dashoffset'].begin = 'anim' + (data.index - 1) + '.end';
          }

          // We need to set an initial value before the animation starts as we are not in guided mode which would do that for us
          data.element.attr({
            'stroke-dashoffset': -pathLength + 'px'
          });
          data.element.attr({ style: 'stroke: ' + color });

          // We can't use guided mode as the animations need to rely on setting begin manually
          // See http://gionkunz.github.io/chartist-js/api-documentation.html#chartistsvg-function-animate
          data.element.animate(animationDefinition, false);
        }
        else if (data.type === 'label') {
          // adjust label size
          data.element._node.setAttribute('style', 'font-size: 2em; fill:' + color);
        }
      }.bind(this)
    }
  }

  render() {
    const { value, data, t } = this.props;

    const options = {
      height: 250,
      axisX: {
        labelInterpolationFnc: (label, i, labelList) => t(label)
      }
    };

    return (
      <ChartistGraph
        type="Line"
        data={data}
        options={options}
        listener={this.chartListener()}
      />
    );
  }

}

Graph.propTypes = {
  value: PropTypes.number
}

export default withTranslation()(Graph);