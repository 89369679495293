import { authHeader } from "Services/helpers/authHeader";
import { standardHeadersWithAuth } from "Services/helpers/standardHeadersWithAuth";
import { downloadHeadersWithAuth } from "Services/helpers/downloadHeadersWithAuth";
import { handleResponse } from "Services/helpers/handleResponse";

let api = function() {};

const apiUrl = process.env.REACT_APP_APIPATH;

api.prototype.Call = function(path, action, data) {
  const url = `${apiUrl}${path}`;

  switch (action) {
    case "get":
      return this._get(url);
    case "post":
      return this._post(url, data);
    case "put":
      return this._put(url, data);
    case "patch":
      return this._patch(url, data);
    case "delete":
      return this._delete(url, data);
  }
};

api.prototype._get = function(path) {
  return fetch(path, {
    method: "GET",
    headers: standardHeadersWithAuth()
  })
    .then(handleResponse)
    .then(jsondata => {
      return { success: true, data: jsondata };
    })
    .catch(error => {
      return { success: false, error: error };
    })
    .finally(() => {});
};

api.prototype._post = function(path, data) {
  if (!data) {
    data = {};
  }

  return fetch(path, {
    method: "POST",
    mode: "cors",
    headers: standardHeadersWithAuth(),
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .then(jsondata => {
      return { success: true, data: jsondata };
    })
    .catch(error => {
      return { success: false, error: error };
    })
    .finally(() => {});
};

api.prototype._put = function(path, data) {
  if (!data) {
    data = {};
  }

  return fetch(path, {
    method: "PUT",
    mode: "cors",
    headers: standardHeadersWithAuth(),
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .then(jsondata => {
      return { success: true, data: jsondata };
    })
    .catch(error => {
      return { success: false, error: error };
    })
    .finally(() => {});
};

api.prototype._patch = function(path, data) {
  if (!data) {
    data = {};
  }

  return fetch(path, {
    method: "PATCH",
    mode: "cors",
    headers: standardHeadersWithAuth(),
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .then(jsondata => {
      return { success: true, data: jsondata };
    })
    .catch(error => {
      return { success: false, error: error };
    })
    .finally(() => {});
};

api.prototype._delete = function(path, data) {
  if (!data) {
    data = {};
  }

  return fetch(path, {
    method: "DELETE",
    mode: "cors",
    headers: standardHeadersWithAuth(),
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .then(jsondata => {
      return { success: true, data: jsondata };
    })
    .catch(error => {
      return { success: false, error: error };
    })
    .finally(() => {});
};

api.prototype.Download = function(type, key) {
  const url = `${apiUrl}${type}/${key}`;
  return fetch(url, {
    method: "GET",
    mode: "cors",
    headers: downloadHeadersWithAuth()
  })
  .then(data => {
    return data;
  })
  .catch(err => {
    console.warn(err);
    return err;
  })
};

export default api;
