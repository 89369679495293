import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//Material-UI Icons
import CloseIcon from "@material-ui/icons/Close";

//Custom Components
import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogContext from "UI/DialogContext/DialogContext";
import Loader from "UI/Loader/Loader";

import { dataService } from "Services/dataService";

const imageStyles = {
  width: "1200x",
  height: "800px",
  borderRadius: "5px"
};

function ImageDialog(props) {
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const [base64, setBase64] = useState();
  const [loader, setLoader] = useState(false);

  const { pid, onClose } = props;

  useEffect(() => {
    setLoader(true);
    dataService.getPregledPhoto(pid).then((resp) => {
      if (resp.success) {
        setBase64(resp.data.base64);
      }
    })
    .finally(() => {
      setLoader(false);
    });
  }, []);

  return (
    <DraggableDialog open={true} maxWidth={false} fullWidth={false} onClose={onClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="image" icon={<i className="fas fa-image"></i>} />
          <DialogToolbarHeading>{t("titles.photo")}</DialogToolbarHeading>
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={onClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          {base64 ? <img src={`data:image/jpeg;base64, ${base64}`} style={imageStyles} /> : <Loader open={loader} />}
        </Box>
      </DialogBody>
    </DraggableDialog>
  );
}

export default ImageDialog;
