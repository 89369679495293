import React, { Fragment, useState, useEffect, useContext } from "react";
import { MapContext } from "Components/Map/MapContext";

import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import LayerSwitcherTreeItem from "./LayerSwitcherTreeItem";

import OlLayerGroup from "ol/layer/Group";

function LayerSwitcher(props) {
  const { ready } = props;

  const mapContext = useContext(MapContext);

  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  const handleToggle = (evt, nodeIds) => {
    console.log(nodeIds);
    setExpanded(nodeIds);
  };

  const handleSelect = (evt, nodeIds) => {
    console.log(nodeIds);
    setSelected(nodeIds);
  };

  let layers = ready && mapContext.map ? mapContext.map.getLayers().getArray() : [];

  const layerGroupsCodes = layers.filter(x => x instanceof OlLayerGroup).map(x => x.get('id'));

  return (
    <Fragment>
      {ready ? (
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={layerGroupsCodes}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
        >
          {layers.map((layer, i) => (
            <LayerSwitcherTreeItem key={i} layer={layer} />
          ))}
        </TreeView>
      ) : null}
    </Fragment>
  );
}

export default LayerSwitcher;
