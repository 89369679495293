import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import moment from "moment";

// Material UI Components
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
// Custom Components

import { formats } from "Lib/formats";

class ViewControl extends React.Component {
	constructor(props) {
		super(props);

		this.getLabel = this.getLabel.bind(this);
		this.getValue = this.getValue.bind(this);
		this.getObjectValue = this.getObjectValue.bind(this);
	}

	getLabel() {
		const { t, field } = this.props;

		return (
			field.hasOwnProperty("ttoken") && field.ttoken ?
				t(field.ttoken)	: field.hasOwnProperty("title") && field.title ?
				t(field.title) :
				""
		);
	}

	getValue() {
		const { t, field, value } = this.props;
		// TODO
		// multiline support
		if(value === null || value === ''){
			return '';
		} else {
			switch (field.type) {
			  case 'date':
          return moment.utc(value).local().format(formats.date);
			  case 'datetime':
          return moment.utc(value).local().format(formats.datetime);
			  case 'boolean':
			  case 'picker':
			  case 'radio':
				  return this.getObjectValue();
			  case 'text':
			  case 'float':
				  return value && (typeof value === "string" || typeof value === "number") ? value : "";
			  case 'numeric':
			  case 'wholenum':
          if(field.suffix) {
            return value + field.suffix;
          } else {
            return value;
          }
			  default:
				return "";
			}
		}
	}

	getObjectValue() {
		const { t, field, value, dc } = this.props;

		if (value === null || value === undefined) {
			return "";
		}

		if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
			if (field.hasOwnProperty("subModel") && dc !== null) {
				// TODO
				// dc support for control
			} else if (field.hasOwnProperty("items") && typeof field.items === "object" &&
					field.items.hasOwnProperty("labels") && Array.isArray(field.items.labels) &&
					field.items.hasOwnProperty("values") && Array.isArray(field.items.values)
				) {
				const valuePos = field.items.values.indexOf(value);
				if (valuePos === -1) {
					console.warn("Value has no label")
					return value.hastranslationvalue ? t(String(value)) : String(value);
				}

				if (valuePos >= field.items.labels) {
					console.error("Not enough labels to match the value position")
					console.error("Current value: " + value + ". All values: " + field.items.values + ". All labels: " + field.items.labels + ".");
				}
        //console.log(field.items.labels[valuePos]);
				return t(field.items.labels[valuePos]);
			} else {
				console.error("Unknown form of model object");
			}
		} else if (typeof value === "object") {
			if (value.hasOwnProperty("label") && value.label) {
				return t(value.label)
			} else if (value.hasOwnProperty("value") && value.value) {
				return t(value.value);
			} else {
				console.error("Value form is not expected!");
				console.error("Expected value form: { value: some_value, label: some_lable }! Instead got: ", value);
				return "ERROR";
      }
    } else {
			console.error("Undefined type of value!");
			console.error("Expected one of [string, number, object]! Instead got: " + typeof value + "!");
			return "ERROR";
		}


	}

	render() {
		const label = this.getLabel();
		const value = this.getValue();

		return (
			<Fragment>
				<Typography variant="caption" align="right" display="inline">
					<b>
				 {
				 	label !== "" ?
					 	`${label}: `
					 	: null
				 }
					</b>
				</Typography>
				<Typography variant="caption" align="left" display="inline">
					{value}
				</Typography>
			</Fragment>
		);
	}
}

ViewControl.defaultProps = {
  multiline: false,
  rowsMax: 5,
  isValueCalculated: false,
  showHelper: true
}

ViewControl.propTypes = {
	field: PropTypes.object.isRequired
	, multiline: PropTypes.bool
}

export default withTranslation()(ViewControl);
