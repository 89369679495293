import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  }
}));

function MapCardActions(props) {
  const classes = useStyles();

  return <CardActions {...props} classes={classes} disableSpacing={true}/>;
}

export default MapCardActions;
