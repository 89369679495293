import React, { FunctionComponent, SyntheticEvent, useContext } from "react";
import { useTranslation } from "react-i18next";

//MUI
import { useTheme } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBox from "@material-ui/core/Checkbox";

//material-ui Lab
import TreeItem from "@material-ui/lab/TreeItem";

//material-ui Icons
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";

//Custom components
import {MapContext} from "Components/Map/MapContext";
import { findLayer, flattenLayers } from "Lib/olHelpers";

//Openlayers
import OlLayerGroup from "ol/layer/Group";
import OlBaseLayer from "ol/layer/Base";

const LayerTreeItem = (props) => {
  const { t } = useTranslation();

  const mapContext = useContext(MapContext);

  const theme = useTheme();

  const { layer, onNodeSelect } = props;

  const visibility = mapContext.getLayerVisibility();

  const handleHelp = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const id = layer.get("id");
  const title = layer.get("title");
  // const info = Math.floor(Math.random() * 100);
  const visible = visibility.hasOwnProperty(id) && visibility[id] === false ? false : true;

  const show = title && title.length > 0;
  if (!show) {
    return null;
  }

  const isGroupLayer = layer instanceof OlLayerGroup;

  const directChildLayers = isGroupLayer ? layer.getLayers().getArray() : null;
  const allChildLayers = isGroupLayer ? flattenLayers(directChildLayers, 5) : null;

  const isVisible = (layer) => {
    const id = layer.get("id");
    return visibility.hasOwnProperty(id) && visibility[id] === false ? false : true;
  };

  const childsVisible = allChildLayers
    ? allChildLayers.filter((x) => isVisible(x) === true && !(x instanceof OlLayerGroup)).length
    : null;
  const childsHidden = allChildLayers
    ? allChildLayers.filter((x) => isVisible(x) === false && !(x instanceof OlLayerGroup)).length
    : null;

  const groupChecked = childsVisible ? childsVisible > 0 : false;
  const groupIndeterminate = childsVisible && childsHidden ? childsVisible > 0 && childsHidden > 0 : false;

  const iconColor = "primary";

  const LabelIcon = isGroupLayer ? (
    groupIndeterminate ? (
      <IndeterminateCheckBoxIcon color={iconColor} />
    ) : groupChecked ? (
      <CheckBoxIcon color={iconColor} />
    ) : (
      <CheckBoxOutlineBlankIcon color={iconColor} />
    )
  ) : visible ? (
    <CheckBoxIcon color={iconColor} />
  ) : (
    <CheckBoxOutlineBlankIcon color={iconColor} />
  );
  const shouldRenderHelp = false;

  const checked = isGroupLayer ? (groupIndeterminate ? true : groupChecked) : visible;

  return (
    <TreeItem
      nodeId={id}
      label={
        <FormControlLabel
          label={t(title)}
          control={
            <CheckBox
              color="secondary"
              checked={checked}
              indeterminate={groupIndeterminate}
              onChange={(e) => onNodeSelect(e, id)}
            />
          }
        />
      }
    >
      {isGroupLayer && directChildLayers
        ? directChildLayers.map((l, i) => {
            return <LayerTreeItem key={i} layer={l} onNodeSelect={onNodeSelect}/>;
          })
        : null}
    </TreeItem>
  );
}

export default LayerTreeItem