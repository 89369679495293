import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

//Material UI Core Components
import TableHeaderButton from "UI/Table/TableHeaderButton";

import FilterListIcon from "@material-ui/icons/FilterList";

function TableHeaderButtonFilter(props) {
  const { t } = useTranslation();
  const { filters, onToggleFilter, ...otherProps } = props;

  const isOn = filters ? true : false;

  return (
    <TableHeaderButton onClick={onToggleFilter} icon={<FilterListIcon />} variant={isOn ? "contained" : "outlined"} {...otherProps}>
      {isOn ? t("buttons.filters-off") : t("buttons.filters-on")}
    </TableHeaderButton>

  );
}

TableHeaderButtonFilter.propTypes = {
  filters: PropTypes.bool,
  onToggleFilter: PropTypes.func
};

export default TableHeaderButtonFilter;
