import React, { Fragment } from 'react'
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';

//Material-UI Core Components
import { withTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';

//Material UI Icons
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ImageIcon from '@material-ui/icons/Image';

//Custom Components
//import SingleImageViewer from "Components/SingleImageViewer";
import DialogConsumer from "UI/DialogContext/DialogConsumer";

class CustomCheckboxControl extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      items: []
    }

    this.handleChange = this.handleChange.bind(this);
    this.getItems = this.getItems.bind(this);
  }

  componentDidMount(){
    this.getItems();
  }

  componentDidUpdate(prevProps, prevState){
    const { value } = this.props;

    if (prevProps.model !== this.props.model){
      this.getItems();
    } else if (prevProps.value !== this.props.value) {
      this.getItems();
    }

    return null;
  }

  handleChange(evt) {
    const { field, onChange } = this.props;
    const { items } = this.state;
    const itemValue = evt.target.value;
    const checked = evt.target.checked;
    const source = field ? field.source : null;

    items.find(v => (v.uuid || v.id) === itemValue).favorite = checked;

    if (onChange) {
      onChange(items, source)
    }
  };

  getItems() {
    const { field, value, onChange } = this.props;
    const { items } = this.state;

    if (value && value.length >= 0) {
      const itemsArray = value.map(v => {
        const tempItem = items.find(i => (i.uuid || i.id) === (v.uuid || v.id))
        if (tempItem) {
          return tempItem;
        } else {
          return v;
        }
      })
      if (items !== value && onChange) {
        onChange(itemsArray, field.source)
      }
      this.setState({
        items: itemsArray
      });
    } else {
      this.setState({ items: [] });
    }
  }

  render(){
    const { field, t, value, validation, controlMode, showDialog } = this.props;
    const { items } = this.state;

    const hasValue = value !== undefined && value !== null;
    const isRequired = (field && field.validation && field.validation.required) ? true : false;
    const hasError = validation && validation.valid === false;
    const isReadOnly = controlMode === "view" || (field && field.readonly);
    const title = field.ttoken ? t(field.ttoken) : t(field.title);

    return (
      <FormControl error={hasError}>
        <FormLabel required={isRequired} >{title}</FormLabel>
        <FormGroup
          row={field.row}
          aria-label={t(this.props.title)}
        >
          {items.map((item, i) =>
            <div 
              style={{
                display: "flex",
                margin: "4px 0"
              }} 
              key={'div-' + i}
            >
              {
                item.thumbnail ?
                <img src={`data:image/jpeg;base64, ${item.thumbnail}`} alt={item.name}/>
                :
                <ImageIcon 
                  key={'icon-img-' + i}
                  //onClick={() => showDialog(SingleImageViewer , {image: item, path: 'dokumenti', level: 2}, 2)} 
                  style={{margin: "auto 10px auto 0"}}
                /> 
              }
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.favorite ? true : false}
                    color="secondary"
                    onChange={this.handleChange}
                    key={i}
                  />
                }
                value={(item.uuid || item.id)}
                label={t(item.name)}
                key={i}
                disabled={isReadOnly || item.disabled}
              />
            </div>
          )}
        </FormGroup>
        <FormHelperText id={field.source + "-helper"}>
            {hasError ? validation.msg : (field.tooltip ? field.tooltip : " ")}
          </FormHelperText>
      </FormControl>
    )
  }
}

CustomCheckboxControl.propTypes = {
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object.isRequired,
  value: PropTypes.any,
  validation: PropTypes.object,
  onChange: PropTypes.func
}

export default withTranslation()(withTheme(CustomCheckboxControl));
