import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { loadCSS } from 'fg-loadcss';

// Material UI Core
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Icon from "@material-ui/core/Icon";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// Material UI Icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

// Custom
import UserContext from "Components/UserContext/UserContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(6) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  },
  activeLink: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.light
    }
  }
}));

const useListStyle = makeStyles((theme) => ({
  root: {
    paddingTop: "4px",
    paddingBottom: "4px"
  },
  gutters: {
    paddingLeft: "14px",
    paddingRight: "12px"
  }
}));

function Sidebar(props) {
  const classes = useStyles();
  const muiListItem = useListStyle();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { menu, sidebarOpen, toggleSidebar } = props;
  const userContext = useContext(UserContext);
  const { t } = useTranslation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  return (
    <aside id="nav-drawer">
      <Drawer
        variant="permanent"
        className={`${sidebarOpen ? classes.drawerOpen : classes.drawerClose} ${classes.drawer}`}
        classes={{
          paper: sidebarOpen ? classes.drawerOpen : classes.drawerClose
        }}
      >
        <div className={classes.toolbar} style={{ minHeight: "48px" }}>
          <IconButton onClick={toggleSidebar}>{!sidebarOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
        </div>
        {
          //sidebarOpen ?
          //<Divider />
          //: null
        }
        <List disablePadding>
          {menu
            .filter((x) => (!x.hasOwnProperty("needRole") || userContext.hasAnyRole(x.needRole) && (!x.hasOwnProperty("needPermission") || !userContext.hasPermission(x.needPermission))))
            .map((m, i) => {
              return (
                <ListItem
                  key={i}
                  button
                  classes={{
                    root: muiListItem.root,
                    gutters: muiListItem.gutters
                  }}
                  className={classes.link}
                  component={NavLink}
                  to={m.path}
                  exact
                  isActive={(match, location) => {
                    if (!match) return false;
                    //console.log(match.url === location.pathname, match.url, location.pathname);
                    return match.url === location.pathname;
                  }}
                  activeClassName={classes.activeLink}
                >
                  <ListItemIcon>
                    {m.icon ? (
                      <Icon fontSize="small" color="secondary">
                        {m.icon}
                      </Icon>
                    ) : (
                      <Icon fontSize="small" color="secondary" /*baseClassName="fas"*/ className={m.faicon} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={t(m.ttoken)} />
                </ListItem>
              );
            })}
        </List>
        <Divider />
      </Drawer>
    </aside>
  );
}

Sidebar.defaultProps = {
  sidebarOpen: false,
  toggleSidebar: () => {}
};

Sidebar.propTypes = {
  menu: PropTypes.array.isRequired,
  sidebarOpen: PropTypes.bool,
  toggleSidebar: PropTypes.func
};
  
export default Sidebar;