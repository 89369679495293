import React from 'react'

//Material-UI Core Components
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogContent from '@material-ui/core/DialogContent';

const customClasses =  makeStyles(theme => ({
  root: {
    paddingTop: 0
  }
}));

function DialogBody(props) {
  const classes = customClasses();

  return (
    <DialogContent classes={classes} {...props}>
      {props.children}
    </DialogContent>
  );
}

export default DialogBody;