import React, { useRef } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  }
}));

export default function SidebarContent(props) {
  const classes = useStyles();

  const contentRef = useRef(null);

  const element = document.createElement("div");
  element.className = "sidebar-content";

  contentRef.current = element;

  return <div className={`${classes.root}` + " sidebar-content"}>{props.children}</div>;
}
