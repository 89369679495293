import React from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const makeCustomStyles = makeStyles((theme) => ({
  toolbarHeading: {
    color: "#FFFFFF",
    marginLeft: 0,
    marginRight: 20,
    marginTop: 2
  }
}));

function DialogToolbarHeadingSub(props) {
  const { lowercase, children } = props;
  const classes = makeCustomStyles();

  return (
    <Typography
      align="left"
      variant="caption"
      className={"nodrag " + classes.toolbarHeading}
      style={lowercase ? { textTransform: "none" } : null}
    >
      {children}
    </Typography>
  );
}

DialogToolbarHeadingSub.defaultProps = {
  lowercase: false
};

DialogToolbarHeadingSub.propTypes = {
  lowercase: PropTypes.bool
};

export default DialogToolbarHeadingSub;
