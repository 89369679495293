import React, { Component } from "react";
import SidebarContext from "./SidebarContext";

class SidebarProvider extends Component {
  constructor(props) {
    super(props);

    const { defaultPane } = props;

    this.setActivePane = this.setActivePane.bind(this);
    this.setCollapsed = this.setCollapsed.bind(this);

    this.state = {
      activePane: defaultPane || "layers",
      collapsed: false,
      setActivePane: this.setActivePane,
      setCollapsed: this.setCollapsed
    };
  }

  setActivePane(id) {
    //console.log("Sidebar Provider active pane: " + id);
    this.setState((prevState) => {
      return {
        activePane: prevState.activePane !== id ? id : null,
        collapsed: prevState.activePane !== id ? false : true
      };
    });
  }

  setCollapsed(isCollapsed) {
    if (isCollapsed) {
      setTimeout(() => this.setState({
        activePane: null
      }), 380)
      this.setState({
        collapsed: true
      })
    } else {
      this.setState({
        collapsed: false
      });
    }
  }

  render() {
    return <SidebarContext.Provider value={this.state}>{this.props.children}</SidebarContext.Provider>;
  }
}

export default SidebarProvider;
