import React from "react";

import SidebarProvider from "./SidebarContext/SidebarProvider";
import SidebarRoot from "./SidebarRoot";

export default function SidebarControl(props) {

  const { defaultPane } = props;
  return (
    <SidebarProvider defaultPane={defaultPane}>
      <SidebarRoot {...props} />
    </SidebarProvider>
  );
}
