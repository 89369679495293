import React, { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

//Material-UI Core Components
import makeStyles from "@material-ui/core/styles/makeStyles";
import withTheme from "@material-ui/core/styles/withTheme";
import Menu from "@material-ui/core/Menu";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";

//Material-UI Icons
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//Custom Components
import HeaderButton from "UI/Header/HeaderButton";
import HeaderMenuItem from "UI/Header/HeaderMenuItem";

const customStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  list: {
    color: theme.palette.common.white,
    padding: "1px 0"
  }
}));

const makeExpandStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
}));

function HeaderMenuButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = customStyles();
  const expandClasses = makeExpandStyles();
  const { icon, text, toggleSidebar, startIcon, endIcon, showExpandIcon } = props;

  const handleClick = (evt) => {
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = (evt, reason) => {
    setAnchorEl(null);
  };

  const hookAction = (action) => {
    setAnchorEl(null);
    action();
  };

  const buildMenuItem = (node, index) => {
    const { onClick, label, icon } = node.props;
    return <HeaderMenuItem onClick={() => hookAction(onClick)} label={label} icon={icon} key={index} />;
  };

  let menuItems = [];
  if (Array.isArray(props.children)) {
    props.children.forEach((child, i) => {
      if (child !== null && child !== undefined) {
        menuItems.push(buildMenuItem(child, i));
      }
    });
  } else {
    if (props.children !== null && props.children !== undefined) {
      menuItems.push(buildMenuItem(props.children, 1));
    }
  }

  return (
    <Fragment>
      <HeaderButton
        onClick={toggleSidebar ? toggleSidebar : handleClick}
        startIcon={startIcon}
        endIcon={
          showExpandIcon ? (
            <ExpandMoreIcon
              className={clsx(expandClasses.expand, {
                [expandClasses.expandOpen]: anchorEl !== null
              })}
            />
          ) : null
        }
      >
        {text ? text : null}
        {icon === undefined && text === undefined ? <MenuIcon /> : null}
      </HeaderButton>
      <Menu
        id="dialogt-toolbar-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={classes}
      >
        {menuItems}
      </Menu>
    </Fragment>
  );
}

HeaderMenuButton.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  toggleSidebar: PropTypes.func,
  showExpandIcon: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node
};

export default withTheme(HeaderMenuButton);
