import React from 'react'
import DialogContext from 'UI/DialogContext/DialogContext';

class DialogProvider extends React.Component {
  constructor(props) {
    super(props);

    this.showDialog = this.showDialog.bind(this);
    this.hideDialog = this.hideDialog.bind(this);

    this.state = {
      component1: null,
      component2: null,
      props1: {},
      props2: {},
      showDialog: this.showDialog,
      hideDialog: this.hideDialog
    };

  }

  showDialog(component, props = {}, level = 1) {
    if( level === 1 ){
      this.setState({
        component1: component,
        props1: props,
        component2: null,
        props2: {}
      });   
    } else if ( level === 2 ) {
      this.setState({
        component2: component,
        props2: props
      })
    } 
  };

  hideDialog(level = 1) {

    if ( level === 1 ){
      this.setState({
        component1: null,
        props1: {},
      });
    } else if( level === 2 ) {
      this.setState({
        component2: null,
        props2: {},
      });
    }
  }

  render() {
    return (
      <DialogContext.Provider value={this.state}>
        {this.props.children}
      </DialogContext.Provider>
    );
  }
}

export default DialogProvider;
