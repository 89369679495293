import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

//Material UI Core Components
import MaterialUITable from "@material-ui/core/Table";
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import TablePagination from "@material-ui/core/TablePagination";

//Material UI Icons
import AddIcon from "@material-ui/icons/Add";

//Custom Components
import TableCheckbox from "UI/Table/TableCheckbox";
import TableRadio from "UI/Table/TableRadio";
import TableSearchBar from "UI/Table/TableSearchBar";
import TableHeaderButton from "UI/Table/TableHeaderButton";
import TableHeaderButtonExport from "UI/Table/TableHeaderButtonExport";
import TableHeaderButtonFilter from "UI/Table/TableHeaderButtonFilter";
import ToolbarFillContent from "UI/Toolbar/ToolbarFillContent";
import { exportTableToExcel } from "Lib/exportExcel";
import { Role } from "Lib/role";

import { useTable, useRowSelect, useSortBy, useFilters, useGlobalFilter, usePagination } from "react-table";

function Table(props) {
  // Use the state and functions returned from useTable to build your UI

  const [rowId, setRowId] = useState(-1);
  const [pageCurrent, setPageCurrent] = useState(0);
  const [recordsNumber, setRecordsNumber] = useState(0);
  const [firstEntry, setFirstEntry] = useState(false);
  const [filters, setFilters] = useState(false);

  const { t } = useTranslation();

  const {
    records,
    columns,
    data,
    hiddenColumns,
    children,
    allowAdd,
    allowSelection,
    allowFilter,
    allowExport,
    onSelectRecord,
    onAddRecord,
    invalidRecordIndeces,
    selectedRowId,
    onSort,
    dc,
    tableName,
    headerButtonsType
  } = props;

  const selectOnlyOne = function (evt, value, onChange, toggleAllRowsSelected) {
    toggleAllRowsSelected(false);
    onChange(evt, value);
  };

  const handleRowClick = function (evt, toggleRowSelected, toggleAllRowsSelected, record) {
    const recordId = record.id;
    switch (allowSelection) {
      case "none":
        return;
      case "one":
        if (recordId !== rowId) {
          setRowId(recordId);
          if (onSelectRecord) {
            onSelectRecord(record);
          }
          toggleAllRowsSelected(false);
          toggleRowSelected();
        }
        return;
      case "many":
        toggleRowSelected();
        break;
    }
  };

  const handleToggleFilter = function (evt) {
    setFilters(!filters);
    if (filters === true) {
      setAllFilters([]);
    }
  };

  const {
    getTableProps,
    toggleAllRowsSelected,
    headerGroups,
    rows,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    prepareRow,
    setAllFilters,
    state: { selectedRowIds, globalFilter, sortBy, pageIndex, pageSize }
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        hiddenColumns: hiddenColumns
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (instance) => {
      instance.allColumns.push((columns) => {
        switch (allowSelection) {
          case "none":
            return [...columns];
          case "one":
          case "many":
            return [
              ...columns,
              {
                id: "selection",
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div>
                    {allowSelection === "many" ? (
                      <TableCheckbox {...getToggleAllRowsSelectedProps()} title={"Toggle"} />
                    ) : null}
                  </div>
                ),
                Cell: ({ row, toggleAllRowsSelected }) => {
                  const { onChange, checked, indeterminate, style } = row.getToggleRowSelectedProps();

                  switch (allowSelection) {
                    case "one":
                      return (
                        <div>
                          <TableRadio
                            // onClick={(evt, val) => { selectOnlyOne(evt, val, onChange, toggleAllRowsSelected) }}
                            checked={checked}
                            title={"Toggle"}
                            style={style}
                          />
                        </div>
                      );
                    case "many":
                    default:
                      return (
                        <div>
                          <TableCheckbox {...row.getToggleRowSelectedProps()} title={"Toggle"} />
                        </div>
                      );
                  }
                }
              }
            ];
          default:
            return [...columns];
        }
      });
    }
  );

  useEffect(() => {

    const arrSelectedRowsIds = Object.keys(selectedRowIds).filter((x) => selectedRowIds[x] === true);
    const selectedRecords = arrSelectedRowsIds.map(x => records[x]);
    if (onSelectRecord) {
      onSelectRecord(selectedRecords);
    }
  }, [selectedRowIds])

  useEffect(() => {
    if (onSort) {
      onSort(sortBy);
    }
  }, [onSort, sortBy]);

  const arrSelectedRowsIds = Object.keys(selectedRowIds).filter((x) => selectedRowIds[x] === true);

  if (selectedRowId === -1 && selectedRowId !== rowId) {
    setRowId(-1);
  }
  if (!firstEntry && rows.length !== recordsNumber) {
    setRecordsNumber(rows.length);
    setFirstEntry(true);
  }

  if (pageIndex !== pageCurrent) {
    if (rows.length !== recordsNumber) {
      gotoPage(0);
      setPageCurrent(0);
      setRecordsNumber(rows.length);
    } else {
      gotoPage(pageCurrent);
      setRecordsNumber(rows.length);
    }
  }

  if (Math.floor(rows.length / 10) < pageCurrent || Math.floor(rows.length / 10) < pageIndex) {
    return null;
  }

  // Render the UI for your table
  return (
    <Fragment>
      <Toolbar variant="regular" disableGutters={true}>
        <TableSearchBar
          count={preGlobalFilteredRows ? preGlobalFilteredRows.length : 0}
          value={globalFilter}
          onChange={setGlobalFilter}
        />
        <ToolbarFillContent />
        {
          allowAdd ? 
            <TableHeaderButton type={headerButtonsType} icon={<AddIcon />} onClick={onAddRecord} needRole={[Role.ADMINISTRATOR]}>
              {t("buttons.add")}
            </TableHeaderButton> 
          : null
        }
        {
          allowFilter ? 
            <TableHeaderButtonFilter type={headerButtonsType} filters={filters} onToggleFilter={handleToggleFilter} /> 
          : null
        }
        {
          allowExport ? 
            <TableHeaderButtonExport type={headerButtonsType} dc={dc} rows={rows} records={records} tableName={tableName} /> 
          : null
        }
      </Toolbar>
      <TableContainer>
        <MaterialUITable {...getTableProps()} size="small">
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, c) => {
                  const { onClick, ...headerProps } = column.getHeaderProps(column.getSortByToggleProps());
                  return (
                    <TableCell padding={column.id === "selection" ? "checkbox" : "default"} {...headerProps}>
                      {column.canSort ? (
                        <TableSortLabel
                          key={c + "-sortlabel"}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          title={t("common.toggle")}
                          active={column.isSorted}
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        >
                          {column.render("Header")}
                        </TableSortLabel>
                      ) : (
                        column.render("Header")
                      )}
                      {filters && column.canFilter ? (
                        <div className="table-filter" key={c + "-filter"}>
                          {column.render("Filter")}
                        </div>
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row, i) => {
              prepareRow(row);
              const isItemSelected = selectedRowIds[pageIndex * pageSize + i] === true || rowId === row.original.id;
              if (rowId === row.original.id && arrSelectedRowsIds.length === 0) {
                arrSelectedRowsIds.push(row.id);
                row.toggleRowSelected();
              }
              const invalidRecord = Array.isArray(invalidRecordIndeces) && invalidRecordIndeces.indexOf(i) >= 0;

              return (
                <TableRow
                  hover={true}
                  selected={isItemSelected}
                  role="check"
                  aria-checked={isItemSelected}
                  {...row.getRowProps()}
                  onClick={(evt) => handleRowClick(evt, row.toggleRowSelected, toggleAllRowsSelected, row.original)}
                  style={{ backgroundColor: invalidRecord ? "#F88" : "FFF" }}
                >
                  {row.cells.map((cell) => {
                    return <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                onChangePage={(evt, page) => setPageCurrent(page)}
                page={pageIndex}
                rowsPerPage={pageSize}
                count={rows.length}
                onChangeRowsPerPage={(evt) => setPageSize(evt.target.value)}
                labelRowsPerPage={"Broj redaka"}
              />
            </TableRow>
          </TableFooter>
        </MaterialUITable>
      </TableContainer>
      <Toolbar variant="dense" disableGutters={true}>
        <div style={{ flexGrow: 1 }} />
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, { selection: arrSelectedRowsIds });
        })}
      </Toolbar>
    </Fragment>
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  allowSelection: PropTypes.oneOf(["none", "one", "many"])
};

export default Table;
