import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlControl, findControl } from "../helpers";

import Util from "../../Util";

import Button from "ol-ext/control/Button";
import { BarControlContext } from "./Bar/BarControlContext";

export default function ButtonControl(props) {
  const context = useContext(MapContext);
  const parentBarContext = useContext(BarControlContext);

  const options = {
    className: undefined,
    title: undefined,
    name: undefined,
    html: undefined,
    handleClick: undefined,
    target: undefined
  };

  const events = {};

  useEffect(() => {
    let allOptions = Object.assign(options, props);
    let definedOptions = Util.getDefinedOptions(allOptions);

    let control = new Button(definedOptions);

    if(props.id) {
      control.set("id", props.id)
    }

    if (parentBarContext && parentBarContext.exists) {
      parentBarContext.childControls.push(control);

    } else if (context.map) {
      const mapControl = findControl(context.map, props.id, Button);
      if (mapControl) {
        context.map.removeControl(mapControl);
        console.log('control removed', Button);
      }
      context.map.addControl(control);
      console.log('control added', Button);
    } else {
      context.initOptions.controls.push(control);
    }

    let olEvents = Util.getEvents(events, props);
    for (let eventName in olEvents) {
      control.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (context.map) {
        const mapControl = findControl(context.map, props.id, Button);
        if (mapControl) {
          context.map.removeControl(mapControl);
        }
      }
    }

  }, [
    props.className,
    props.title,
    props.name,
    props.html,
    props.handleClick
  ])

  return null;
}
