import api from "Lib/api";

export const dataService = {
  getElementStats,
  getQRStats,
  getData,
  getPreglediById,
  createPregled,
  updatePregled,
  removePregled,
  getPregledPhoto
};

function getElementStats() {
  const apiInstance = new api();

  return apiInstance.Call("data/stats", "get").then((resp)=> {
    if(resp.success){
        return Promise.resolve({
          success: true,
          data: resp.data
        });
    } else {
      return Promise.reject({
        success: false
      })
    }
  })
}

function getQRStats() {
  const apiInstance = new api();

  return apiInstance.Call("qr/stats", "get").then((resp)=> {
    if(resp.success){
        return Promise.resolve({
          success: true,
          data: resp.data
        });
    } else {
      return Promise.reject({
        success: false
      })
    }
  })
}

function getData(id) {
  const apiInstance = new api();

  return apiInstance.Call("data/"+ id, "get").then((resp)=> {
    if(resp.success){
        return Promise.resolve({
          success: true,
          data: resp.data
        });
    } else {
      return Promise.reject({
        success: false
      })
    }
  })
}

function getPreglediById(id) {
  const apiInstance = new api();

  return apiInstance.Call("data/"+ id + "/pregledi", "get").then((resp)=> {
    if(resp.success){
        return resp.data;
    } else {
      return Promise.reject(undefined)
    }
  })
}

function createPregled(id, pregled) {
  const apiInstance = new api();

  return apiInstance.Call("data/"+ id + "/pregledi", "post", pregled).then((resp)=> {
    if(resp.success){
      return Promise.resolve({success: true});
    } else {
      return Promise.reject(undefined)
    }
  })
}

function updatePregled(id, pregled_id, pregled) {
  const apiInstance = new api();

  return apiInstance.Call("data/"+ id + "/pregledi/" + pregled_id, "put", pregled).then((resp)=> {
    if(resp.success){
      return Promise.resolve({success: true});
    } else {
      return Promise.reject(undefined)
    }
  })
}

function removePregled(id, pregled_id) {
  const apiInstance = new api();

  return apiInstance.Call("data/"+ id + "/pregledi/" + pregled_id, "delete").then((resp)=> {
    if(resp.success){
      return Promise.resolve({success: true});
    } else {
      return Promise.reject(undefined)
    }
  })
}

function getPregledPhoto(pid) {
  const apiInstance = new api();

  return apiInstance.Call("pregledi/fotografije/"+ pid, "get").then((resp)=> {
    if(resp.success){
      return Promise.resolve(resp);
    } else {
      return Promise.reject(undefined)
    }
  })
}

