import React, { Fragment, useContext, useState } from 'react';
import GridContainer from "UI/Grid/GridContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import DialogContext from "UI/DialogContext/DialogContext";
import ImageDialog from "Components/ImageDialog";

const imageStyles = {
  width: "100px",
  height: "100px",
  borderRadius: "5px",
  cursor: "pointer"
}

function ImageSlider(props) {
  const dialogContext = useContext(DialogContext);
  const [index, setIndex] = useState(1);

  const {images} = props;

  const handleMoveSliderLeft = () => {
    if (index === 1) {
      return;
    } else {
      setIndex(index - 1)
    }
  }

  const handleMoveSliderRight = () => {
    if (index + 3 > images.length) {
      return;
    } else {
      setIndex(index + 1)
    }
  }

  const handleOpenImageDialog = (pid) => {
    dialogContext.showDialog(ImageDialog, {
      pid: pid,
      onClose: handleDialogClose
    })
  }

  const handleDialogClose = () => {
    dialogContext.hideDialog();
  }


  return (
      <Fragment>
        {images.length <= 3 ? (
            images.map((image, index) => (
                <GridContainer
                key={"grid-" + index}
                 style={{
                  display: "flex",
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center'
                }}>
                  <img key={"image-" + index}
                       src={`data:image/jpeg;base64, ${image.base64}`}
                       style={imageStyles}
                       onClick={() => handleOpenImageDialog(image.pid)}
                       />
                </GridContainer>
            ))
        ) : (
            <GridContainer style={{
              display: "flex",
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}>
              <IconButton
                  value="left"
                  disabled={index === 1}
                  onClick={() => handleMoveSliderLeft()}
                  data-toggle="tooltip"
                  title="Left"
              >
                <FontAwesomeIcon icon={faChevronLeft}/>
              </IconButton>
              <div style={{width: "350px"}}>
                <GridContainer style={{
                  display: "flex",
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center'
                }}>
                  <img src={`data:image/jpeg;base64, ${images[index - 1].base64}`} style={imageStyles} onClick={() => handleOpenImageDialog(images[index - 1].pid)}/>
                  <img src={`data:image/jpeg;base64, ${images[index].base64}`} style={imageStyles} onClick={() => handleOpenImageDialog(images[index].pid)}/>
                  <img src={`data:image/jpeg;base64, ${images[index + 1].base64}`} style={imageStyles} onClick={() => handleOpenImageDialog(images[index + 1].pid)}/>
                </GridContainer>
              </div>
              <IconButton
                  value="right"
                  disabled={index + 3 > images.length}
                  onClick={() => handleMoveSliderRight()}
                  data-toggle="tooltip"
                  title="Right"
              >
                <FontAwesomeIcon icon={faChevronRight}/>
              </IconButton>
            </GridContainer>
        )}
      </Fragment>
  );
}

export default ImageSlider;
