import React from 'react'
import PropTypes from 'prop-types';
import numbro from 'numbro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faCalculator } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next';

//Material-UI Core Components
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

//Material-UI Icons
import LockIcon from "@material-ui/icons/Lock";


class CurrencyControl extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleFocusLost = this.handleFocusLost.bind(this);

    this.getLabel = this.getLabel.bind(this);
    this.getHelperText = this.getHelperText.bind(this);
    this.checkIfRequired = this.checkIfRequired.bind(this);

    this.state = {
      isFocused: false
    }
  }

  handleChange(evt){
    const { field } = this.props;
    const value = evt.currentTarget.value;

    const decValue = value.length > 0 ? value.replace('.',',') : null;

    const valueIsValid = value.length === 0 || numbro(decValue).value() !== undefined;

    if (this.props.onChange && valueIsValid) {
      this.props.onChange(decValue, field.source);
    }
  }

  handleFocus(evt) {
    this.setState({ isFocused: true });

  }

  handleFocusLost(evt) {
    this.setState({ isFocused: false });
  }

  getLabel() {
    const { field, label } = this.props;

    return field ? field.title : label;
  }

  getHelperText() {
    const { field, helperText} = this.props;

    return field && field.tooltip ? field.tooltip : (helperText ? helperText : " ");
  }

  checkIfRequired() {
    const { field } = this.props;

    if (field) {
      return field.validation && field.validation.required;
    } else {
      return false;
    }
  }

  formatEditingValue(value) {
    if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'number') {
      const isInt = Number.isInteger(value);
      return numbro(value).format({thousandSeparated: false, mantissa: isInt ? 0 : 2});
    } else if (typeof value === 'object') {
      const isInt = Number.isInteger(value.value());
       return value.format({thousandSeparated: false, mantissa: isInt ? 0 : 2});
    } else {
      return value;
    }
  }

  formatDisplayValue(value) {
    const numValue = numbro(value);
    if (numValue.value() !== undefined && numValue.value() !== null) {
      return numValue.format({thousandSeparated: true, mantissa: 2});
    } else {
      return '';
    }
  }

  render() {
    const { isFocused } = this.state;
    const { field, formMode, controlMode, value, validation, multiline, rowsMax, isValueCalculated, negativeRed } = this.props;

    const isRequired = this.checkIfRequired();
    const hasError = validation && validation.valid === false;
    const isReadOnly = controlMode === "view" || field === undefined || field.readonly || field.type === "categorization";
    const hasValue = value !== undefined && value !== null;

    const label = this.getLabel();
    const helperText = this.getHelperText();

    return(
      <TextField
        margin="none"
        fullWidth
        required={isRequired}
        error={hasError}
        label={ formMode === "form" ? label : null}
        value={hasValue ? (isFocused ? this.formatEditingValue(value) : this.formatDisplayValue(value)) : ''}
        onChange={this.handleChange}
        multiline={multiline}
        rowsMax={rowsMax}
        InputProps={{
          onFocus: this.handleFocus,
          onBlur: this.handleFocusLost,
          readOnly: isReadOnly,
          startAdornment: <InputAdornment position="start">Kn</InputAdornment>,
          endAdornment: isValueCalculated ? <FontAwesomeIcon icon={faCalculator} color="grey" /> :
                          ( isReadOnly ? <LockIcon style={{ fontSize: 20 }} color="disabled" /> : null),
          style: negativeRed ? {color: "#F00"} : null
        }}
        variant={formMode === "form" ? "filled" : "standard"}
        helperText={hasError ? validation.msg : helperText}
        autoFocus={isFocused}
      />
    )
  }
}

CurrencyControl.defaultProps = {
  formMode: "form",
  controlMode: "edit",
  multiline: false,
  rowsMax: 5,
  isValueCalculated: false,
  negativeRed: false
}

CurrencyControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  isValueCalculated: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  validation: PropTypes.object,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  rowsMax: PropTypes.number,
  negativeRed: PropTypes.bool,
}

export default CurrencyControl;