import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.tonalOffset.blur
    }
}))

export default function SidebarTabs(props) {
    const classes = useStyles();

    return (
        <div className={`${classes.root}` + ' sidebar-tabs'}>
            {props.children}
        </div>
    )
}
