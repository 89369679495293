import React, { useContext, useEffect, useRef, Fragment } from "react";
import { MapContext } from "../../../MapContext";
import { registerOlControl, findControl } from "../../helpers";

import Util from "../../../Util";

import { BarControlContext } from "./BarControlContext";

import Bar from "ol-ext/control/Bar";
import "ol-ext/control/Bar.css";

export default function BarControl(props) {
  const mapContext = useContext(MapContext);

  const { position } = props;

  const childControls = [];

  const options = {
    className: undefined,
    group: undefined,
    toggleOne: undefined,
    autoDeactivate: undefined,
  };

  const events = {};

  useEffect(() => {
    //console.log('bar useEffect');
    let allOptions = Object.assign(options, props);
    let definedOptions = Util.getDefinedOptions(allOptions);

    let control = new Bar(definedOptions);
    control.setPosition(position || "top");

    childControls.forEach(child => {

      control.addControl(child);
    })

    if(props.id) {
      control.set("id", props.id)
    }

    if (mapContext.map) {
      const mapControl = findControl(mapContext.map, props.id, Bar);
      if (mapControl) {
        mapContext.map.removeControl(mapControl);
        //console.log('control removed', Bar);
      }
      mapContext.map.addControl(control);
      //console.log('control added', Bar);
    } else {
      mapContext.initOptions.controls.push(control);
    }

    let olEvents = Util.getEvents(events, props);
    for (let eventName in olEvents) {
      control.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, Bar);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    }


  }, [
    props.className,
    props.group,
    props.toggleOne,
    props.autoDeactivate,
    props.lng
  ])

  //console.log('bar render')

  if (props.children === undefined) {
    return null;
  } else {
    return (
      <BarControlContext.Provider value={{ exists: true, childControls: childControls }} >
        {props.children}
      </BarControlContext.Provider>
    );
  }

  // if (props.children !== undefined) {
  //   if (Array.isArray(props.children)) {
  //     return (
  //       <Fragment>
  //         {props.children}
  //       </Fragment>
  //     );
  //   } else {
  //     return props.children
  //   }

  //   // return (

  //   // );
  // } else {
  //   return null;
  // }
}
