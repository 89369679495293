import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlControl } from "../helpers";

import Scale from "ol-ext/control/Scale";
import "ol-ext/control/Scale.css";

export default function ScaleControl(props) {
  const context = useContext(MapContext);

  const options = {
    className: undefined,
    ppi: undefined,
    editable: undefined
  };

  const events = {
    select: undefined,
    "change:input": undefined
  };

  useEffect(() => registerOlControl(context, Scale, props, options, events), [
    props.className,
    props.ppi,
    props.editable
  ]);

  return null;
}
