import React, { Fragment, useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";

import Table from "UI/Table/Table";
import tableGenerator from "Lib/tableGenerator";
import dataControllerSubModel from "Lib/dataControllerSubModel";
import dataController from "Lib/dataController";

import radio from "Models/submodels/radio";
import checkbox from "Models/submodels/checkbox";
import picker from "Models/submodels/picker";
import TextField from "@material-ui/core/TextField";

function ModelTable(props) {
  const { t } = useTranslation();
  const [subModelsWithData, setSubModelsWithData] = useState([]);
  const [columnInfo, setColumnInfo] = useState({ columns: [], hiddenColumns: [] });
  const [data, setData] = useState([]);
  const [initialSortBy, setInitialSortBy] = useState([]);

  const {
    dc,
    viewName,
    records,
    children,
    allowSelection,
    allowFilter,
    allowExport,
    invalidRecordIndeces,
    selectedRowId,
    searchFilter,
    sidebarpane,
    ...otherProps
  } = props;

  const viewFields = dc.getViewFields(viewName);
  const SelectColumnFilter = function ({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    const { t } = useTranslation();
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">{t("common.all")}</option>
        {options.map((option, i) =>
          option !== "" ? (
            <option key={i} value={option}>
              {option}
            </option>
          ) : null
        )}
      </select>
    );
  };

  const TextColumnFilter = function ({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    return (
      <TextField
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      />
    );
  };

  const NoFilter = function () {
    return null;
  };

  //TODO: poboljasti da radi sa vise italic pozicija, trenuntno radi samo za prvi par <i></i>
  const ItalicCell = (value) => {
    const regexP = /<p[^>]*>|<\/p>/gi;
    let newValue = value.replace(regexP, " ");

    let st = newValue.indexOf("<em>");
    let end = newValue.indexOf("</em>");

    if (st >= 0 && end >= 0) {
      return (
        <Fragment>
          {newValue.substring(0, st)}
          <em>{newValue.substring(st + "<em>".length, end)}</em>
          {newValue.substring(end + "</em>".length)}
        </Fragment>
      );
    } else {
      let st1 = newValue.indexOf("<i>");
      let end1 = newValue.indexOf("</i>");
      if (st1 >= 0 && end1 >= 0) {
        return (
          <Fragment>
            {newValue.substring(0, st1)}
            <em>{newValue.substring(st1 + "<i>".length, end1)}</em>
            {newValue.substring(end1 + "</i>".length)}
          </Fragment>
        );
      }
    }
    return <Fragment>{newValue}</Fragment>;
  };

  const StripHtml = (value) => {
    const regexP = /<p[^>]*>|<\/p>/gi;
    const regexHtml = /<\s*[A-Za-z\/]+[^<>]*>/gi;

    const regexAmp = /&amp;/gi;
    const regexLt = /&lt;/gi;
    const regexGt = /&gt;/gi;

    const newValue = value
      .replace(regexP, " ")
      .replace(regexAmp, "&")
      .replace(regexLt, "<")
      .replace(regexGt, ">")
      .replace(regexHtml, "");
    return <Fragment>{newValue}</Fragment>;
  };

  const compareDateTime = (rowA, rowB, columnId, desc) => {
    const divA = rowA.values[columnId];
    const divB = rowB.values[columnId];
    const momA = divA && divA.props && divA.props.value !== "" ? moment(divA.props.value) : null;
    const momB = divB && divB.props && divB.props.value !== "" ? moment(divB.props.value) : null;
    if (moment.isMoment(momA) && moment.isMoment(momB)) {
      if (momA.isAfter(momB)) {
        return 1;
      } else {
        return -1;
      }
    } else if (momA !== null && momB === null) {
      return -1;
    } else if (momA === null && momB !== null) {
      return 1;
    } else {
      return 1;
    }
  };

  useEffect(() => {
    const fieldsWithModel = viewFields.filter((f) => f.subModel);
    const subModels = {};

    fieldsWithModel.forEach((f) => {
      const modelType =
        f.type === "picker"
          ? picker
          : f.type === "radio"
          ? radio
          : f.type === "checkbox"
          ? checkbox
          : f.type === "link"
          ? require(`Models/${f.subModel.name}`)
          : null;

      if (modelType !== null) {
        if (f.type === "link") {
          Object.assign(subModels, {
            [f.source]: new dataController(modelType, f)
          });
        } else {
          Object.assign(subModels, {
            [f.source]: new dataControllerSubModel(modelType, f)
          });
        }
      }
    });

    const getDataPromises = Object.keys(subModels).map((f) => subModels[f].GetData());
    Promise.all(getDataPromises).then((data) => {
      setSubModelsWithData(subModels);
    });
  }, []);

  useEffect(() => {
    const viewFields = dc.getViewFields(viewName);
    const columns = viewFields.map((f) => {
      const canSort = f.hasOwnProperty("sort") && f.sort === false ? false : true;
      const sortType = f.type === "date" || f.type === "datetime" ? compareDateTime : "alphanumeric";
      let obj = {
        Header: f.ttoken ? t(f.ttoken) : t(f.title),
        accessor: f.source,
        disableSortBy: !canSort,
        sortType: canSort && sortType,
        disableFilters: !f.filter,
        Filter:
          sidebarpane === undefined
            ? f.type === "text"
              ? TextColumnFilter
              : f.type === "radio" || f.type === "boolean" || f.type === "picker" || f.type === "active"
              ? SelectColumnFilter
              : SelectColumnFilter
            : SelectColumnFilter,
        filter: (arrValues, filterField, currValue) => {
          const [fField] = filterField;
          return arrValues.filter((field) => {
            return (field.original[fField] + "").toUpperCase().indexOf(currValue.toUpperCase()) >= 0;
          });
        },
        padding: f.padding ? f.padding : "default"
      };
      if (f.type === "richtext") {
        obj.Cell = (obj) => StripHtml(obj.cell.value ? obj.cell.value : "");
      }
      return obj;
    });

    const hiddenColumns = dc.getHiddenViewFieldsNames(viewName);

    setColumnInfo({
      columns: columns,
      hiddenColumns: hiddenColumns
    });
  }, [viewName, t]);

  useEffect(() => {
    const tg = new tableGenerator(t);
    const viewFields = dc.getViewFields(viewName);
    tg.generateDataRows(records, viewFields, subModelsWithData).then((data) => {
      setData(data);
    });
  }, [viewName, records, records.length, t]);

  const onSort = function (sortBy) {
    setInitialSortBy(sortBy);
  };

  return (
    <Table
      data={data}
      records={records}
      columns={columnInfo.columns}
      hiddenColumns={columnInfo.hiddenColumns}
      allowSelection={allowSelection}
      allowFilter={allowFilter}
      allowExport={allowExport}
      invalidRecordIndeces={invalidRecordIndeces}
      selectedRowId={selectedRowId}
      initialSortBy={initialSortBy}
      onSort={onSort}
      dc={dc}
      {...otherProps}
    >
      {children}
    </Table>
  );
}

ModelTable.defaultProps = {
  viewName: "default",
  allowSelection: "one",
  allowFilter: false,
  allowExport: false
};

ModelTable.propTypes = {
  records: PropTypes.array,
  dc: PropTypes.object,
  viewName: PropTypes.string,
  allowSelection: PropTypes.oneOf(["none", "one", "many"]),
  allowFilter: PropTypes.bool,
  allowExport: PropTypes.bool
};

export default withTranslation()(ModelTable);
