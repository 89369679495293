import React, { useContext} from "react";

//OL
import OlLayerGroup from "ol/layer/Group";

//Custom Components
import TileLayer from "Components/Map/Layers/TileLayer";
import { MapContext } from "Components/Map/MapContext";
import { findLayer, flattenLayers } from "Lib/olHelpers";

const gs_url = process.env.REACT_APP_GEOSERVERPATH;

function GeoPortalGSLayerCombined(props) {
  const mapContext = useContext(MapContext);
  const { id, layersCollection, ...otherProps } = props;

  const visibility = mapContext?.getLayerVisibility()
  const hiddenLayers = visibility ? Object.keys(visibility).filter(key => visibility[key] === false) : [];
  // console.log(hiddenLayers);
  const allLayers = flattenLayers(layersCollection.getArray()).filter(x => !(x instanceof OlLayerGroup)).sort((firstEl, secondEl) => {
    if(firstEl.getZIndex() < secondEl.getZIndex()) {
      return -1
    } else if(firstEl.getZIndex() > secondEl.getZIndex()) {
      return 1
    }
    return 0
  });

  // console.log("all GS layers", allLayers);


  const visibleLayers = allLayers.filter(x => hiddenLayers.indexOf(x.get("id")) === -1);
  // console.log("visibleLayers", visibleLayers);

  const GSlayerNames = visibleLayers.map(x => x.get("layer"));
  // console.log(GSlayerNames);

  return (
    <TileLayer
      id={id}
      title={id}
      {...otherProps}
      zIndex={allLayers[allLayers.length - 1].getZIndex()}
      visible={true}
      wms={{
        urls: [gs_url],
        params: {
          LAYERS: GSlayerNames.join(","),
          FORMAT: "image/png8",
          TILED: true
        }
      }}
    />
  );
}

export default GeoPortalGSLayerCombined;
