import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { MapContext } from "Components/Map/MapContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRulerHorizontal, faVectorSquare } from "@fortawesome/free-solid-svg-icons";

// Material UI Core Components
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";

//Custom Components
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import Button from "@material-ui/core/Button";

export default function MeasuresPane(props) {
  const { t } = useTranslation();
  const { elementRef, changeDrawType, handleEraseMeasurements, draw_type } = props;
  const mapContext = useContext(MapContext);

  const isLine = draw_type === "LineString";
  const isArea = draw_type === "Polygon";
  const isPoint = draw_type === "Point";


  return (
    <Fragment>
      <Typography variant="h5" gutterBottom={true}>
        {t("titles.tools")}
      </Typography>
      {/* <div
            id="ctrls-measure"
            ref={elementRef}
            style={{height:"100px"}}
        ></div> */}
      <GridContainer style={{ marginBottom: "10px" }}>
        <GridItem>
          <Button
            value="length"
            color="primary"
            onClick={() => changeDrawType("LineString")}
            variant={ isLine ? "contained" : "outlined"}
            startIcon={<i className="fas fa-ruler-horizontal"></i>}
          >
            {t("buttons.line")}
          </Button>
        </GridItem>
        <GridItem>
          <Button
            value="area"
            color="primary"
            onClick={() => changeDrawType("Polygon")}
            variant={ isArea ? "contained" : "outlined"}
            startIcon={<i className="fas fa-vector-square"></i>}
          >
            {t("buttons.area")}
          </Button>
        </GridItem>
        <GridItem>
          <Button
            value="point"
            color="primary"
            onClick={() => changeDrawType("Point")}
            variant={ isPoint ? "contained" : "outlined"}
            startIcon={<i className="fas fa-map-marker-alt"></i>}
          >
            {t("buttons.point")}
          </Button>
        </GridItem>
      </GridContainer>
      <Button variant="contained" onClick={() => handleEraseMeasurements()}>
        {t("buttons.erase")}
      </Button>
    </Fragment>
  );
}
