import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";

export default function InfoPane() {
  const { t } = useTranslation();

  const headingVariant = "h5";
  const textVariant = "body2";

  return (
    <Fragment>
      <Typography variant={headingVariant}
                  gutterBottom={true}>
        {t("map:info.panning")}
      </Typography>
      <Typography variant={textVariant} paragraph={true}>
        {t("map:info.move-the-map")}
      </Typography>
      <Typography variant={headingVariant} paragraph={true}>
        {t("map:info.zooming")}
      </Typography>
      <Typography variant={textVariant} paragraph={true}>
        {t("map:info.zoom-the-map")}
      </Typography>
      <Typography variant={headingVariant} gutterBottom={true}>
        {t("map:info.rotating")}
      </Typography>
      <Typography variant={textVariant} paragraph={true}>
        {t("map:info.rotate-the-map")}
      </Typography>
      <Typography variant={textVariant} paragraph={true}>
        {t("map:info.reset-the-map")}
      </Typography>
      <Typography variant={headingVariant} gutterBottom={true}>
        {t("map:info.focusing")}
      </Typography>
      <Typography variant={textVariant} paragraph={true}>
        {t("map:info.make-rectangle")}
      </Typography>
    </Fragment>
  );
}
