import api from "Lib/api";

class dataControllerSubModel {
  constructor(model, field) {
    this.apiPath = field.subModel.apiPath;
    this.fields = model.fields;

    this.records = [];

    this.defaultItem = field && field.items && field.items.default ? field.items.default : null;

    const fieldIdName = field.subModel.idattr ? field.subModel.idattr : null;
    if (fieldIdName) {
      this.fieldId = this.fields.find(attr => attr.source === fieldIdName);
    } else {
      this.fieldId = this.fields.find(attr => attr.idattr === true);
    }

    this.api = new api();
  }

  GetData() {
    if(this.records.length === 0) {
      return this.api.Call(this.apiPath, "get").then(resp => {
        if (resp.success) {
          this.records = resp.data.map(d => Object.assign({}, d));
        }
        return resp;
      });
    } else {
      return Promise.resolve({data: this.records})
    }
  }

  getRecordObject(recordId) {
    if (this.fieldId && (recordId !== null || recordId !== undefined)) {
      let res = this.records.find(r => {
        return r[this.fieldId.source] == recordId;
      });
      if (res) {
        return Object.assign({}, res); //clone object
      } else {
        return null;
      }
    }
    return null;
  }
}
export default dataControllerSubModel;
