import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  }
}));

function MapCardContent(props) {
  const classes = useStyles();

  return <CardContent {...props} classes={classes} />;
}

export default MapCardContent;
