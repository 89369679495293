import React from "react";
import { withTranslation } from 'react-i18next';

import Box from "@material-ui/core/Box";

import AppPage from "UI/AppPage/AppPage"
import MainMap from "Views/MapPage/MainMap";

class MapPage extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { t } = this.props;

		return (
			<AppPage>
				<MainMap />
			</AppPage>
		);
	}
}

export default withTranslation()(MapPage);