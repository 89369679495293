import Control from 'ol/control/Control';


export default class CoordZoomStatus extends Control {

  constructor(opt_options) {

    super(opt_options);

    var options = Object.assign({}, null, opt_options);

    var element = document.createElement('div');
    element.id = "coordzoomstatus-control";
    element.className = "coordzoomstatus";
    element.appendChild(options.element);

  }

}

if (window.ol && window.ol.control) {
  window.ol.control.Status = CoordZoomStatus;
}
