import React, { useContext } from "react";
import PropTypes from "prop-types";

//Material-UI Core Components
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Fab from '@material-ui/core/Fab';

//Custom Components
import UserContext from "Components/UserContext/UserContext";

const customButtonStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.black,
    marginLeft: 10,
    marginRight: 0
  }
}));

function TableHeaderButton(props) {
  const userContext = useContext(UserContext);
  const { 
    type
    , icon
    , onClick
    , needRole
    , needPermission
    , variant
    , ...otherProps 
  } = props;
  const classes = customButtonStyles();

  const handleClick = function (evt) {
    if (onClick) {
      onClick(evt);
    }
  };

  return needRole && !userContext.hasAnyRole(needRole) || needPermission && !userContext.hasPermission(needPermission) ? null : (
    type === "icon" ?
    <Fab classes={classes} color="secondary" size="small" {...otherProps} onClick={handleClick} >
      {icon}
    </Fab> :
      <Button classes={classes} color="secondary" size="small" variant={variant} {...otherProps} startIcon={icon} onClick={handleClick}>
        {props.children}
      </Button>
  );
}

TableHeaderButton.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  needRole: PropTypes.array,
  needPermission: PropTypes.array
};

export default TableHeaderButton;
