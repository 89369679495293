import React from 'react'
import { withRouter } from "react-router";

class ScrollToTop extends React.Component {

  firstPart(location) {
    if (location.length > 0) {
      if (location[0] === '/') {
        location = location.substring(1);
      }

      let parts = location.split('/')

      if (parts.length > 0)  {
        return parts[0];
      }
    }
    return '';
  }

  componentDidUpdate(prevProps) {

    //scroll to top if first part of the path is changed (if params are changed, no need to scroll)
    if ( this.firstPart(this.props.location.pathname) !== this.firstPart(prevProps.location.pathname)) {
      window.scrollTo(0,0);
    }
  }

  render() {
    return this.props.children;
  }

}

export default withRouter(ScrollToTop);