import React from 'react';

//Material-UI Core Components
import Grid from "@material-ui/core/Grid";

function GridContainer(props) {
  const { children, spacing, ...other} = props;

  return (
    <Grid container spacing={ spacing !== undefined ? spacing : 2} {...other}>
      {children}
    </Grid>
  );
}

export default GridContainer;