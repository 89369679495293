import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";

//Custom Components
import Map from "Components/Map/Map";
import {
  Controls,
  DefaultControls,
  ZoomControl,
  ScaleControl,
  ScaleRatioControl,
  ScaleLineControl,
  FullScreenControl,
  RotateControl,
  MeasureControls,
  PanningControl,
  StatusControl,
  CoordZoomStatusControl,
  SearchControl,
  LayerTree
} from "Components/Map/Controls";
import {
  SidebarControl,
  SidebarTabs,
  SidebarTabList,
  SidebarTabListItem,
  SidebarContent,
  SidebarPane,
  SidebarHeading
} from "Components/Map/Controls/Sidebar";
import InfoPane from "Components/MapSidebarPanes/InfoPane";
import DataPane from "Components/MapSidebarPanes/DataPane";
import { Layers, TileLayer, GroupLayer, VectorLayer } from "Components/Map/Layers";
import { Interactions, DefaultInteractions, DrawInteraction } from "Components/Map/Interactions";
import MeasuresPane from "Components/MapSidebarPanes/MeasuresPane";
import ViewHistoryControl from "Components/Map/Controls/Custom/ViewHistoryControl";
import BookmarkPane from "Components/MapSidebarPanes/BookmarkPane";
import LayerSwitcher from "Components/Map/Controls/Custom/LayerSwitcher/LayerSwitcher";
import UserConsumer from "Components/UserContext/UserConsumer";
import MapModelTable from "UI/Table/MapModelTable";

import dataController from "Lib/dataController";
import modelStabla from "Models/stabla.json";
import modelGrmovi from "Models/grmovi";
import modelKlupe from "Models/klupe";
import modelKosevi from "Models/kosevi";
import modelTravnjaci from "Models/travnjaci";
import modelCvjetnjaci from "Models/cvjetnjaci";

import { Overlays, PopupOverlay, MeasureTooltipOverlay, MeasurementInfoOverlay } from "Components/Map/Overlays";

//Openlayers
import proj4 from "proj4";
import { register as OlRegister } from "ol/proj/proj4";
import { get as OlGetProjection } from "ol/proj";
import OlFormatWKT from "ol/format/WKT";
import OlSourceVector from "ol/source/Vector";
import Overlay from "ol/Overlay";
import OlLayerGroup from "ol/layer/Group";
import OlFeature from "ol/Feature";
import { transform as OlTransform } from "ol/proj";
import OlCollection from "ol/Collection";

//Custom views
import GeoPortalGSLayerCombined from "Views/MapPage/GeoPortalGSLayerCombined";
import GeoportalBaseLayer from "Views/MapPage/GeoportalBaseLayer";
import GeoPortalGSLayer from "Views/MapPage/GeoPortalGSLayer";
import GeoPortalBaseLayerSwitcher from "Views/MapPage/GeoPortalBaseLayerSwitcher";
import View from "ol/View";

//Styles
import { measurementsStyle, measuringStyle, drawStyle, selectedStyle } from "Components/mapStyles";

//Services
import { mapService } from "Services/mapService";
import { gsService } from "Services/gsService";
import { toStringHDMS } from "ol";
import GSInfoCard from "./GSInfoCard";

import { flattenLayers, flatLayersCollection } from "Lib/olHelpers";
import { intersects } from "ol/extent";

class MainMap extends React.Component {
  constructor(props) {
    super(props);

    this.layerSwitcherElementRef = React.createRef();
    this.measuresElementRef = React.createRef();
    this.tooltipRef = React.createRef();

    //define proj
    proj4.defs(
      "EPSG:3765",
      "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
    );
    proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
    proj4.defs(
      "EPSG:31276",
      "+proj=tmerc +pm=greenwich +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs"
    );
    proj4.defs(
      "EPSG:3857",
      "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
    );
    OlRegister(proj4);

    this.htrs96 = OlGetProjection("EPSG:3765");
    this.wgs84 = OlGetProjection("EPSG:4326");
    this.wgs84PM = OlGetProjection("EPSG:3857");

    this.defaultViewCenter = process.env.REACT_APP_MAP_DEFAULT_VIEW_CENTER.split(",").map((x) => parseInt(x, 10));
    this.defaultExtent = process.env.REACT_APP_MAP_DEFAULT_EXTENT.split(",").map((x) => parseInt(x, 10));
    this.defaultZoomLevel = process.env.REACT_APP_MAP_DEFAULT_ZOOM_LEVEL;

    // this.defaultViewCenter = [1731757, 5581737];
    // this.defaultExtent = [1954953, 5153516, 3311252, 5952131];

    this.handleClick = this.handleClick.bind(this);
    this.handleViewChangeCenter = this.handleViewChangeCenter.bind(this);
    this.setViewCenter = this.setViewCenter.bind(this);
    this.previousView = this.previousView.bind(this);
    this.nextView = this.nextView.bind(this);

    this.showCardOverlay = this.showCardOverlay.bind(this);
    this.closeOverlays = this.closeOverlays.bind(this);
    this.changeCoordinateSystemDisplay = this.changeCoordinateSystemDisplay.bind(this);

    this.getLayers = this.getLayers.bind(this);

    this.handleDrawMeasureStart = this.handleDrawMeasureStart.bind(this);
    this.handleDrawMeasureEnd = this.handleDrawMeasureEnd.bind(this);
    this.handleDrawMeasureChange = this.handleDrawMeasureChange.bind(this);
    this.handleEraseMeasurements = this.handleEraseMeasurements.bind(this);
    this.changeDrawType = this.changeDrawType.bind(this);

    this.handleMarkRecords = this.handleMarkRecords.bind(this);
    this.handleSidebarPaneChange = this.handleSidebarPaneChange.bind(this);
    this.handleGoToCoordinates = this.handleGoToCoordinates.bind(this);
    this.measurementsStyle = this.measurementsStyle.bind(this);

    this.wkt = new OlFormatWKT();
    this.measurementsSource = new OlSourceVector({});
    this.drawingSource = new OlSourceVector({});
    this.selectedFeaturesSource = new OlSourceVector({});

    this.state = {
      viewOptions: {
        center: this.defaultViewCenter,
        zoom: this.defaultZoomLevel,
        minZoom: 7,
        maxZoom: 22
      },
      drawing: false,
      draw_type: undefined,
      areaWKT: undefined,
      currentViews: [],
      removedViews: [],
      currentView: 0,
      viewSwitchClicked: false,
      zoomToExtent: this.defaultExtent,
      cardOverlay: null,
      feature: undefined,
      chosenCoordinateSystem: "WGS84",
      layersCollection: undefined,
      cartographyLayerGroup: undefined,
      allLayersCollection: undefined,
      forceRefreshCounter: 0
    };
    this.drawingSource.set("coordSystem", "WGS84")
  }

  componentDidMount() {
    this.getLayers();
    this.getDefaultData();
  }

  getLayers() {
    mapService.getLayers().then((coll) => {
      let podloga = undefined
      const flatColl = new OlCollection(coll.getArray().filter((layer) => {
          if(layer.get("id") !== "app-podloga") {
            console.log(layer.get("id"))
            return layer
          } else {
            podloga = layer
          }
        }))
      this.setState({
        layersCollection: flatColl,
        cartographyLayerGroup: podloga,
        allLayersCollection: coll
      });
    });
  }

  getDefaultData() {
    mapService.getDefaultData().then((data) => {
      if (data) {
        const viewData = Array.isArray(data) ? Object.assign({}, data[0]) : Object.assign({}, data);
        this.setState((prevState) => {
          const { viewOptions } = prevState;
          viewOptions.zoom = viewData.initial_view_zoom;
          viewOptions.center = viewData.initial_view_center;
          return {
            viewOptions: viewOptions,
            zoomToExtent: viewData.default_extent
          }
        });
      }
    });
  }

  getFeatureType(id) {
    if (id > 10000 && id < 20000) {
      return "stabla";
    } else if (id > 20000 && id < 30000) {
      return "grmovi";
    } else if (id > 30000 && id < 40000) {
      return "klupe";
    } else if (id > 40000 && id < 50000) {
      return "kosevi";
    } else if (id > 50000 && id < 60000) {
      return "travnjaci";
    } else if (id > 60000 && id < 70000) {
      return "cvjetnjaci";
    } else if (id > 70000 && id < 80000) {
      return "sumske_sastojine";
    } else if (id > 80000 && id < 90000) {
      return "putevi";
    } else if (id > 90000 && id < 100000) {
      return "photos360";
    } else {
      return null;
    }
  }

  handleClick(evt, userContext) {
    const { draw_type, layersCollection } = this.state;
    let hit = false;

    if (draw_type) {
      return;
    }

    if (!hit) {
      const item = localStorage.getItem("maplayers");
      const visibility = item ? (JSON.parse(item)) : ({});
      const hiddenLayers = visibility ? Object.keys(visibility).filter((key) => visibility[key] === false) : [];
      const allLayers = flattenLayers(layersCollection.getArray()).filter((x) => !(x instanceof OlLayerGroup));
      const visibleLayers = allLayers.filter((x) => hiddenLayers.indexOf(x.get("id")) === -1);
      const GSLayerNames = visibleLayers.map((x) => x.get("layer"));


      gsService.getFeatureInfo(evt.map, evt.pixel, GSLayerNames).then((resp) => {
        if (resp && Object.keys(resp).length != 0) {
          const layerKeys = Object.keys(resp);
          const firstLayer = resp[layerKeys[0]];
          const firstFeature = firstLayer[0];
          const featureType = this.getFeatureType(firstFeature.properties.id);
          const data = {
            position: evt.coordinate,
            feature: firstFeature,
            type: featureType,
            record: undefined
          };
          //TODO: depending on ID, define data type, send different id instead of "gsinfo"
          const showCard = () => this.showCardOverlay("gsinfo", data, null);
          this.closeOverlays(showCard);
        } else {
          this.closeOverlays();
        }
      });
    }
  }

  previousView() {
    const { currentViews, currentView, removedViews } = this.state;
    if (currentView === 0) {
      return;
    }
    this.setViewCenter(currentViews[currentView - 1]);
    removedViews.push(currentViews.pop());
    this.setState({
      viewSwitchClicked: true,
      currentView: currentView - 1,
      currentViews: currentViews,
      removedViews: removedViews
    });
  }

  nextView() {
    const { currentViews, currentView, removedViews } = this.state;
    if (removedViews.length === 0) {
      return;
    }
    this.setViewCenter(removedViews[removedViews.length - 1]);
    currentViews.push(removedViews.pop());
    this.setState({
      viewSwitchClicked: true,
      currentView: currentView + 1,
      currentViews: currentViews,
      removedViews: removedViews
    });
  }

  setViewCenter(view) {
    this.setState(prevState => ({
      viewOptions: Object.assign(prevState.viewOptions, {
        center: view.center,
        zoom: view.zoom
      })
    }));
    setTimeout(() => {
      this.setState({
        viewSwitchClicked: false
      });
    }, 100);
  }

  handleViewChangeCenter(evt) {
    const { currentViews, viewSwitchClicked, viewOptions } = this.state;

    if (viewSwitchClicked) {
      return;
    }
    if (evt && evt.map) {
      // // center_val might be used for showing center coordinates
      // const center = transform(evt.map.getView().getCenter(), "EPSG:3857", "EPSG:4326");
      // this.setState({ center_val: toStringHDMS(center, 0) });
      const newView = {
        center: evt.map.getView().getCenter(),
        zoom: evt.map.getView().getZoom()
      };
      this.setState({
        viewOptions: Object.assign(viewOptions, newView),
        currentViews: currentViews.concat(newView),
        currentView: currentViews.length,
        removedViews: []
      });
    }
  }

  showCardOverlay(type, data, ft) {
    const { feature } = this.state;

    //TODO: refactor
    feature ? feature.setProperties({ selected: false }) : null;

    if (ft) {
      ft.setProperties({ selected: true });
    }

    if (type === "gsinfo") {
      this.setState({
        cardOverlay: {
          type: type,
          data: data
        },
        feature: ft
      });
    }
  }

  closeOverlays(callback = () => {}) {
    this.setState({
      cardOverlay: null
    }, typeof callback === "function" ? () => callback() : () => {});
  }

  changeCoordinateSystemDisplay(type) {
    this.setState({ chosenCoordinateSystem: type });
    this.measurementsSource.changed()
  }

  changeDrawType(type) {
    const { draw_type, drawing } = this.state;
    if (!drawing) {
      this.handleToggleDrawMeasure(true);
    }
    if (draw_type !== type) {
      this.setState({
        draw_type: type
      });
    } else {
      this.setState({
        draw_type: undefined,
        measuringFeature: undefined
      });
      this.handleToggleDrawMeasure(false);
    }
  }

  handleToggleDrawMeasure(isDrawing) {
    const { drawing } = this.state;
    if (drawing !== isDrawing) {
      this.setState({
        drawing: isDrawing
      });
    }
  }

  handleDrawMeasureStart(evt) {
    if (this.drawingSource.getFeatures().length > 0) {
      this.drawingSource.clear();
    }

    this.setState({
      measuringFeature: evt.feature
    });
  }

  handleDrawMeasureEnd(evt) {
    this.drawingSource.clear();
    this.measurementsSource.addFeature(evt.feature);
    this.setState((prevState) => {
      return {
        forceRefreshCounter: prevState.forceRefreshCounter +1
      }
    });
    //console.log(evt.target.getMap());
  }

  handleDrawMeasureChange(evt) {
    //console.log("handleDrawChange", evt);
  }

  handleEraseMeasurements() {
    this.measurementsSource.clear();
  }

  handleMarkRecords(selectedRecords, map) {

    //console.log(selectedRecords)
    const wkts = selectedRecords.map(x => x.wkt);
    const features = wkts.map(wkt => {
      return new OlFeature({
        geometry: this.wkt.readGeometry(wkt, {
          dataProjection: this.htrs96,
          featureProjection: this.wgs84PM
        })
      })
    });

    this.selectedFeaturesSource.clear();
    this.selectedFeaturesSource.addFeatures(features);
  }

  handleSidebarPaneChange(id) {
    const { draw_type } = this.state;

    if (draw_type) {
      this.setState({
        draw_type: undefined
      });
    }
  }

  handleGoToCoordinates(obj) {
    const str = obj.search;
    let elements = []
    elements = str.split(" ")
    if (elements.length !== 2) {
      elements = str.split(",")
    }

    if (elements.length === 2) {
      const coords = elements.map(x => parseFloat(x));
      const mapCoords = OlTransform(coords, "EPSG:31276", "EPSG:3857");
      //console.log(mapCoords);
      this.setViewCenter({
        center: mapCoords,
        zoom: 18
      });
    }
  }

  parseGoToCoordinates(str) {
    if (str === "") {
      return ["format: x,y"]
    }
    let elements = []
    elements = str.split(" ")
    if (elements.length !== 2) {
      elements = str.split(",")
    }

    if (elements.length === 1) {
      return ["x..."]
    } else if (elements.length === 2) {
      const x = parseFloat(elements[0]);
      const y = parseFloat(elements[1]);
      if (x === NaN) {
        return ["invalid"];
      }
      if (elements[1] !== "" && y === NaN) {
        return ["invalid"]
      }
      if (elements[1] === "" || y < 5055000) {
        return ["x,y..."];
      } else {
        return [str];
      }

    } else {
      return ["invalid"]
    }
  }

  measurementsStyle(f) {
    const { chosenCoordinateSystem } = this.state;
    return measurementsStyle(f, chosenCoordinateSystem);
  }

  render() {
    const { t } = this.props;
    const {
      cardOverlay,
      currentViews,
      currentView,
      viewOptions,
      zoomToExtent,
      chosenCoordinateSystem,
      layersCollection,
      allLayersCollection,
      cartographyLayerGroup,
      layersGroup,
      draw_type,
      drawing
    } = this.state;

    //TODO: multiple CardOverlays depending on feature
    const gsOverlay = cardOverlay && cardOverlay.type === "gsinfo" ? cardOverlay : undefined;

    const measurements = this.measurementsSource ? this.measurementsSource.getFeatures() : [];

    return (
      <UserConsumer>
        {(userContext) => (
          <Fragment>
            {userContext && layersCollection ? (
              <Map
                height="800px"
                view={viewOptions}
                onClick={(evt) => this.handleClick(evt, userContext)}
                zoomToExtent={zoomToExtent}
                onMoveend={this.handleViewChangeCenter}
                className="sidebar-map"
                id="main-map"
              >
                <Controls>
                  <ZoomControl
                    zoomInTipLabel={t("map:controls.zoom_in")}
                    zoomOutTipLabel={t("map:controls.zoom_out")}
                  />
                  {/* <ScaleControl className="ol-control ol-scale-ratio ol-sidebar-sticky" ppi={96} /> */}
                  <ScaleRatioControl viewOptions={viewOptions} ppi={96} />
                  <ScaleLineControl />
                  <FullScreenControl tipLabel={t("map:controls.full_screen")} />
                  <RotateControl />
                  <CoordZoomStatusControl chosenCoordinateSystem={chosenCoordinateSystem} view={viewOptions} />
                  <StatusControl view={viewOptions} changeCoordinateSystem={this.changeCoordinateSystemDisplay} />
                  {/* {this.measuresElementRef && this.measuresElementRef.current ? (
                    <MeasureControls
                      className=""
                      position="top"
                      zoomDefaultExtent={this.defaultExtent}
                      id="ctrl-group-1"
                      lng={t.language}
                      measureVectorSource={this.drawinfSource}
                      target={this.measuresElementRef.current}
                      onAddFeature={(feature) => this.handleDrawMeasureEnd(feature)}
                    />
                  ) : null} */}
                  <GeoPortalBaseLayerSwitcher />
                  <SearchControl className="ol-control ol-search ol-sidebar-sticky"
                    inputLabel={"GK6"} placeholder={t("koordinate...")}
                    autocomplete={this.parseGoToCoordinates}
                    typing={300}
                    minLength={1}
                    maxItems={1}
                    onSelect={this.handleGoToCoordinates}
                    getTitle={(title) => { return title}}
                  />
                  <PanningControl viewOptions={viewOptions} zoomToExtent={zoomToExtent} />
                  <ViewHistoryControl previousView={this.previousView} nextView={this.nextView} />
                  <SidebarControl initialOpenId="layers">
                    <SidebarTabs>
                      <SidebarTabList>
                        <SidebarTabListItem
                          id="info"
                          title={t("map:sidebar.info")}
                          icon={<i className="fas fa-info-circle"></i>}
                          onChange={this.handleSidebarPaneChange}
                        />
                        <SidebarTabListItem
                          id="layers"
                          title={t("map:sidebar.layers")}
                          icon={<i className="fas fa-layer-group"></i>}
                          onChange={this.handleSidebarPaneChange}
                        />
                        <SidebarTabListItem
                          id="stabla"
                          title={t("titles.stabla")}
                          icon={<i className="fas fa-tree"></i>}
                          onChange={this.handleSidebarPaneChange}
                        />
                        <SidebarTabListItem
                          id="grmovi"
                          title={t("titles.grmovi")}
                          icon={<i className="fas fa-leaf"></i>}
                          onChange={this.handleSidebarPaneChange}
                        />
                        <SidebarTabListItem
                          id="klupe"
                          title={t("titles.klupe")}
                          icon={<i className="fas fa-chair"></i>}
                          onChange={this.handleSidebarPaneChange}
                        />
                        <SidebarTabListItem
                          id="kosevi"
                          title={t("titles.kosevi")}
                          icon={<i className="fas fa-trash-alt"></i>}
                          onChange={this.handleSidebarPaneChange}
                        />
                        <SidebarTabListItem
                          id="travnjaci"
                          title={t("titles.travnjaci")}
                          icon={<i className="fas fa-seedling"></i>}
                          onChange={this.handleSidebarPaneChange}
                        />
                        <SidebarTabListItem
                          id="cvjetnjaci"
                          title={t("titles.cvjetnjaci")}
                          icon={<i className="fas fa-spa"></i>}
                          onChange={this.handleSidebarPaneChange}
                        />
                        <SidebarTabListItem
                          id="measures"
                          title={t("map:sidebar.measures")}
                          icon={<i className="fas fa-pencil-ruler"></i>}
                          onChange={this.handleSidebarPaneChange}
                        />
                        {/* <SidebarTabListItem
                          id="bookmarking"
                          title={t("map:sidebar.bookmark")}
                          icon={<i className="fas fa-bookmark"></i>}
                        /> */}
                      </SidebarTabList>
                    </SidebarTabs>
                    <SidebarContent>
                      <SidebarPane id="info">
                        <SidebarHeading title={t("map:sidebar.info")} />
                        <InfoPane />
                      </SidebarPane>
                      {/*<SidebarPane id="settings">*/}
                      {/*  <SidebarHeading title={t("map:sidebar.settings")} />*/}
                      {/*</SidebarPane>*/}
                      <SidebarPane id="stabla">
                        <SidebarHeading title={t("titles.stabla")} />
                        <DataPane
                          model = {modelStabla}
                          tableName = {t("titles.stabla")}
                          viewName = "sidebar"
                          onMarkRecords={this.handleMarkRecords}
                          />
                      </SidebarPane>
                      <SidebarPane id="grmovi">
                        <SidebarHeading title={t("titles.grmovi")} />
                        <DataPane
                          model = {modelGrmovi}
                          tableName = {t("titles.grmovi")}
                          viewName = "sidebar"
                          onMarkRecords={this.handleMarkRecords}
                          />
                      </SidebarPane>
                      <SidebarPane id="klupe">
                        <SidebarHeading title={t("titles.klupe")} />
                        <DataPane
                          model = {modelKlupe}
                          tableName = {t("titles.klupe")}
                          viewName = "sidebar"
                          onMarkRecords={this.handleMarkRecords}
                          />
                      </SidebarPane>
                      <SidebarPane id="kosevi">
                        <SidebarHeading title={t("titles.kosevi")} />
                        <DataPane
                          model = {modelKosevi}
                          tableName = {t("titles.kosevi")}
                          viewName = "sidebar"
                          onMarkRecords={this.handleMarkRecords}
                          />
                      </SidebarPane>
                      <SidebarPane id="travnjaci">
                        <SidebarHeading title={t("titles.travnjaci")} />
                        <DataPane
                          model = {modelTravnjaci}
                          tableName = {t("titles.travnjaci")}
                          viewName = "sidebar"
                          onMarkRecords={this.handleMarkRecords}
                          />
                      </SidebarPane>
                      <SidebarPane id="cvjetnjaci">
                        <SidebarHeading title={t("titles.cvjetnjaci")} />
                        <DataPane
                          model={modelCvjetnjaci}
                          tableName={t("titles.cvjetnjaci")}
                          viewName="sidebar"
                          onMarkRecords={this.handleMarkRecords}
                          />
                      </SidebarPane>
                      <SidebarPane id="layers">
                        <SidebarHeading title={t("map:sidebar.layers")} />
                        {/* <div id="layers" className="layer-switcher" ref={this.layerSwitcherElementRef}></div> */}
                        {/*<LayerSwitcher ready={layersCollection ? true : false} />*/}
                        <LayerTree 
                          ready={layersCollection ? true : false} 
                          layersCollection={allLayersCollection}
                          currentCardType={gsOverlay ? gsOverlay.data.type : null}
                          closeOverlay={this.closeOverlays}
                        />
                      </SidebarPane>
                      <SidebarPane id="measures">
                        <SidebarHeading title={t("map:sidebar.measures")} />
                        <MeasuresPane
                          toggleDraw={this.handleToggleDrawMeasure}
                          changeDrawType={this.changeDrawType}
                          handleEraseMeasurements={this.handleEraseMeasurements}
                          draw_type={draw_type}
                          //elementRef={this.measuresElementRef}
                        />
                      </SidebarPane>
                      {/* <SidebarPane id="bookmarking">
                        <SidebarHeading title={t("map:sidebar.bookmark")} />
                        <BookmarkPane currentViews={currentViews} currentView={currentView} />
                      </SidebarPane> */}
                    </SidebarContent>
                  </SidebarControl>
                </Controls>
                {layersCollection ? (
                  <Layers>
                    {cartographyLayerGroup
                      .getLayers()
                      .getArray()
                      .map((childLayer, ci) => {
                        return (
                          <GeoPortalGSLayer
                            key={ci}
                            id={childLayer.get("id")}
                            title={t(childLayer.get("title"))}
                            layer={childLayer.get("layer")}
                            query={childLayer.get("query")}
                            zIndex={childLayer.get("zIndex")}
                          />
                        );
                    })}
                    <GeoPortalGSLayerCombined id="all-layers" layersCollection={layersCollection} />
                    {/* layersCollection.getArray().map((layer, i) => {
                      if (layer instanceof OlLayerGroup) {
                        return (
                          <GroupLayer key={layer.get("id") + "-" + i} id={layer.get("id")} title={t(layer.get("title"))}>
                            {layer
                              .getLayers()
                              .getArray()
                              .map((childLayer, ci) => {
                                return (
                                  <GeoPortalGSLayer
                                    key={ci}
                                    id={childLayer.get("id")}
                                    title={t(childLayer.get("title"))}
                                    layer={childLayer.get("layer")}
                                    query={childLayer.get("query")}
                                    zIndex={childLayer.get("zIndex")}
                                  />
                                );
                              })}
                          </GroupLayer>
                        );
                      } else {
                        return (
                          <GeoPortalGSLayer
                            key={i}
                            id={layer.get("id")}
                            title={t(layer.get("title"))}
                            layer={layer.get("layer")}
                            query={layer.get("query")}
                            zIndex={layer.get("zIndex")}
                          />
                        );
                      }
                    })*/}
                    <VectorLayer
                      id="measurements"
                      source={this.measurementsSource}
                      style={this.measurementsStyle}
                      zIndex={900}
                    />
                    <VectorLayer
                      id="mark-selected"
                      source={this.selectedFeaturesSource}
                      style={selectedStyle}
                      zIndex={900}
                    />
                  </Layers>
                ) : null}
                <Overlays>
                  <PopupOverlay
                    id="feature-overlay"
                    position={gsOverlay ? gsOverlay.data.position : undefined}
                    autoPan={true}
                  >
                    {gsOverlay ? <GSInfoCard featureData={gsOverlay.data} onClose={this.closeOverlays} /> : null}
                  </PopupOverlay>
                  <MeasureTooltipOverlay id="measure-overlay" feature={this.state.measuringFeature} />
                  {/* {measurements.map((m, i) => (
                    <MeasurementInfoOverlay id={"measurement-info-" + i} feature={m} />
                  ))} */}
                  {/* <div
                      className="ol-react-popup"
                      id="measure-overlay"
                      style={{ display: "block" }}
                      ref={this.measureTooltipElementRef}
                    >
                    </div> */}
                </Overlays>
                <Interactions>
                  <DefaultInteractions />
                  {draw_type ? (
                    <DrawInteraction
                      source={this.drawingSource}
                      type={draw_type}
                      style={measuringStyle}
                      onChange={this.handleDrawMeasureChange}
                      onDrawstart={this.handleDrawMeasureStart}
                      onDrawend={this.handleDrawMeasureEnd}
                    />
                  ) : null}
                </Interactions>
              </Map>
            ) : null}
          </Fragment>
        )}
      </UserConsumer>
    );
  }
}

export default withTranslation()(MainMap);
