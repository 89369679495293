import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

//Material-UI Core Components
import Input from "@material-ui/core/Input";

//Material-UI Icons
import SearchIcon from "@material-ui/icons/Search"

//Custom Components
import useDebounce from "Components/useDebounce";

function TableSearchBar(props) {
  const { count, onChange } = props;
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const handleChange = function (evt) {
    setSearchTerm(evt.target.value || undefined);
  };

  // Here's where the API call happens
  // We use useEffect since this is an asynchronous action
  useEffect(
      () => {
        // Make sure we have a value (user has entered something in input)
        if (debouncedSearchTerm) {
          onChange(debouncedSearchTerm)
        } else {
          onChange(undefined);
        }
      },
      // This is the useEffect input array
      // Our useEffect function will only execute if this value changes ...
      // ... and thanks to our hook it will only change if the original ...
      // value (searchTerm) hasn't changed for more than 500ms.
      [debouncedSearchTerm]
  );

  //const placeholder = `Search: ${count} records...`;
  const placeholder = t("components.search.count", { count: count });

  return (
      <Input
          id="global-search"
          startAdornment={<SearchIcon/>}
          variant="filled"
          placeholder={placeholder}
          onChange={handleChange}
      />
  );
}

TableSearchBar.propTypes = {
  count: PropTypes.number,
  onChange: PropTypes.func
}

export default TableSearchBar;
