import React, { useEffect, Fragment } from "react";

export default function Overlays(props) {

  useEffect(() => {
    //console.log('overlays mounted!')
  }, [])

  if (React.Children.count(props.children) > 0) {
    return (
      <div id="overlays" style={{display: "none"}}>{props.children}</div>
    )
  } else {
    return null;
}
}
