import React from 'react'
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";

//Custom Components
import AppHeader from 'UI/Header/AppHeader';
import Sidebar from 'UI/Sidebar/Sidebar';

import menuRoutes from "Routes/menu";
import DialogProvider from 'UI/DialogContext/DialogProvider';
import DialogContainer from 'UI/DialogContext/DialogContainer';

import UserContext from "Components/UserContext/UserContext";
import UserConsumer from "Components/UserContext/UserConsumer";

import withRedirect from "Components/withRedirect";

const style = (theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: "48px !important",
  },
  content: {
    flexGrow: 1,
    //padding: theme.spacing(3),
  },
});

class AppPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false
    };

    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  toggleSidebar() {
    console.log("sidebar prevState", this.state.sidebarOpen)
    this.setState(prevState => ({
      sidebarOpen: !prevState.sidebarOpen
    }));
  }

  render() {
    const { needRole, needPermission } = this.props;
    const { redirect } = this.props; //HOC withRedirect
    const { classes } = this.props; //HOC withStyles
    const { sidebarOpen } = this.state;

    let userContext = this.context;

    if (needRole && !userContext.hasAnyRole(needRole) || needPermission && !userContext.hasPermission(needPermission)) {
      console.warn('Tried to access forbidden page, Logging out...');
      redirect("/");
      return null;
    }
    else {
      return (
        <DialogProvider>
          <div id="page">
            <AppHeader menu={menuRoutes} sidebarOpen={sidebarOpen} toggleSidebar={this.toggleSidebar}/>
            {/*<Sidebar menu={menuRoutes} sidebarOpen={sidebarOpen} toggleSidebar={this.toggleSidebar}/>*/}
            <main id="content-wrapper" className={classes.content}>
              <div className={classes.toolbar}/>
              <div id="content">
                {this.props.children}
              </div>
            </main>
            <DialogContainer />
          </div>
        </DialogProvider>
      );
    }
  }
}

AppPage.contextType = UserContext;

AppPage.defaultProps = {
  needRole: [],
  needPermission: []
}

AppPage.propTyps = {
  needRole: PropTypes.array,
  needPermission: PropTypes.array
}

export default withRedirect(withStyles(style)(AppPage));