import React, { useState, useEffect, useContext, useCallback } from "react";

import BarControl from "Components/Map/Controls/OpenLayersExt/Bar/BarControl";
import ButtonControl from "Components/Map/Controls/OpenLayersExt/ButtonControl";
import { zoomToPanValue } from "Lib/conversions";

import { MapContext } from "../../MapContext";

export default function PanningControl(props) {
  const { viewOptions, zoomToExtent } = props;

  const mapContext = useContext(MapContext);

  const map = mapContext.map;

  const [ready, setReady] = useState(false);

  const panToCenter = () => {
    map.getView().fit(zoomToExtent, {
      size: map.getSize(),
      padding: [100, 100, 100, 600],
      duration: 500,
      center: viewOptions.center
    });
    //map.getView().setCenter(viewOptions.center);
  };

  const handlePan = (dx, dy) => {
    const zoom = map.getView().getZoom();
    /*
     * extent = [minX, minY. maxX, maxY]
     * dx is the direction on x-axis and dy direction on y-axis 
     *    (-1 for negative direction, 1 for positive and 0 if that direction doesn't change)
     * north = 1, south = -1, east = 1, west = -1
     * i % 2 == 1 (true) if i is odd
     * i % 2 == 0 (false) if i is even
     * so for each value in extent add or substract zoomToPanValue to extent value
     */
    const extentCalc = map.getView().calculateExtent().map((ex, i) => (ex + zoomToPanValue(zoom) * (i % 2 ? dy : dx)));

    map.getView().fit(extentCalc, {
      duration: 500,
      /*
       * for some reason this easing function is not necessary and everything works as it should...
       *
       * this is some mathemagics so it would work properly
       * for reasons unbeknown to me fit with duration when panning would make a little step
       *    towards the destination and only then would it start with the animation
       * to fix that step I reckon that it is necessary to zero out that initial step
       * some empirical evidence made me believe that the value of the initial step is 0.35
       *    of the full distance
       * so this little formula down here is mapping the interval [0, 1] to [-0.35, 1] 
       *    as easing function should return a value relative to the point on line 
       *    from start to finish of the animation
       */
      //easing: (a) => 1.1 * a - 0.1
    });
  }

  const panOptions = [
    {
      className: "pan-btn",
      title: "Pan North West",
      name: "pan_north_west",
      html: '<i class="fas fa-long-arrow-alt-left fa-rotate-45"></i>',
      handleClick: () => handlePan(-1, 1)
    },
    {
      className: "pan-btn",
      title: "Pan North",
      name: "pan_north",
      html: '<i class="fas fa-long-arrow-alt-up"></i>',
      handleClick: () => handlePan(0, 1)
    },
    {
      className: "pan-btn",
      title: "North East",
      name: "pan_north_east",
      html: '<i class="fas fa-long-arrow-alt-up fa-rotate-45"></i>',
      handleClick: () => handlePan(1, 1)
    },
    {
      className: "pan-btn",
      title: "Pan West",
      name: "pan_west",
      html: '<i class="fas fa-long-arrow-alt-left"></i>',
      handleClick: () => handlePan(-1, 0)
    },
    {
      className: "pan-btn",
      title: "Pan Center",
      name: "pan_center",
      html: '<i class="far fa-dot-circle"></i>',
      handleClick: panToCenter
    },
    {
      className: "pan-btn",
      title: "Pan East",
      name: "pan_east",
      html: '<i class="fas fa-long-arrow-alt-right"></i>',
      handleClick: () => handlePan(1, 0)
    },
    {
      className: "pan-btn",
      title: "Pan South West",
      name: "pan_south_west",
      html: '<i class="fas fa-long-arrow-alt-down fa-rotate-45"></i>',
      handleClick: () => handlePan(-1, -1)
    },
    {
      className: "pan-btn",
      title: "Pan South",
      name: "pan_south",
      html: '<i class="fas fa-long-arrow-alt-down"></i>',
      handleClick: () => handlePan(0, -1)
    },
    {
      className: "pan-btn",
      title: "Pan South East",
      name: "pan_south_east",
      html: '<i class="fas fa-long-arrow-alt-right fa-rotate-45"></i>',
      handleClick: () => handlePan(1, -1)
    }
  ];

  useEffect(() => {
    if (!ready) {
      if (map !== null) {
        setReady(true);
      }
    }
  }, [map]);

  return ready ? (
    <BarControl id="bar-history" className="panning-control ol-sidebar-sticky" position="top-left">
      {panOptions.map((btn, i) => (
        <ButtonControl
          key={i}
          className={btn.className}
          title={btn.title}
          name={btn.name}
          html={btn.html}
          handleClick={btn.handleClick}
        />
      ))}
    </BarControl>
  ) : null;
}
