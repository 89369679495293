import React from "react";

//Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const useClasses = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white
  },
  img: {
    color: theme.palette.primary.main
  }
}));

function MapCardAvatar(props) {

  const { icon, ariaLabel } = props;

  const { className } = icon.props;

  const classes = useClasses();

  return(
    <Avatar aria-label={ariaLabel} classes={classes}>
      <icon.type className={classes.img + (className ? " " + className : "")} />
    </Avatar>
  );
}

export default MapCardAvatar;
