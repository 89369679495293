import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlInteraction } from "../helpers";

import { MouseWheelZoom } from "ol/interaction";

export default function MouseWheelZoomInteraction(props) {
  const context = useContext(MapContext);

  const options = {
    condition: undefined,
    maxDelta: undefined,
    duration: undefined,
    timeout: undefined,
    useAnchor: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, MouseWheelZoom, props, options, events),
    []
  );

  return null;
}
