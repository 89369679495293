import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlControl } from "../helpers";

import { Zoom } from "ol/control";

export default function ZoomControl(props) {
  const context = useContext(MapContext);

  const options = {
    duration: undefined,
    className: undefined,
    zoomInLabel: undefined,
    zoomOutLabel: undefined,
    zoomInTipLabel: undefined,
    zoomOutTipLabel: undefined,
    delta: undefined,
    target: undefined
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, Zoom, props, options, events), [
    props.duration,
    props.className,
    props.zoomInLabel,
    props.zoomOutLabel,
    props.zoomInTipLabel,
    props.zoomOutTipLabel,
    props.delta,
    props.target
  ]);

  return null;
}
