import React, { ChangeEvent, Fragment, FunctionComponent, SyntheticEvent, useContext } from "react";

//MUI
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

//OL
import OlBaseLayer from "ol/layer/Base";
import OlLayerGroup from "ol/layer/Group";

//Custom components
import LayerTreeItem from "./LayerTreeItem";
import {MapContext} from "Components/Map/MapContext";
import { findLayer, flattenLayers } from "Lib/olHelpers";

const LayerTree = (props) => {
  const { ready, layersCollection, currentCardType, closeOverlay } = props;

  const mapContext = useContext(MapContext);

  const [expanded, setExpanded] = React.useState(["podaci"]);

  const visibility = mapContext?.getLayerVisibility();
  const hiddenLayers = visibility ? Object.keys(visibility).filter((key) => visibility[key] === false) : [];
  // console.log(hiddenLayers);

  const allLayers = flattenLayers(layersCollection.getArray()).filter((x) => !(x instanceof OlLayerGroup));
  // console.log(allLayers);

  const visibleLayers = allLayers.filter((x) => hiddenLayers.indexOf(x.get("id")) === -1);
  // console.log(visibleLayers);

  // const selected = visibleLayers.map(x => x.get("id"));
  const selected = [];

  const isVisible = (layer) => {
    const id = layer.get("id");
    return visibility.hasOwnProperty(id) && visibility[id] === false ? false : true;
  };

  const handleToggle = (evt, nodeIds) => {
    evt.preventDefault()
    if(!((evt.target?.type ?? "") === "checkbox")) {
      setExpanded(nodeIds);  
    }
  };

  const handleSelect = (evt, nodeIds) => {
    evt.preventDefault(); //prevent onNodeToggle

    if (Array.isArray(nodeIds)) {
    } else if((evt.target?.type ?? "") === "checkbox"){
      const layer = flattenLayers(layersCollection.getArray(), 5).find((x) => x.get("id") === nodeIds);
      if (layer) {
        const cardType = parseCardType(currentCardType)
        const layer_visible = isVisible(layer);

        const isGroup = layer instanceof OlLayerGroup;
        if (isGroup) {
          const childLayers = flattenLayers(layer.getLayers().getArray(), 5);

          const childsVisible = childLayers
            ? childLayers.filter((x) => isVisible(x) === true && !(x instanceof OlLayerGroup))
            : [];

          const childsHidden = childLayers
            ? childLayers.filter((x) => isVisible(x) === false && !(x instanceof OlLayerGroup))
            : [];

          const groupChecked = childsVisible.length > 0;
          const groupIndeterminate = childsVisible.length > 0 && childsHidden.length > 0;

          let newVisible;

          if (groupIndeterminate) {
            newVisible = true;
          } else {
            newVisible = !groupChecked;
          }

          if (newVisible === true) {
            childsHidden.forEach((l) => {
              const lid = l.get("id");
              l.setVisible(true);
              mapContext.setLayerVisibility(lid, true);
            });
          } else {
            childsVisible.forEach((l) => {
              const lid = l.get("id");
              l.setVisible(false);
              mapContext.setLayerVisibility(lid, false);
              if(cardType && cardType === lid.substring(4)) {
                closeOverlay()
              }
            });
          }
        } else {
          mapContext.setLayerVisibility(nodeIds, !layer_visible);
          if(cardType && cardType === nodeIds.substring(4)) {
            closeOverlay()
          }
        }
      }
    }
  };

  const layers = layersCollection.getArray();

  const parseCardType = (cardType) => {
    switch (cardType) {
      case "sumske_sastojine": 
        return "sum_sast"
      case "photos360":
        return "photo360"
      default:
        return cardType
    }
  }

  return (
    <Fragment>
      {ready && layers.length > 0 ? (
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={expanded}
          expanded={expanded}
          selected={selected}
          onNodeSelect={handleSelect}
          onNodeToggle={handleToggle}
          sx={{
            mb: 2
          }}
        >
          {layers.map((layer, i) => (
            <LayerTreeItem key={i} layer={layer} onNodeSelect={handleSelect} />
          ))}
        </TreeView>
      ) : null}
    </Fragment>
  );
};

export default LayerTree;