import React from 'react'

//Material-UI Core Components
import { withStyles } from '@material-ui/core/styles';

const style = {
  toolbarFillContent: {
    flexGrow: 1,
    paddingLeft: 5,
    paddingRight: 5
  }
}

class DialogToolbarFillContent extends React.Component {
  render() {
    const { classes, children } = this.props;
    return (
      <div align="center" className={classes.toolbarFillContent}>
        {children}
      </div>
    );
  }
}

export default withStyles(style)(DialogToolbarFillContent);