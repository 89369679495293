import React from "react";
import ClipboardContext from "./ClipboardContext";

class ClipboardProvider extends React.Component {
  constructor(props) {
    super(props);

    this.copy = this.copy.bind(this);

    this.state = {
      record: undefined,
      copy: this.copy
    };
  }

  copy(record) {
    const newRecord = Object.assign({}, record);
    ["id","wkt","can_edit_geom"].forEach(key => {
      if (newRecord.hasOwnProperty(key)) {
        delete newRecord[key];
      }
    })

    this.setState({
      record: newRecord
    });
  }

  render() {
    return (
      <ClipboardContext.Provider value={this.state}>
        {this.props.children}
      </ClipboardContext.Provider>
    );
  }
}

export default ClipboardProvider;
