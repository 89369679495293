import React from 'react'
import PropTypes from "prop-types";

//Material-UI Core Components
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

const customStyles =  theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  }
});

class DialogToolbarMenuItem extends React.Component {

  render() {
    const { classes, theme, onClick, label, icon } = this.props;

    return (
      <MenuItem onClick={onClick}
       classes={classes}
       >
        {icon ?
          <ListItemIcon style={{minWidth: 32, color: "#fff"}}>
            {icon}
          </ListItemIcon>
        : null}
        {label ?
          <Typography variant="button">{label}</Typography>
        : null }
      </MenuItem>
    );
  }
}

DialogToolbarMenuItem.propTypes={
  label: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func
}

export default withStyles(customStyles)(DialogToolbarMenuItem);