import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

//Material UI Core Elements
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import grey from "@material-ui/core/colors/grey";

//Material UI Icons
import PublicIcon from '@material-ui/icons/Public';

//Custom Components
import StatusRoot from "./StatusRoot";

function StatusControl(props) {
  const {t} = useTranslation();

  const {changeCoordinateSystem} = props;

  const fontSize = 14;

  const styles = {
    display: "inline-block",
    lineHeight: "16px",
    marginBottom: 6,
    paddingBottom: 2,
    verticalAlign: "middle",
    maxHeight: "16px"
  };

  const genTooltip = function (ts) {
    if (ts === null || ts === undefined) {
      return "Unkwnon status";
    } else {
      return "Last activity @ " + moment(ts).toString();
    }
  };

  const genTypography = function (text) {
    return (
        <Typography variant="caption" color="inherit"
                    style={styles}>
          {text}:{" "}
        </Typography>
    );
  };

  const genIcon = function (type) {
    const defaultStyles = {
      size: "small",
      fontSize: fontSize,
      borderRadius: "50%",
      margin: "0px 5px 0px 2px",
      cursor: "pointer"
    };
    return (
        <Tooltip title={type}
                 placement="top">
          <PublicIcon
              style={{
                color: "#b7c800",
                backgroundColor: grey[100], ...defaultStyles
              }}
              onClick={() => changeCoordinateSystem(type)}
          />
        </Tooltip>
    );
  }


  return (
      <StatusRoot>
        <Box m={0} style={{
          padding: "1px 5px 1px 5px",
          verticalAlign: "middle"
        }}>
          {genIcon("HTRS96/TM")}
          {genIcon("WGS84")}
          {genIcon("Gauss Kruger 6")}
        </Box>
      </StatusRoot>
  );
}

export default StatusControl;
