
export const gsService = {
  getFeatureInfo
};

const INFO_FORMAT_JSON = "application/json";
const INFO_FORMAT_HTML = "text/html"
const INFO_FORMAT_TEXT = "text/plain";


function getFeatureInfo(map, point, gsLayers) {
  let headers = [];
  headers['Content-Type'] = 'text/plain';
  headers['Accept'] = 'text/html'

  const mapSize = map.getSize();
  const extent = map.getView().calculateExtent(mapSize);

  const requestOptions = {
    method: 'GET',
    headers: headers
  }

  //TODO: need to define in which layer are we seraching for.
  //const query_layers = ["DVK:data"];
  const query_layers = gsLayers;

  let layers = query_layers.join(',');

  const iencUrl = process.env.REACT_APP_GEOSERVERPATH;
  const url = iencUrl
    + "/?service=wms"
    + "&version=1.1.1"
    + "&request=GetFeatureInfo"

    + "&layers=" + layers
    + "&styles="
    + "&srs=EPSG:3857"
    + "&bbox=" + extent
    + "&width=" + mapSize[0]
    + "&height=" + mapSize[1]
    + "&query_layers=" + layers
    + "&format=image/png"
    + "&info_format=" + INFO_FORMAT_JSON
    + "&feature_count=100"
    + "&x=" + point[0].toFixed(0)
    + "&y=" + point[1].toFixed(0)
    + "&buffer=10"
    ;

    return fetch(url, requestOptions, 30000)
    .then( response => {
      return response.text().then( text => {
        if(!response.ok) {
          console.log(response);
        } else {
          const data = parseText(JSON.parse(text));
          return data;
        }
        // const data = text && JSON.parse(text)
        // if (!response.ok) {
        //   const error = (data && data.message) || response.statusText;
        //   return Promise.reject(error);
        // }

        // return data;
      })

    })
    .catch( function(error) {

    });
}

function parseText(text) {
  const features = text.features.map((feature) => {
    const ind = feature.id.indexOf('.');
    const layerId = feature.id.slice(0, ind);
    const properId = feature.id.slice(ind + 1);
    for(var propName in feature.properties) {
      if(feature.properties[propName] === null) {
        delete feature.properties[propName];
      }
    }
    return {
      layerId: layerId,
      properId: properId,
      properties: feature.properties
    }
  });
  return sortInLayers(features);
}

function sortInLayers(object) {
  var layers = {};
  for(var feature of object) {
    if(layers[feature.layerId]) {
      layers[feature.layerId].push({id: feature.properId, properties: feature.properties});
    } else {
      layers[feature.layerId] = [{id: feature.properId, properties: feature.properties}];
    }
  }
  const iencinfo = layers;
  return iencinfo;
}

