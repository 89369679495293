import React from "react";

//Material-UI Core
import IconButton from "@material-ui/core/IconButton";

//Materual-UI Icons
import CloseIcon from "@material-ui/icons/Close";

function MapCardHeaderButtonClose(props) {
  return (
    <IconButton color="inherit" aria-label="close" aria-controls="close" {...props}>
      <CloseIcon />
    </IconButton>
  );
}

export default MapCardHeaderButtonClose;