import React, { Fragment} from 'react'
import PropTypes from 'prop-types';

//Custom Components
import withFormController from "Components/withFormController";
import FormContent from "Components/FormContent";

function Form(props) {
  const { children, dc, recordId } = props;
  const { record, validation, fields } = props; //HOC withFormController

  const handleFieldChange = (value, source) => {
    const { onFieldChange } = props; //HOC withFormController

    if (onFieldChange) {
      onFieldChange(value, source);
    }
  }

  const buildFormContent = (child, i) => {
    return (
      <FormContent
        key={i}
        record={record}
        validation={validation}
        fields={fields}
        onFieldChange={handleFieldChange}
        {...child.props}
      />
    );
  }


  let formElements = [];
  if (Array.isArray(children)) {
    React.Children.forEach(children, (child, i) => {
      if (child !== null) {
        if (child.type === FormContent) {
          formElements.push(buildFormContent(child, i));
        } else {
          formElements.push(child);
        }
      }
    })
  } else {
    if (children !== null) {
      const child = children;
      if (child !== null) {
        if (child.type === FormContent) {
          formElements.push(buildFormContent(child, 1));
        } else {
          formElements.push(child);
        }
      }
    }
  }

  return (
    <Fragment>
      {formElements}
    </Fragment>
  );
}

Form.propTypes = {
  dc: PropTypes.object.isRequired,
  recordId: PropTypes.number
}

export default withFormController(Form)