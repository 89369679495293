import React, { useContext} from "react";
import PropTypes from 'prop-types';

//Custom Component
import ModelTable from "UI/Table/ModelTable";
import { MapContext } from "Components/Map/MapContext";

function MapModelTable(props) {

  const mapContext = useContext(MapContext);
  const { onSelectRecord, ...otherProps} = props;

  const handleRecordSelect = (record) => {
    onSelectRecord(record, mapContext.map);
  }

  return <ModelTable
    onSelectRecord={handleRecordSelect}
   {...otherProps} />
}

MapModelTable.defaultProps = {
	onSelectRecord: () => {}
};

MapModelTable.propTypes = {
	onSelectRecord: PropTypes.func
};

export default MapModelTable;