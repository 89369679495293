import { transform } from "ol/proj";
import { toStringHDMS as OlToStringHDMS } from "ol/coordinate";

import green from "@material-ui/core/colors/lightGreen";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";
import pink from "@material-ui/core/colors/pink";

import OlStyle from "ol/style/Style";
import OlStroke from "ol/style/Stroke";
import OlCircle from "ol/style/Circle";
import OlFill from "ol/style/Fill";
import OlIcon from "ol/style/Icon";
import OlText from "ol/style/Text";
import OlRegularShape from "ol/style/RegularShape"

import OlGeomMultiPoint from "ol/geom/MultiPoint";
import OlGeomPoint from "ol/geom/Point";
import OlGeomPolygon from "ol/geom/Polygon";
import OlGeomLineString from "ol/geom/LineString";
import {toStringHDMS} from 'ol/coordinate';
import {createStringXY} from 'ol/coordinate';

export { recordStyle, selectedStyle, drawStyle, measuringStyle, measurementsStyle };

import { formatArea, formatLength } from "Lib/olHelpers";

const recordStyle = (f) => {
  let style = new OlStyle({
    image: new OlCircle({
      radius: 7,
      stroke: new OlStroke({
        color: "rgba(255, 255, 255, 0.79)"
      }),
      fill: new OlFill({
        color: "rgba(26, 114, 158, 0.79)"
      })
    }),
    fill: new OlFill({
      color: "rgba(184, 184, 184, 0.4)"
    }),
    stroke: new OlStroke({
      color: "#ff1f1f",
      width: 3.25,
      radius: 5
    })
  });
  return style;
};

const selectedStyle = (f) => {
  let style = new OlStyle({
    image: new OlCircle({
      radius: 7,
      stroke: new OlStroke({
        color: "rgba(255, 255, 255, 0.79)"
      }),
      fill: new OlFill({
        color: "rgba(26, 114, 158, 0.79)"
      })
    }),
    fill: new OlFill({
      color: "rgba(184, 184, 184, 0.4)"
    }),
    stroke: new OlStroke({
      color: "#ff1f1f",
      width: 3.25,
      radius: 5
    })
  });
  return style;
};

const drawStyle = new OlStyle({
  image: new OlCircle({
    radius: 7,
    stroke: new OlStroke({
      color: "rgba(255, 255, 255, 0.79)"
    }),
    fill: new OlFill({
      color: "rgba(26, 114, 158, 0.79)"
    })
  }),
  fill: new OlFill({
    color: "rgba(255,255,255,0.4)"
  }),
  stroke: new OlStroke({
    color: "#3399CC",
    width: 3.25,
    radius: 5
  })
});

const measuringStyle = new OlStyle({
  fill: new OlFill({
    color: 'rgba(255, 255, 255, 0.2)',
  }),
  stroke: new OlStroke({
    color: 'rgba(0, 0, 0, 0.5)',
    lineDash: [10, 10],
    width: 2,
  }),
  image: new OlCircle({
    radius: 5,
    stroke: new OlStroke({
      color: 'rgba(0, 0, 0, 0.7)',
    }),
    fill: new OlFill({
      color: 'rgba(255, 255, 255, 0.2)',
    })
  }),
});

const measurementsStyle = (f, coordSystem) => {
  const geom = f.getGeometry();
  var output;
  var tooltipCoord;

  if (geom instanceof OlGeomPolygon) {
    output = formatArea(geom);
    //tooltipCoord = geom.getInteriorPoint().getCoordinates();
    tooltipCoord = geom.getCoordinates()[0];
  } else if (geom instanceof OlGeomLineString) {
    output = formatLength(geom);
    tooltipCoord = geom.getCoordinates()[0];
  } else if (geom instanceof OlGeomPoint) {
    let coord_transform;
    switch(coordSystem) {
      case "WGS84": coord_transform = OlToStringHDMS(transform(geom.getCoordinates(), "EPSG:3857", "EPSG:4326"), 0)
        output = coord_transform
        break;
      case "Gauss Kruger 6": coord_transform = transform(geom.getCoordinates(), "EPSG:3857", "EPSG:31276")
        output = coord_transform[0].toFixed(0) + ", " + coord_transform[1].toFixed(0)
        break;
      case "HTRS96/TM": coord_transform = transform(geom.getCoordinates(), "EPSG:3857", "EPSG:3765")
        output = coord_transform[0].toFixed(0) + ", " + coord_transform[1].toFixed(0)
        break;
      default: coord_transform = transform(geom.getCoordinates(), "EPSG:3857", "EPSG:3765")
        output = coord_transform[0].toFixed(0) + ", " + coord_transform[1].toFixed(0)
        break;
    }
    tooltipCoord = geom;
  }
  let style = [
    new OlStyle({
      image: new OlCircle({
        radius: 5,
        stroke: new OlStroke({
          color: "#232f34"
        }),
        fill: new OlFill({
          color: "rgba(255, 255, 255, 0.2)"
        })
      })
    }),
    new OlStyle({
      fill: new OlFill({
        color: "rgba(255, 255, 255, 0.5)"
      }),
      stroke: new OlStroke({
        color: "#232f34"
      })
    }),
    new OlStyle({
      text: new OlText({
        font: "14px sans serif",
        fill: new OlFill({ color: "#000" }),
        stroke: new OlStroke({ color: "#fff", width: 2 }),
        text: output,
        textBaseline: "center",
        offsetY: -12,
        overflow: true
      })
    })
    // ,
    // new OlStyle({
    //   image: new OlCircle({
    //     radius: 3,
    //     stroke: new OlStroke({
    //       color: "#232f34"
    //     }),
    //     fill: new OlFill({
    //       color: "rgba(255, 255, 255, 0.2)"
    //     })
    //   }),
    //   geometry: function (feature) {
    //     // return the coordinates of the first ring of the polygon
    //     const geom = feature.getGeometry();
    //     const type = geom.getType();
    //     var coordinates = geom.getCoordinates
    //       ? type === "Polygon"
    //         ? geom.getCoordinates()[0]
    //         : geom.getCoordinates()
    //       : null;
    //     return coordinates ? new OlGeomMultiPoint(coordinates) : null;
    //   }
    // })
  ];
  return style;
};
