export const Role = {
	ADMINISTRATOR: { id: "ADMINISTRATOR", code: 900, display: "role.admin" },
	DATA_EDITOR: { id: "DATA_EDITOR", code: 200, display: "role.editor" },
	BASIC_USER: { id: "BASIC_USER", code: 100, display: "role.basic" },


	getRoleByCode: (code) => {
		switch (code) {
			case 900:
				return ADMINISTRATOR;
			case 200:
				return DATA_EDITOR;
			case 100:
				return BASIC_USER;
		}
	}
};