import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { BarControlContext } from "./Bar/BarControlContext";
import { registerOlControl, findControl } from "../helpers";

import Util from "../../Util";

import Search from "ol-ext/control/Search";
import "ol-ext/control/Search.css";

export default function SearchControl(props) {
  const context = useContext(MapContext);
  const parentBarContext = useContext(BarControlContext);

  const options = {
    className: undefined,
    target: undefined,
    label: undefined,
    placeholder: undefined,
    reverse: undefined,
    inputLabel: undefined,
    noCollapse: undefined,
    typing: undefined,
    minLength: undefined,
    maxItems: undefined,
    maxHistory: undefined,
    getTitle: undefined,
    autocomplete: undefined
  };

  const events = {
    select: undefined,
    "change:input": undefined
  };

  useEffect(() => {
    let allOptions = Object.assign(options, props);
    let definedOptions = Util.getDefinedOptions(allOptions);

    let control = new Search(definedOptions);

    if(props.id) {
      control.set("id", props.id)
    }

    if (parentBarContext && parentBarContext.exists) {
      parentBarContext.childControls.push(control);

    } else if (context.map) {
      const mapControl = findControl(context.map, props.id, Search);
      if (mapControl) {
        context.map.removeControl(mapControl);
        //console.log('control removed', Search);
      }
      context.map.addControl(control);
      //console.log('control added', Search);
    } else {
      context.initOptions.controls.push(control);
    }

    let olEvents = Util.getEvents(events, props);
    for (let eventName in olEvents) {
      control.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (context.map) {
        const mapControl = findControl(context.map, props.id, Search);
        if (mapControl) {
          context.map.removeControl(mapControl);
        }
      }
    }

  }, [
    props.className,
    props.target,
    props.label,
    props.placeholder,
    props.reverse,
    props.inputLabel,
    props.noCollapse,
    props.typing,
    props.minLength,
    props.maxItems,
    props.maxHistory,
    props.getTitle,
    props.autocomplete
  ])



  return null;
}
