import { useContext, useEffect } from "react";
import { MapContext } from "../../MapContext";
import { registerOlControl } from "../helpers";

import { ScaleLine } from "ol/control.js";

export default function ScaleLineControl(props) {
  const context = useContext(MapContext);

  const options = {
    className: undefined,
    minWidth: undefined,
    render: undefined,
    target: undefined,
    units: undefined,
    bar: undefined,
    steps: undefined,
    text: undefined
  };

  const events = {
    change: undefined,
    "change:units": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, ScaleLine, props, options, events), [
    props.className,
    props.minWidth,
    props.render,
    props.target,
    props.units,
    props.bar,
    props.steps,
    props.text
  ]);

  return null;
}
