import React from 'react';

//Material-UI Core Components
import Grid from "@material-ui/core/Grid";

function GridItem(props) {
  const { children, ...other} = props;

  return (
    <Grid item {...other}>
      {children}
    </Grid>
  );
}

export default GridItem;