import React, { useContext } from "react";

import UserContext from "Components/UserContext/UserContext";
import SidebarContext from "./SidebarContext/SidebarContext";

function SidebarPane(props) {
  const sidebarContext = useContext(SidebarContext);
  const userContext = useContext(UserContext);
  const { id, needRole, needPermission } = props;

  const isActive = sidebarContext.activePane === id;
  return isActive ? (
    <div className="sidebar-pane active" id={id}>
      {needRole && !userContext.hasAnyRole(needRole) || needPermission && !userContext.hasPermission(needPermission) ? null : props.children}
    </div>
  ) : null;
}

export default SidebarPane;
