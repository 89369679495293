import { createContext } from "react";

export const MapContext = createContext({
  map: undefined,

  initOptions: {
    initControls: [],
    initLayers: [],
    initOverlays: [],
    initInteractions: []
  }
})
