import React, { Fragment, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import clsx from "clsx";

//Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

//Material-UI Icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AssessmentIcon from "@material-ui/icons/Assessment";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

//Custom Components
import MapCard from "UI/MapCard/MapCard";
import MapCardHeader from "UI/MapCard/MapCardHeader";
import MapCardAvatar from "UI/MapCard/MapCardAvatar";
import MapCardContent from "UI/MapCard/MapCardContent";
import MapCardActions from "UI/MapCard/MapCardActions";
import MapCardActionButton from "UI/MapCard/MapCardActionButton";
import { formats } from "Lib/formats";
import { Role } from "Lib/role";
import dataController from "Lib/dataController";
import MapCardHeaderButtonClose from "UI/MapCard/MapCardHeaderButtonClose";
import DialogContext from "UI/DialogContext/DialogContext";
import GridContainer from "UI/Grid/GridContainer.jsx";
import GridItem from "UI/Grid/GridItem.jsx";
import ModelTable from "UI/Table/ModelTable";
import { dataService } from "Services/dataService";
import Image360 from "Views/HomePage/Image360";
import Form from "Components/Form";
import FormContent from "Components/FormContent";
import UserContext from "Components/UserContext/UserContext";

import modelPregled from "Models/pregledi";
import ImageSlider from "Components/ImageSlider";

import modelCvjetnjaci from "Models/cvjetnjaci";
import modelGrmovi from "Models/grmovi";
import modelKlupe from "Models/klupe";
import modelKosevi from "Models/kosevi";
import modelPutevi from "Models/putevi";
import modelStabla from "Models/stabla";
import modelSumskeSastojine from "Models/sumske-sastojine";
import modelTravnjaci from "Models/travnjaci";
import modelPhotos360 from "Models/photos360";

import DataDialog from "Views/MapPage/DataDialog";
import InspectionDialog from "Views/MapPage/InspectionDialog";

import {dataIcons} from "Lib/dataIcons";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minWidth: "450px"
  },
  buttonExpand: {
    marginLeft: "auto"
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}));

function GSInfoCard(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const initialRecod = props.featureData.record ? props.featureData.record :props.featureData.feature.properties;

  const [record, setRecord] = useState(initialRecod);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [loadStatus, setLoadStatus] = useState({ isLoading: true, isLoaded: false });
  const [imageLoadStatus, setImageLoadStatus] = useState({ imageLoading: true });
  const [expanded, setCollapse] = useState(false);
  const [inspections, setInspections] = useState(null);

  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);

  const { featureData, onClose } = props;

  const { type } = featureData;

  const getModel = () => {
    console.log(type)
    switch (type) {
      case "stabla":
        return modelStabla;
      case "grmovi":
        return modelGrmovi;
      case "klupe":
        return modelKlupe;
      case "kosevi":
        return modelKosevi;
      case "travnjaci":
        return modelTravnjaci;
      case "cvjetnjaci":
        return modelCvjetnjaci;
      case "sumske_sastojine":
        return modelSumskeSastojine;
      case "putevi":
        return modelPutevi;
      case "photos360":
        return modelPhotos360;
      default:
        return null;
    }
  };

  const model = getModel();

  const dc = new dataController(model);

  const faIconClass = dataIcons(type);

  const handleRefresh = function () {
    setRefreshCounter(refreshCounter + 1);

    setLoadStatus({ isLoading: true });
  };

  const handleDetailCollapse = function () {
    setCollapse(!expanded);
  };

  const handleEdit = () => {
    const model = getModel(type);
    const dc = new dataController(model);

    dialogContext.showDialog(DataDialog, {
      type: type,
      dc: dc,
      recordId: record.id,
      mode: "update",
      form: "edit",
      iconClass: faIconClass,
      onClose: (result) => handleDataDialogClose(result, record.id)
    });
  };

  const handleDataDialogClose = (dialogResult, recordId) => {
    if (dialogResult.dataChanged) {
      dataService.getData(recordId).then((result) => {
        if (result.success) {
          setRecord(result.data);
        }
      });
    }
  };

  const handlePregledDialogClose = (result) => {
    if (result.dataChanged) {
      refreshInspections();
    }
  };

  const handleAddPregled = (evt) => {
    const dc = new dataController(modelPregled);
    const defaultValues = {
      autor: userContext.fullName,
      datum_pregleda: moment()
    };
    dialogContext.showDialog(InspectionDialog, {
      dc: dc,
      mode: "insert",
      form: "default",
      defaultValues: defaultValues,
      dataId: record.id,
      onClose: handlePregledDialogClose
    });
  };

  const handleEditPregled = (evt, pregled) => {
    const dc = new dataController(modelPregled);
    dialogContext.showDialog(InspectionDialog, {
      dc: dc,
      mode: "update",
      form: "default",
      dataId: record.id,
      recordId: pregled.id,
      onClose: handlePregledDialogClose
    });
  };

  const handleDeletePregled = (evt, pregled) => {
    dataService.removePregled(record.id, pregled.id).then((resp) => {
      if (resp.success) {
        refreshInspections();
      } else {
      }
    });
  };

  const refreshInspections = () => {
    dataService.getPreglediById(record.id).then((resp) => {
      if (resp) {
        setInspections(resp);
      } else {
        setInspections(null);
      }
      setLoadStatus({
        isLoading: false,
        isLoaded: true
      });
    });
  };

  useEffect(() => {
    refreshInspections();
  }, [record.id, refreshCounter]);

  useEffect(() => {
    if(featureData) {
      if(featureData.record) {
        setRecord(featureData.record);
      } else if(featureData.feature) {
        setRecord(featureData.feature.properties);
      }
    }
    setRecord
  }, [featureData]);

  const form = "default";
  const title = record.naziv ? record.naziv : (
    type ? t("layer." + type) : "Info"
  );

  return (
    <Fragment>
      <MapCard className={classes.root}>
        <MapCardHeader
          classes={{ root: classes.header }}
          title={title}
          subheader={t("common.id") + ": " + record.id}
          subheaderTypographyProps={{ color: "initial" }}
          avatar={<MapCardAvatar ariaLabel="info" icon={<i className={faIconClass}></i>} />}
          action={<MapCardHeaderButtonClose onClick={onClose} />}
        />
        <MapCardContent>
          {type === "photos360" ? (
            <Image360 fileName={record.Name} />
          ) : (
            <GridContainer spacing={0}>
              <GridItem xs={12} container>
                <Form dc={dc} mode="view" form={form} record={record}>
                  <FormContent
                    dc={dc}
                    form={form}
                    fields={dc.getFormFields(form)}
                    fieldNames={dc.getFormFieldsNames(form)}
                    columns={1}
                    mode="view"
                    record={record}
                  />
                </Form>
              </GridItem>
              {/* <GridItem xs={6}>Image Placeholder</GridItem> */}
            </GridContainer>
          )}
        </MapCardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {inspections && expanded ? (
            <MapCardContent>
              <GridContainer spacing={0} style={{ display: "flex", flexDirection: "column" }}>
                {/*<ModelTable*/}
                {/*    dc={dc}*/}
                {/*    records={inspections}*/}
                {/*    allowSelection={"one"}*/}
                {/*    allowFilter={true}*/}
                {/*    allowExport={true}*/}
                {/*    tableName={t("titles.users")}*/}
                {/*/>*/}
                {inspections.map((inspection, index) => {

                  let photos = inspection.photos && inspection.photos.length > 0 ? inspection.photos : [];
                  photos = photos.filter(x => x.base64 !== null);

                  return (
                    <Accordion key={"accordion-" + index} style={{ width: "100%" }}>
                      <AccordionSummary
                        key={"accordion-" + index}
                        expandIcon={<ExpandMoreIcon />}
                        id={"panel-" + index}
                        aria-controls="filter-time-content"
                      >
                        <Typography variant="caption" key={"typography-" + index}>
                          <b>{t("pregledi.datum_pregleda")}</b>:{" "}
                          {moment(inspection.datum_pregleda).format(formats.date)}
                        </Typography>
                        <Typography variant="caption">
                          <b>{t("pregledi.biljeska")}</b>: {inspection.biljeska}
                        </Typography>
                        <Typography variant="caption">
                          <b>{t("pregledi.autor")}</b>: {inspection.autor}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails key={"accordiondetails-" + index}>
                        {photos && photos.length > 0 ? (
                          <ImageSlider images={photos} />
                        ) : (
                          <Typography variant="caption">{t("common.no_photos")}</Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
                {/*{inspections.map((inspection, index) => (*/}
                {/*    <GridItem key={"grid-item-" + index} xs={12} style={{display: "flex",flexDirection: "column", marginBottom:"5px"}}>*/}
                {/*      <Typography variant="caption" key={"property-" + index}>*/}
                {/*        <b>Comment</b>: {inspection.biljeska}*/}
                {/*      </Typography>*/}
                {/*      <Typography variant="caption" key={"property-" + index}>*/}
                {/*        <b>Author</b>: {inspection.autor}*/}
                {/*      </Typography>*/}
                {/*      <Typography variant="caption" key={"property-" + index}>*/}
                {/*        <b>Date</b>: {inspection.datum_pregleda}*/}
                {/*      </Typography>*/}
                {/*    </GridItem>*/}
                {/*))}*/}
              </GridContainer>
            </MapCardContent>
          ) : null}
        </Collapse>
      </MapCard>
    </Fragment>
  );
}

export default GSInfoCard;
