import React, {useContext, useRef, useEffect} from "react";
import {registerOlControl, findControl} from "../helpers";
import Util from "../../Util";

import {MapContext} from "../../MapContext";

import Sidebar from "./ol5-sidebar";
import "./ol3-sidebar.css";
import SidebarContext
  from "./SidebarContext/SidebarContext";
import SidebarConsumer
  from "./SidebarContext/SidebarConsumer";
import Fade from "@material-ui/core/Fade";

export default function SidebarRoot(props) {
  const mapContext = useContext(MapContext);
  const sideBarContext = useContext(SidebarContext);
  const sidebarRef = useRef(null);

  useEffect(() => {
    //console.log("useEffect Sidebar", props);
    //console.log(sidebarRef);
    if (sidebarRef.current) {
      const options = {
        element: sidebarRef.current,
        position: "left"
      };

      let allOptions = Object.assign(options, props);
      let definedOptions = Util.getDefinedOptions(allOptions);

      const events = {};

      let control = new Sidebar(definedOptions);

      if (props.id) {
        control.set("id", props.id);
      }

      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, props.id, Sidebar);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
        mapContext.map.addControl(control);
      } else {
        mapContext.initOptions.controls.push(control);
      }

      let olEvents = Util.getEvents(events, props);
      for (let eventName in olEvents) {
        control.on(eventName, olEvents[eventName]);
      }
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, props.id, Sidebar);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, []);

  let classNames = ["sidebar"];
  classNames.push("sidebar-" + (props.position ? props.position : "left"));
  if (sideBarContext.collapsed) {
    classNames.push("collapsed");
  }
  classNames = classNames.join(" ");

  return (

      <div id="sidebar" className={classNames}
           ref={sidebarRef}>
        {props.children}
      </div>

  );
}
