import { createContext } from "react";

const ClipboardContext = createContext({
  record: undefined,
  copy: (record) => {}
  }
)
ClipboardContext.displayName = "ClipoboardContext";

export default ClipboardContext;
