import React, {
  useContext,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";

//Material UI Core Elements
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

//Material UI Icons
import AssignmentIcon from "@material-ui/icons/Assignment";

//Custom Components
import CoordZoomStatusRoot from "./CoordZoomStatusRoot";
import { MapContext } from "../../MapContext";

import { toStringHDMS as OlToStringHDMS } from "ol/coordinate";
import { transform } from "ol/proj";

function CoordZoomStatusControl(props) {
  const {t} = useTranslation();

  const {chosenCoordinateSystem, view} = props;

  const fontSize = 14;

  useEffect(() => {
    //console.log(view)
  }, [])


  const changeCoordinatesDiplsay = (type) => {
    switch (type) {
      case "HTRS96/TM":
        let htrs_array = transform(view.center, "EPSG:3857", "EPSG:3765");
        return htrs_array[0].toFixed(2) + ", " + htrs_array[1].toFixed(2)
        break;
      case "WGS84":
        return OlToStringHDMS(transform(view.center, "EPSG:3857", "EPSG:4326"), 2);
        break;
      case "Gauss Kruger 6":
        let gk_array = transform(view.center, "EPSG:3857", "EPSG:31276");
        return gk_array[0].toFixed(2) + ", " + gk_array[1].toFixed(2)
        break;
      default:
        return ""
        break;
    }
  }

  return (
      <CoordZoomStatusRoot>
        <Box m={0} style={{paddingLeft: 5}}>
          <Tooltip title={t("coordzoomstatus.copy")}>
            <CopyToClipboard text={changeCoordinatesDiplsay(chosenCoordinateSystem)}>
              <IconButton size="small">
                <AssignmentIcon
                    style={{fontSize: fontSize}}/>
              </IconButton>
            </CopyToClipboard>
          </Tooltip>
          <Typography
              variant="caption">{t("coordzoomstatus.center") + ": " + changeCoordinatesDiplsay(chosenCoordinateSystem) + " "} {" " + t("coordzoomstatus.zoom") + ": " + Number.parseFloat(props.view.zoom).toFixed(0)}
          </Typography>
        </Box>
      </CoordZoomStatusRoot>
  );
}

export default CoordZoomStatusControl;
