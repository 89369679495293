import React from 'react'

//Material-UI Core Components
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

const customButtonStyles =  makeStyles(theme => ({
  root: {
    color: theme.palette.primary.black,
    marginLeft: 10,
    marginRight: 0
  }}));


function DialogToolbarButton(props) {
  const classes = customButtonStyles();
  return (
    <Button classes={classes} color="primary" variant="contained" size="small" {...props}>
      {props.children}
    </Button>
  );
}

export default DialogToolbarButton;