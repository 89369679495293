import React from 'react'

//Material-UI Core Components
import makeStyles from '@material-ui/core/styles/makeStyles';
import Checkbox from '@material-ui/core/Checkbox';

const customStyles = makeStyles(theme => ({
  root: {
    padding: 0
  }
}));

const TableCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef
    const classes = customStyles();

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} indeterminate={indeterminate} classes={classes} color="secondary" size="small" />
      </>
    )
  }
)

export default TableCheckbox;