import React from "react";

import Card from "@material-ui/core/Card"

function MapCard(props) {

  return <Card {...props} />;

}

export default MapCard;