import React, { useEffect } from "react";
import ReactPannellum, { setYaw } from "react-pannellum";
import { useTranslation } from "react-i18next";

function Image360(props) {
  const { t } = useTranslation();
  const { fileName } = props;

  const setNorth = () => {
    setYaw(0);
  }

  useEffect(() => {
    const elements = document.getElementsByClassName("pnlm-compass");
    const compass = elements.length ? elements[0] : null;
    if (compass !== null) {
      compass.addEventListener("click", setNorth);
      return () => {
        compass.removeEventListener("click", setNorth);
      }
    }
  })


  const config = {
    //autoRotate: 0,
    autoLoad: true,
    autoRotate: -2,
    compass: true,
    strings: {
      loadButtonLabel: t("360.btn_lbl"),
      loadingLabel: t("360.ldg_lbl"),
      bylineLabel: t("360.lin_lbl"),
      noPanoramaError: t("360.no_pan"),
      fileAccessError: t("360.fil_acs"),
      malformedURLError: t("360.mal_url")
    },
    uiText: {
      loadButtonLabel: t("360.btn_lbl"),
      loadingLabel: t("360.ldg_lbl"),
      bylineLabel: t("360.lin_lbl"),
      noPanoramaError: t("360.no_pan"),
      fileAccessError: t("360.fil_acs"),
      malformedURLError: t("360.mal_url")
    },
    showFullscreenCtrl: !document.fullscreen
  };

  return (
    <div>
      <ReactPannellum id="1" sceneId="firstScene" imageSource={"photos360/" + fileName} config={config} />
    </div>
  );
}

export default Image360;
