import React from 'react'
import PropTypes from 'prop-types';

import TextControl from 'Controls/TextControl';

class FloatNumberControl extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value, source) {
    let valid = false;
    if (value === '') {
      valid = true;
    }

    const valueCorrectDot = value.length > 0 ? value.replace('.',',') : null;

    if (value && value.length > 0 && valueCorrectDot.match(/^[-|0-9]{1}[0-9]{0,}[,]{0,1}[0-9]{0,}$/)) {
      valid = true;
    }

    if (this.props.onChange && valid) {
      this.props.onChange(valueCorrectDot, source);
    }
  }

  render() {
    const { formMode, controlMode, field, value, validation } = this.props;
    // TODO:
    // add decimal places funtionality
    return (
      <TextControl
        formMode = {formMode}
        controlMode = {controlMode}
        field = {field}
        value = {value}
        validation = {validation}
        onChange = {this.handleChange}
      />
    )
  }

}

FloatNumberControl.defaultProps = {
  formMode: "form",
  controlMode: "edit"
}

FloatNumberControl.propTypes = {
  formMode: PropTypes.oneOf(["form", "table"]),
  controlMode: PropTypes.oneOf(["edit", "view"]),
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validation: PropTypes.object,
  onChange: PropTypes.func
}

export default FloatNumberControl;