import React, { Fragment } from "react";
import SnackbarConsumer from "UI/SnackbarContext/SnackbarConsumer";

import { makeStyles } from "@material-ui/core/styles";
import { amber, green } from "@material-ui/core/colors";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

const customStyles = makeStyles(theme => ({
  root: {
    justifyContent: "center"
  }
}));

const variantsStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  }
}));

const SnackbarContainer = () => {
  const classes = customStyles();
  const variants = variantsStyles();

  return (
    <Fragment>
      <SnackbarConsumer>
        {({status, hideNotification}) => (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            key={"snackglobal"}
            open={status.show}
            onClose={hideNotification}
            autoHideDuration={3000}
          >
            <SnackbarContent
              classes={classes}
              className={variants[status.variant]}
              message={status.message}
            />
          </Snackbar>
        )}
      </SnackbarConsumer>
    </Fragment>
  );
};

export default SnackbarContainer;
