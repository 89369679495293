import { createContext } from 'react';

const DialogContext = createContext({
  component: null,
  props: {},
  showDialog: () => {},
  hideDialog: () => {}
});
DialogContext.displayName = 'DialogContext';

export default DialogContext;