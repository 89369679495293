import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//Material-UI Icons
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";

//Custom Components
import withFormController from "Components/withFormController";
import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarHeadingSub from "UI/Dialog/DialogToolbarHeadingSub";
import DialogToolbarButton from "UI/Dialog/DialogToolbarButton";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogActionButton from "UI/Dialog/DialogActionButton";
import DialogToolbarMenuButton from "UI/Dialog/DialogToolbarMenuButton";
import DialogToolbarMenuItem from "UI/Dialog/DialogToolbarMenuItem";
import DialogContext from "UI/DialogContext/DialogContext";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import ClipboardContext from "Components/ClipboardContext/ClipboardContext";
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormContent from "Components/FormContent";
import MapCardAvatar from "UI/MapCard/MapCardAvatar";
import RecordMap from "Views/MapPage/RecordMap";
import { Role } from "Lib/role";

function DataDialog(props) {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const clipboardContext = useContext(ClipboardContext);
  const { t } = useTranslation();

  const [dataChanged, setDataChanged] = useState(false);

  const { dc, mode, form, record, validation, fields, subModels } = props; //HOC withFormController
  const { doInsert, doUpdate, doDelete, doClose } = props; //HOC withFormController
  const { onFieldChange, onClose } = props; //HOC withFormController
  const { iconClass, type, title } = props;

  const handleFieldChange = (value, source) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt) => {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "insert" });
        } else if (result.error) {
          snackbarContext.showNotification("notifications.insert_fail", "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleDelete = (evt) => {
    if (doDelete) {
      doDelete().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "delete" });
        } else if (result.error) {
          snackbarContext.showNotification("notifications.delete_fail", "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleUpdate = (evt) => {
    if (doUpdate) {
      doUpdate().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: "update" });
        } else if (result.error) {
          snackbarContext.showNotification("notifications.update_fail", "error");
        } else if (result.validationPass === false) {
          //do nothing
        }
      });
    }
  };

  const handleClose = (evt) => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false });
        } else {
          if (result.shouldsave) {
            handleUpdate(evt);
          } else if (result.canceled) {
            //do nothing
          }
        }
      });
    }
  };

  const close = (result) => {
    if (result.dataChanged || dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog();
  };

  const handleChangeGeometry = (wkt) => {
    console.log(wkt);

    if (onFieldChange) {
      onFieldChange(wkt, "wkt");
    }
  };

  const handleCopy = () => {
    if (clipboardContext && clipboardContext.copy) {
      clipboardContext.copy(record);
    }
  };

  const handlePaste = () => {
    if (clipboardContext && clipboardContext.record) {
      const pasteRecord = clipboardContext.record;
      Object.keys(pasteRecord).forEach(key => {
        onFieldChange(pasteRecord[key], key);
      })
    }
  };

  const commonFields = {
    record: record,
    validation: validation,
    fields: fields,
    onFieldChange: handleFieldChange,
    mode: mode,
    subModels: subModels
  };

  const somethingToPaste = clipboardContext && clipboardContext.record;

  const allowEdit = form === "insert" || record.can_edit_geom ? true : false;

  const heading = record.naziv ? record.naziv : (
    type ? t("layer." + type) : t("dialog." + title)
  )

  return (
    <DraggableDialog open={true} maxWidth={"lg"} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogAvatar ariaLabel="contract" icon={<i className={iconClass}></i>} />
          <DialogToolbarHeading>{heading}</DialogToolbarHeading>
          {record.id ? <DialogToolbarHeadingSub>(ID = {record.id})</DialogToolbarHeadingSub> : null}
          <DialogToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <GridItem xs={6}>
              <GridContainer>
                <FormContent fieldNames={dc.getFormFieldsNames(form)} columns={1} {...commonFields} />
              </GridContainer>
            </GridItem>
            <GridItem xs={6}>
              { record && record. wkt ?
              <RecordMap canEdit={allowEdit} wkt={record.wkt} onGeometryChange={handleChangeGeometry} /> : null }
            </GridItem>
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButton variant="outlined" startIcon={<AssignmentIcon />} onClick={handleCopy}>
          {t("buttons.copy_attributes")}
        </DialogActionButton>
        <DialogActionButton variant="outlined" startIcon={<CloseIcon />} onClick={handleClose}>
          {t("buttons.close")}
        </DialogActionButton>
      </DialogActions>
    </DraggableDialog>
  );
}

export default withFormController(DataDialog);
