export function dataIcons(type) {
  switch (type) {
    case "stabla":
      return "fas fa-tree ";
    case "grmovi":
      return "fas fa-leaf ";
    case "klupe":
      return "fas fa-chair ";
    case "kosevi":
      return "fas fa-trash-alt ";
    case "travnjaci":
      return "fas fa-seedling ";
    case "cvjetnjaci":
      return "fas fa-spa ";
    case "sumske-sastojine":
      return "fas fa-chart-area ";
    case "putevi":
      return "fas fa-bacon ";
    case "photos360":
      return "fas fa-image ";
    default:
      return "fas fa-info ";
  }
};