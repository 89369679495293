export default function checkPasswordComplexity(password) {
  if (password.length < 8) {
    return false;
  }

  var matchedCase = new Array();
  matchedCase.push("[A-Z]"); // Uppercase Alpabates
  matchedCase.push("[0-9]"); // Numbers
  matchedCase.push("[a-z]"); // Lowercase Alphabates

  var ctr = 0;
  for (var i = 0; i < matchedCase.length; i++) {
    if (new RegExp(matchedCase[i]).test(password)) {
      ctr++;
    }
  }

  return ctr == 3 ? true : false;
};