import React from 'react'

//Custom Components
import ViewControl from 'Controls/ViewControl';
import PickerControl from 'Controls/PickerControl';
import TextControl from 'Controls/TextControl';
import WholeNumberControl from 'Controls/WholeNumberControl';
import FloatNumberControl from 'Controls/FloatNumberControl';
import DateTimeControl from 'Controls/DateTimeControl';
import BoolControl from 'Controls/BoolControl';
import CurrencyControl from 'Controls/CurrencyControl';
import RichTextControl from 'Controls/RichTextControl';
import CheckboxControl from 'Controls/CheckboxControl';
import CustomCheckboxControl from 'Controls/CustomCheckboxControl';
import RadioControl from 'Controls/RadioControl';
import FileControl from 'Controls/FileControl';

let formGenerator = function () {

  this.config = {
		supported_date_formats: [ 'DD.MM HH','DD.MM.YYYY', 'DD.MM.YYYY HH:mm', 'DD.MM.YY HH:mm', 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'YYYY-MM-DD HH:mm' ],
    date_format: 'DD.MM.YYYY',
		datetime_format: 'DD.MM.YYYY HH:mm',
  }

}

formGenerator.prototype.generateFormControls = function(fields, formMode, controlMode, record, validation, onChangeHandler, subModels, showDialog) {
  let rec = {}
  if (fields) {
    fields.forEach(field => {
      if (field) {
        const defaultValue = record && field && field.source && record.hasOwnProperty(field.source) ? record[field.source] : null;
        const fieldValidation = validation && validation.hasOwnProperty(field.source) ? validation[field.source] : null;
        const control = this.generateFieldControl(field, formMode, controlMode, defaultValue, fieldValidation, onChangeHandler, subModels, showDialog);

        rec[field.source] = control;
      }
    });
  }
  return rec;
}

formGenerator.prototype.generateFieldControl = function(field, formMode, controlMode, value, validation, onChangeHandler, subModels, showDialog) {

  let dc = null;
  if (field && field.subModel && subModels) {
    dc = subModels[field.source];
  }

  if (controlMode === "view") {
    return <ViewControl field={field} value={value} dc={dc}/>
  }

  switch (field.type) {
    case ('numeric'):
      return <FloatNumberControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} />
    case ('wholenum'):
      return <WholeNumberControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} />
    case ('date'):
      return <DateTimeControl config={this.config} field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} showTime={false} showTimeLeft={this.should_show_timeleft(field)} />
    case ('datetime'):
      return <DateTimeControl config={this.config} field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} showTimeLeft={this.should_show_timeleft(field)} />
    case ('pickergroup'):
      return <PickerControl config={this.config} field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} isLoading={subModels ? false : true} dc={dc} onChange={onChangeHandler} />
    case ('picker'):
      return <PickerControl config={this.config} field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} isLoading={subModels ? false : true} dc={dc} onChange={onChangeHandler} />
    case ('multiline'):
      return <TextControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} multiline={true} rows={field.rows || 1} rowsMax={field.rowsMax} onChange={onChangeHandler} />
    case 'currency':
        return <CurrencyControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} />
    case ('boolean'):
      return <BoolControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} />
    case ('password'):
      return <TextControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} type="password"/>
    case ('richtext'):
      return <RichTextControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} />;
    case ('checkbox'):
      return <CheckboxControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} dc={dc}/>;
    case ('checkbox_custom'):
      return <CustomCheckboxControl field={field} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} dc={dc} showDialog={showDialog}/>;
    case ('radio'):
      return <RadioControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} dc={dc}/>
    case ('images'):
    //case ('cmsimages'):
      return <FileControl accept={'image/jpeg,image/png'} field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} />
    case ('dokumenti'):
      return <FileControl accept={'*'} field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} />
    default:
      return <TextControl field={field} formMode={formMode} controlMode={controlMode} value={value} validation={validation} onChange={onChangeHandler} />
  }
}

formGenerator.prototype.should_show_timeleft = function(attr) {
  return attr.flags ? attr.flags.indexOf('showTimeLeft') > -1 : false;
}

export default formGenerator;