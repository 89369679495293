import React, { Fragment } from 'react';
import DialogConsumer from 'UI/DialogContext/DialogConsumer';

const DialogContainer = () => (
  <DialogConsumer>
    {({ component1: Component1, props1, component2: Component2, props2, hideModal }) =>
    	<Fragment>
	    	{
		      Component1 ? <Component1 {...props1} onRequestClose={hideModal} /> : null
	    	}
	    	{
	    		Component2 ? <Component2 {...props2} onRequestClose={hideModal} /> : null
	    	}
    	</Fragment>
    }
  </DialogConsumer>
);

export default DialogContainer;