import React, { useRef, useContext, useEffect } from "react";

import { MapContext } from "../../MapContext";
import {findControl} from "../helpers";
import ScaleRatio from "./scaleratio";
import "./scaleratio.css";

import Util from "../../Util";

function ScaleRatioRoot(props) {
    const mapContext = useContext(MapContext);
    const scaleRatioRef = useRef(props.ref);

    const options = {
        activationMode: undefined,
        collapseLabel: undefined,
        label: undefined,
        tipLabel: undefined,
        groupSelectStyle: undefined,
        reverse: undefined,
        target: undefined
      };
    
      const events = {
        change: undefined,
        error: undefined,
        propertychange: undefined
      };

      useEffect(() => {
        if (scaleRatioRef.current) {
          const options = {
            element: scaleRatioRef.current,
          };
    
          let allOptions = Object.assign(options, props);
          let definedOptions = Util.getDefinedOptions(allOptions);
    
          const events = {};
    
          let control = new ScaleRatio(definedOptions);
    
          if (props.id) {
            control.set("id", props.id);
          }
    
          if (mapContext.map) {
            const mapControl = findControl(mapContext.map, props.id, ScaleRatio);
            if (mapControl) {
              mapContext.map.removeControl(mapControl);
            }
            mapContext.map.addControl(control);
          } else {
            mapContext.initOptions.controls.push(control);
          }
    
          let olEvents = Util.getEvents(events, props);
          for (let eventName in olEvents) {
            control.on(eventName, olEvents[eventName]);
          }
        }
    
        return () => {
          if (mapContext.map) {
            const mapControl = findControl(mapContext.map, props.id, ScaleRatio);
            if (mapControl) {
              mapContext.map.removeControl(mapControl);
            }
          }
        };
      }, []);
    
      return (
    
          <div id="scaleratio-control"
                className="ol-control ol-scale-ratio ol-sidebar-sticky ol-scale ol-unselectable ol-control scaleratio"
               ref={scaleRatioRef}>
              {props.children}
          </div>
    
      );
}

export default ScaleRatioRoot;