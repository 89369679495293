import React from "react";

//Materual-UI Icons
import CloseIcon from "@material-ui/icons/Close";

//Custom Components
import DialogToolbarButton from "UI/Dialog/DialogToolbarButton";

function DialogToolbarButtonClose(props) {
  return (
    <DialogToolbarButton aria-label="close" aria-controls="close" {...props}>
      <CloseIcon />
    </DialogToolbarButton>
  );
}

export default DialogToolbarButtonClose;
