import React, { useContext, useEffect, useRef } from "react";
import { MapContext } from "../../MapContext";
import {findControl} from "../helpers";
import Status from "./ol5-status";
import "./ol5-status.css";

import Util from "../../Util";

export default function StatusRoot(props) {
  const mapContext = useContext(MapContext);
  const statusRef = useRef(props.ref);

  const options = {
    activationMode: undefined,
    collapseLabel: undefined,
    label: undefined,
    tipLabel: undefined,
    groupSelectStyle: undefined,
    reverse: undefined,
    target: undefined
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => {
    if (statusRef.current) {
      const options = {
        element: statusRef.current,
      };

      let allOptions = Object.assign(options, props);
      let definedOptions = Util.getDefinedOptions(allOptions);

      const events = {};

      let control = new Status(definedOptions);

      if (props.id) {
        control.set("id", props.id);
      }

      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, props.id, status);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
        mapContext.map.addControl(control);
      } else {
        mapContext.initOptions.controls.push(control);
      }

      let olEvents = Util.getEvents(events, props);
      for (let eventName in olEvents) {
        control.on(eventName, olEvents[eventName]);
      }
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, props.id, status);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, []);

  return (

      <div id="status-control"
           ref={statusRef}>
          {props.children}
      </div>

  );
}