import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import LockIcon from '@material-ui/icons/Lock';

//Custom UI Components
import GridItem from "UI/Grid/GridItem";

//Custom Components
import Map from "Components/Map/Map";
import { DefaultInteractions, Interactions, ModifyInteraction } from "Components/Map/Interactions";
import { Layers, TileLayer, VectorLayer } from "Components/Map/Layers";
import {
  Controls,
  ZoomControl,
  ScaleControl,
  ScaleLineControl,
  ZoomToExtentControl,
  RotateControl,
  CoordZoomStatusControl
} from "Components/Map/Controls";
import GeoPortalBaseLayerSwitcher from "Views/MapPage/GeoPortalBaseLayerSwitcher";
import { recordStyle } from "Components/mapStyles";

//Openlayers Components
import proj4 from "proj4";
import { register as OlRegister } from "ol/proj/proj4";
import { get as OlGetProjection } from "ol/proj";
import OlFormatWKT from "ol/format/WKT";
import OlSourceVector from "ol/source/Vector";
import OlSourceTileWMS from "ol/source/TileWMS";
import OlSourceXYZ from "ol/source/XYZ";
import withStyles from "@material-ui/core/styles/withStyles";
import OlFeature from "ol/Feature";

const MapGrid = withStyles(() => ({
  root: {
    minHeight: "400px",
    minWidth: "400px",
    height: "100%",
    maxHeight: "680px",
    borderRadius: "0.8rem"
  }
}))(GridItem);

class RecordMap extends React.Component {
  constructor(props) {
    super(props);

    proj4.defs(
      "EPSG:3765",
      "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
    );
    proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
    proj4.defs(
      "EPSG:3857",
      "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
    );
    OlRegister(proj4);
    this.htrs96 = OlGetProjection("EPSG:3765");
    this.wgs84 = OlGetProjection("EPSG:4326");
    this.wgs84PM = OlGetProjection("EPSG:3857");

    this.wkt = new OlFormatWKT();
    this.defaultViewCenter = [1731757, 5581737];
    this.defaultExtent = [1042452.65, 5167448.19, 2626808.03, 5851603.54];

    this.recordSource = new OlSourceVector({});

    this.generateSource = this.generateSource.bind(this);
    this.handleGeometryChange = this.handleGeometryChange.bind(this);

    this.state = {
      viewOptions: {
        center: this.defaultViewCenter,
        zoom: 8,
        minZoom: 6,
        maxZoom: 20
      },
      zoomToExtentView: null,
      recordExtent: this.defaultExtent
    };
  }

  componentDidMount() {
    this.generateSource();
  }

  generateSource() {
    const { wkt } = this.props;
    if (wkt) {
      let f = new OlFeature({
        geometry: this.wkt.readGeometry(wkt, {
          dataProjection: this.htrs96,
          featureProjection: this.wgs84PM
        })
      });
      this.recordSource.addFeature(f);
      const extent = f.getGeometry().getExtent();
      this.setState({
        zoomToExtentView: extent,
        recordExtent: extent
      });
    }
  }

  handleGeometryChange(evt) {
    const { onGeometryChange } = this.props;

    const fts = evt.features.getArray();
    if (fts && fts.length > 0) {
      const ft = fts[0];

      let wktResult = this.wkt.writeGeometry(ft.getGeometry(), {
        featureProjection: this.wgs84PM,
        dataProjection: this.htrs96,
        decimals: 2
      });

      console.log(wktResult);
      if (onGeometryChange) {
        onGeometryChange(wktResult);
      }
    }
  }

  render() {
    const { t, canEdit, zoomToExtentCreate } = this.props;
    const { viewOptions, zoomToExtentView, recordExtent } = this.state;
    const zoomToExtent = zoomToExtentView ? zoomToExtentView : zoomToExtentCreate;


    return (
      <MapGrid xs={12} sm={12}>
        <Map id="map-record" view={viewOptions} zoomToExtent={zoomToExtent} zoomToExtentPadding={[50, 50, 50, 50]}>
          <Controls>
            <ZoomControl zoomInTipLabel={t("map:controls.zoom_in")} zoomOutTipLabel={t("map:controls.zoom_out")} />
            <ZoomToExtentControl
              extent={recordExtent}
              tipLabel={t("map:controls.zoom_to_extent")}
              className="ol-zoom-extent ol-sidebar-sticky"
            />
            <ScaleControl className="ol-control ol-scale-ratio ol-sidebar-sticky" ppi={96} />
            <ScaleLineControl />
            <RotateControl />
            <GeoPortalBaseLayerSwitcher />
            {/* <CoordZoomStatusControl chosenCoordinateSystem={chosenCoordinateSystem} view={viewOptions} /> */}
          </Controls>
          <Layers>
            <VectorLayer id="record" source={this.recordSource} style={recordStyle} zIndex={5} />
          </Layers>
          <Interactions>
            <DefaultInteractions dragPan={true} />
          </Interactions>
        </Map>
        { !canEdit ?
        <Typography>Locked</Typography> : null
        }
      </MapGrid>
    );
  }
}

RecordMap.propTypes = {
  canEdit: PropTypes.bool,
  wkt: PropTypes.string,
  onGeometryChange: PropTypes.func
};

export default withTranslation()(RecordMap);
