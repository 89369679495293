import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1)
  },
  title: {
    color: theme.palette.common.white
  },
  subheader: {
    color: grey[200]
  }
}));

function MapCardHeader(props) {
  const classes = useStyles();

  return <CardHeader {...props} classes={classes} />;
}

export default MapCardHeader;
