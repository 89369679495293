import React, { Fragment } from "react";
import moment from "moment";

import { fieldFormat } from "Lib/fieldFormat";
import { formats } from "Lib/formats";

let tableGenerator = function (t) {
  this.t = t;
};

tableGenerator.prototype.generateDataRows = function (records, fields, subModels) {
  return Promise.resolve(
      records.map((record) => {
        let rows = {};
        fields.forEach((field) => {
          rows[field.source] = this.generateDataCell(field, record, subModels[field.source]);
        });
        return rows;
      })
  );
};

tableGenerator.prototype.generateDataCell = function (field, record, dc) {
  const val = record[field.source];
  switch (field.type) {
    case "text":
      if (field.items) {
        let pos = field.items.values.indexOf(val);
        return this.t(field.items.labels[pos]);
      } else if (field.format) {
        return fieldFormat(record, field.format, this.t);
      } else {
        return this.t(val);
      }
    case "date":
      return (
          <div type="date" value={val ? moment.utc(val).toISOString() : ""}>
            {val ? moment.utc(val).local().format(formats.date) : "-"}
          </div>
      );
    case "datetime":
      return (
          <div type="datetime" value={val ? moment.utc(val).toISOString() : ""}>
            {val ? moment.utc(val).local().format(formats.datetime) : ""}
          </div>
      );
    case "currency":
      if (val && typeof val === "object") {
        if (val._value !== undefined && val._value !== null) {
          const num = numbro(val._value).format({ thousandSeparated: true, mantissa: 2 });
          return (
              <div align="right" unformatedvalue={val._value} type="currency">
                {num}
              </div>
          );
        }
        return null;
      } else {
        return null;
      }
    case "active":
      if (field.items) {
        let pos = field.items.values.indexOf(val);
        return (
            <div type="active" value={val}>
              {this.t(field.items.labels[pos])}
            </div>
        );
      }
    case "boolean":
    case "radio":
      if (dc) {
        if (val === null || val === undefined) {
          return "";
        } else {
          return this.t(dc.records.find((x) => x.value === val).label);
        }
      } else if (field.items) {
        const ind = field.items.values.indexOf(val);
        const valueLabel = this.t(field.items.labels[ind]);
        if(field.items.tableIncludesValues){
          return (
              <div type="hazard" value={val}>
                {this.t(valueLabel)}
              </div>
          );
        }
        return valueLabel;
      } else {
        return val;
      }
    case "checkbox":
      if (Number.isInteger(val)) {
        if (dc) {
          return this.t(dc.records.find((x) => x.value == val).label);
          //console.log(model.records);
        }
        const codes = val.toString(2).split("").reverse();
        return codes.map((x, i) => this.t(field.items.labels[field.items.values.indexOf(Math.pow(2, i))])).join(", ");
        // let valueLabel = "";
        // codes.forEach((el, i) => {
        //   if (el === "1") {
        //     const pos = field.items.values.indexOf(Math.pow(2, i));
        //     // valueArray.push( Math.pow(2, i) );
        //     valueLabel += this.t(field.items.labels[pos]) + "; ";
        //   }
        // });
        // return valueLabel;
      } else if (Array.isArray(val)) {
        if (dc) {
          return val.map((x) => this.t(dc.records.find((f) => f.value === x || f.value === x.value).label)).join(", ");
        } else {
          return val.map((x) => x.value).join(", ");
        }

        // return val.map(x => this.t(x.label)).join(', ');
      } else {
        return val;
      }
    case "picker":
      if (dc) {
        if (val === null || val === undefined) {
          return "";
        } else {
          const rec = dc.records.find((x) => x.value === (val.value || val));
          if (rec) {
            return this.t(rec.label);
          } else {
            return "";
          }
        }
      } else if (val && val.hasOwnProperty("value")) {
        return val.value;
      } else if (field.items) {
        const ind = field.items.values.indexOf(val);
        const valueLabel = this.t(field.items.labels[ind]);
        return valueLabel;
      } else {
        return val;
      }
    case "array":
      if(Array.isArray(val)){
        return val.map((x) => this.t(x)).join(", ");
      } else {
        return this.t(val);
      }
    default:
      return val;
  }
};

export default tableGenerator;
